// eslint-disable-next-line @typescript-eslint/no-empty-interface

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { hex2color, hex2rgb, rgb2hex } from '../modules/colors'
import { createPaletteArray } from '../modules/palettes'
import { useTsSelector } from '../reducers'
import {
  CreatePalette,
  createRandomPalette,
  Palette,
  PaletteArrayType,
  PaletteId,
  palettes,
} from '../reducers/modules/palettes'

export const ARRAY_START_DELIMITER = '['
export const ARRAY_START_DELIMITER_URLPARAM = '%5B'
export const ARRAY_END_DELIMITER = ']'
export const ARRAY_END_DELIMITER_URLPARAM = '%5D'
const ITEM_DELIMITER = ','
export const ITEM_DELIMITER_URLPARAM = '%2D'

const decimal2bin = (dec: number): string => {
  return dec.toString(2)
}

const bin2decimal = (bin: string): number => {
  return parseInt(bin, 2)
}
const setBinStringLength = (text: string, length: number): string => {
  return text.padStart(length, '0').slice(0, length)
}

export const setBinStringMinimumLength = (text: string, length: number): string => {
  return text.padStart(length, '0')
}

const handlePaletteToBinaryString =
  (palettes: Record<PaletteId, Palette>) =>
  (palette: Palette): string => {
    if (!palette.colorHex) {
      throw new Error('missing palette color')
    }
    const { r, g, b } = hex2rgb(palette.colorHex)
    const colorBitString = `${setBinStringLength(decimal2bin(r), 8)}${setBinStringLength(
      decimal2bin(g),
      8,
    )}${setBinStringLength(decimal2bin(b), 8)}`

    const permutationsString = setBinStringLength(decimal2bin(palette.permutationsAmount), 6)
    const typeString = setBinStringLength(decimal2bin(palette.type), 4)
    const bgString = setBinStringLength(palette.background ? '1' : '0', 1)
    const _subPalettesString = palette.subpalettes
      .map((pId) => palettes[pId])
      .filter(Boolean)
      .map(handlePaletteToBinaryString(palettes))

    const subPalettesString =
      palette.subpalettes.length > 0 ? `,[${_subPalettesString.join(ITEM_DELIMITER)}]` : ''

    const paletteBitString = `${bgString}${typeString}${permutationsString}${colorBitString}`
    const paletteNumber = bin2decimal(paletteBitString)
    return `${ARRAY_START_DELIMITER}${paletteNumber}${subPalettesString}${ARRAY_END_DELIMITER}`
  }

interface PersistedPalette {
  colorHex: string
  type: PaletteArrayType
  permutationsAmount: number
  background?: boolean
  parentId?: string
}

const unpackBinaryString = (binaryString: string): PersistedPalette | undefined => {
  const colorBitString = binaryString.slice(11)
  const permutationsString = binaryString.slice(5, 11)
  const typeString = binaryString.slice(1, 5)
  const bgString = binaryString.slice(0, 1)

  const rString = colorBitString.slice(0, 8)
  const gString = colorBitString.slice(8, 16)
  const bString = colorBitString.slice(16)
  const bitstringOverflow = binaryString.slice(35)
  const r = bin2decimal(rString)
  const g = bin2decimal(gString)
  const b = bin2decimal(bString)
  const colorHex = rgb2hex({ r, g, b })

  const background = Boolean(bin2decimal(bgString))
  if (bitstringOverflow) {
    throw new Error('This should not happen now')
  }
  const permutationsAmount = parseInt(permutationsString, 2)
  const type = parseInt(typeString, 2)
  return {
    colorHex,
    type,
    permutationsAmount,
    background,
    // subPalettesBitString,
  }
}

const persistedPaletteToPaletteActionData = (
  paletteData: PersistedPalette,
): CreatePalette | undefined => {
  const { type, permutationsAmount, colorHex, background } = paletteData
  const color = hex2color(colorHex)
  const permutations = createPaletteArray(color, Number(permutationsAmount), type)
  return {
    id: uuid(),
    colorHex,
    permutationsAmount,
    permutations,
    type,
    background,
  }
}

type PaletteStringItem = number | number[]

const paletteStringItemToBinaryString = (ps: number): string => {
  return setBinStringMinimumLength(decimal2bin(ps), 35)
}

const flattenNestedPaletteData = (parentId: string | undefined) => (state: PaletteStringItem[]) => {
  const [items] = state.reduce<[CreatePalette[], string | undefined]>(
    ([memo, parentId], item) => {
      if (typeof item === 'number') {
        const paletteData = unpackBinaryString(paletteStringItemToBinaryString(item))
        if (!paletteData) {
          return [memo, parentId]
          // throw new Error()
        }
        const palette = persistedPaletteToPaletteActionData(paletteData)
        if (!palette) {
          return [memo, parentId]
          // throw new Error()
        }
        const data = [...memo, { ...palette, parentId }]
        return [data, palette.id]
      } else if (Array.isArray(item)) {
        const subPalettes = flattenNestedPaletteData(parentId)(item)
        const data = [...memo, ...subPalettes]
        return [data, parentId]
      } else {
        return [memo, parentId]
        // throw new Error()
      }
    },
    [[], parentId],
  )
  return items
}

const hydrateState = (stateBinaryString: string): CreatePalette[] => {
  try {
    const state = JSON.parse(stateBinaryString)
    const actions = flattenNestedPaletteData(undefined)(state)
    return actions
  } catch (er) {
    console.log('bad json data')
  }
  return []
}

const dehydrateState = ({ palettes }: { palettes: Record<string, Palette> }): string => {
  const rootItems = Object.values(palettes).filter((p) => !p.parentId)
  const palette2BinaryString = handlePaletteToBinaryString(palettes)
  return `[${rootItems.map(palette2BinaryString).join(',')}]`

  // return [initial].map(palette2BinaryString).join(',')
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StatusPersistance {}

export const StatusPersistance: React.FC<StatusPersistance> = () => {
  const [params, setParams] = useSearchParams()
  const [hasHydrated, setHasHydrated] = useState(false)
  const dispatch = useDispatch()
  const palettesState = useTsSelector((store) => store.palettes)

  useEffect(() => {
    if (!hasHydrated) {
      const stateString = params.get('state')
      if (
        stateString &&
        Boolean(stateString) &&
        stateString !== 'NaN' &&
        stateString !== 'undefined' &&
        stateString !== `${ARRAY_START_DELIMITER}${ARRAY_END_DELIMITER}`
      ) {
        const actions: CreatePalette[] = hydrateState(stateString)
        Promise.all(
          actions.map((action) => {
            return dispatch(palettes.actions.createPalette(action))
          }),
        )
      } else {
        const palette = createRandomPalette()
        dispatch(palettes.actions.createPaletteWithBG(palette))
      }
      setHasHydrated(true)
    }
  }, [hasHydrated, params, dispatch])

  useEffect(() => {
    const queryParamsState = dehydrateState({ palettes: palettesState.present })
    if (Object.keys(palettesState?.present ?? []).length > 0) {
      setParams({
        state: queryParamsState,
      })
    }
  }, [palettesState, setParams])
  return <></>
}
