import React from 'react'
import { CreatePaletteButton } from '../components/createPaletteButton'
import classNames from './colorSwatchContainer.module.sass'
import { Color } from '../reducers/modules/colors'
import { ColorSwatch } from '../components/ColorSwatch'
import { useTsSelector } from '../reducers'

interface ColorSwatchContainer {
  nearestColors: Color[]
  color?: Color
  parentId: string
  amountOfSwatches: number
}
export const ColorSwatchContainer: React.FC<ColorSwatchContainer> = ({
  nearestColors,
  color,
  parentId,
  amountOfSwatches,
}) => {
  const colorSetName = useTsSelector((state) => state.gui.usedColorSet)
  const isColorSetHex = colorSetName === 'hex'
  const colors = isColorSetHex
    ? nearestColors
    : nearestColors.slice(0, Math.min(amountOfSwatches, nearestColors.length - 1))
  return (
    <div className={classNames.colorSwatchGradientContainer}>
      {false && <p className={classNames.colorName}>{color?.hex}</p>}
      {colors.map((neighbourColor, i) => (
        <ColorSwatch color={neighbourColor} key={`${neighbourColor.hex}-${i}`}>
          <CreatePaletteButton color={neighbourColor} permutationsAmount={4} parentId={parentId} />
        </ColorSwatch>
      ))}
    </div>
  )
}
export default ColorSwatchContainer
