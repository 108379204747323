import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
import { hex2color } from '../../modules/colors'
import { DistanceType } from '../../modules/colorDistances'
// import { DistanceType } from '../../modules/colorDistances'
import { createPaletteArray } from '../../modules/palettes'
import { Color, Hex } from './colors'

export type PaletteId = string

// Helper
const StringIsNumber = (value: string): boolean => isNaN(Number(value)) === false

// Turn enum into array of numbers
const enumToArray = (enumme: Record<number | string, any>): string[] => {
  return Object.keys(enumme)
    .filter(StringIsNumber)
    .map((key) => enumme[key])
}

export enum PaletteArrayType {
  FullHLab,
  FullH,
  // FullCLab,
  // FullLLab,
  WhitenessOk,
  BlacknessOk,
  Wack,
  // FullSOk,
  // FullVOk,
}

export const paletteArrayTypeNumberValues = enumToArray(PaletteArrayType)

export const DefaultPaletteArrayType = PaletteArrayType.FullHLab

export interface Palette {
  color: Color
  colorHex?: Hex
  permutationsAmount: number // default to 3
  permutations: Color[]
  id: PaletteId
  subpalettes: PaletteId[]
  parentId?: PaletteId
  distanceType?: DistanceType
  type: PaletteArrayType
  closestColorHex?: Hex
  background?: boolean
}
export interface PaletteInStore extends Omit<Palette, 'color'> {
  colorHex: Hex
}
export interface PalettesState {
  [index: string]: PaletteInStore
}

// export const createRandomPalette = (): PaletteInStore => {
//   const id = uuid()
//   const hex = `#${['c0', '60', 'a0'].sort(() => Math.random() - 0.5).join('')}`

//   const color = hex2color(hex)
//   const permutations = 3
//   return {
//     color,
//     permutations,
//     id,
//     subpalettes: [],
//     arrayType: DefaultPaletteArrayType,
//     distanceType: DefaultDistanceType,
//   }
// }

export const createRandomPalette = (): CreatePalette => {
  const id = uuid()
  const hex = `#${['d0', '50', '90'].sort(() => Math.random() - 0.5).join('')}`
  const permutationsAmount = 4
  const type = DefaultPaletteArrayType
  const color = hex2color(hex)
  const permutations = createPaletteArray(color, Number(permutationsAmount), type)

  return {
    colorHex: hex,
    permutationsAmount,
    permutations,
    id,
    type,
    background: true,
  }
}

export const MIN_TYPE = 0
export const MAX_TYPE = Math.max(Object.keys(PaletteArrayType).length / 2 - 1, 0) // keys are as strings and as numbers. We need only either

const limitType = (type: unknown): PaletteArrayType => {
  const typeNumber = typeof type !== 'number' ? Number(type) : type
  if (typeof typeNumber !== 'number') {
    throw new Error('Unusable type')
    // return DefaultPaletteArrayType
  } else {
    return Math.min(MAX_TYPE, Math.max(MIN_TYPE, typeNumber))
  }
}

export type ColorSetCalc = Record<PaletteArrayType, typeof createPaletteArray>

// export type CreatePalette = Palette & SpawnCoordinates
export interface CreatePalette {
  colorHex: string
  parentId?: string
  permutationsAmount: number
  permutations?: Color[]
  left?: string // Where the palette is created from
  top?: string // Where the palette is created from
  id?: string
  type?: PaletteArrayType
  background?: boolean
}

export interface ModifyPalette {
  colorHex: string
  id: string
  permutationsAmount: number
  type?: PaletteArrayType
  background?: boolean
}

const defaultState = {}
export const palettes = createSlice({
  name: 'palettes',
  initialState: defaultState,
  reducers: {
    createPalette(state, action: PayloadAction<CreatePalette>) {
      const { parentId, colorHex, permutationsAmount, type, background } = action.payload
      const id = action.payload.id ? action.payload.id : uuid()
      const permutations = createPaletteArray(hex2color(colorHex), Number(permutationsAmount), type)
      state[id] = {
        id,
        colorHex,
        permutationsAmount,
        permutations,
        subpalettes: [],
        parentId,
        type,
        background,
      }
      if (parentId) {
        state[parentId].subpalettes.push(id)
      }
    },
    createPaletteWithBG(state, action: PayloadAction<CreatePalette>) {
      const { parentId, colorHex, permutationsAmount } = action.payload
      const id = action.payload.id ? action.payload.id : uuid()
      const parent = parentId ? state?.[parentId] : undefined
      const isSameColorAsParent = parent?.colorHex === colorHex && parent?.background

      const type = action.payload.type || DefaultPaletteArrayType

      const permutations = createPaletteArray(hex2color(colorHex), Number(permutationsAmount), type)

      const background = !isSameColorAsParent

      const follower: PaletteInStore = {
        id,
        colorHex,
        permutationsAmount,
        permutations,
        subpalettes: [],
        parentId: parentId,
        type,
        background,
      }

      if (parentId) {
        state[parentId].subpalettes.push(id)
      }

      state[follower.id] = follower
    },

    modifyPalette(state, action: PayloadAction<ModifyPalette>) {
      const { id, colorHex } = action.payload
      const original = { ...state[id] }
      const type =
        action.payload.type || action.payload.type === 0
          ? limitType(action.payload.type)
          : original.type
      const permutationsAmount = action.payload.permutationsAmount ?? original.permutationsAmount

      const permutations = createPaletteArray(
        original.color || hex2color(colorHex || original.hex),
        Number(permutationsAmount),
        type,
      )

      state[id] = {
        ...original,
        ...action.payload,
        permutations,
        type,
        colorHex: colorHex,
      }
    },
    deletePalette(state, action: PayloadAction<string>) {
      delete state[action.payload]
    },
    reset(state) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = defaultState
    },
  },
  extraReducers: (_builder) => {
    // builder.addCase(
    // thunks.createPalette.pending,
    // (state, action: PayloadAction<PositionOnGui>) => {
    //   state.position = action.payload
    //   state.zIndex += 1
    // },
    // )
  },
})
