const colorsTikkurilaFacade750 = [
  ['Q104', '28063', '#f4ca84'],
  ['Q105', '28064', '#f2c371'],
  ['Q106', '28065', '#f9eccc'],
  ['Q107', '28066', '#fae6bc'],
  ['Q108', '28067', '#f8daa1'],
  ['Q109', '28068', '#f6d492'],
  ['Q110', '28069', '#f1cd89'],
  ['Q111', '28070', '#e7bc70'],
  ['Q112 Hirssi', '24629', '#fae8c9'],
  ['Q113', '28071', '#f9dfb7'],
  ['Q114', '28072', '#f7d6a2'],
  ['Q115 Paahde', '25179', '#f4cc8d'],
  ['Q116 Heinäpouta', '25180', '#f1c079'],
  ['Q117', '28073', '#ddad6c'],
  ['Q118 Tuomi', '25181', '#EFDEC7'],
  ['Q119 Vuota', '25182', '#e8d1b0'],
  ['Q120', '28074', '#e5c8a1'],
  ['Q121', '28075', '#d9b686'],
  ['Q122 Kultapyörä', '28076', '#c8a276'],
  ['Q123', '28077', '#a98454'],
  ['Q124', '28078', '#f9e3c6'],
  ['Q125', '28079', '#f8d9b0'],
  ['Q126', '28080', '#f1c48b'],
  ['Q127 Pelto', '25183', '#e8b679'],
  ['Q128', '28081', '#e0aa65'],
  ['Q129 Kesä', '25184', '#d49a52'],
  ['Q130', '28082', '#f2e0c9'],
  ['Q131 Päivä', '25185', '#f0d2af'],
  ['Q132', '28083', '#e8b773'],
  ['Q133', '28084', '#e0a962'],
  ['Q134', '28085', '#ce9656'],
  ['Q135', '28086', '#c48e4f'],
  ['Q136', '28087', '#F6F0E1'],
  ['Q137 Lempi', '25186', '#f2dec3'],
  ['Q138 Lyhde', '25187', '#f0ca98'],
  ['Q139', '28088', '#e7b477'],
  ['Q140', '28089', '#d4a770'],
  ['Q141 Syksy', '25188', '#bb8c58'],
  ['Q142', '28090', '#f8ead7'],
  ['Q143 Angervo', '25189', '#f5dfc0'],
  ['Q144 Vilja', '25190', '#f7d4a6'],
  ['Q145', '28091', '#f1c38c'],
  ['Q146', '28092', '#eebc81'],
  ['Q147 Sato', '25191', '#ce9760'],
  ['Q148', '28093', '#f7dec1'],
  ['Q149', '28094', '#f5cba1'],
  ['Q150', '28095', '#f0be8c'],
  ['Q151', '28096', '#dfaa75'],
  ['Q152', '28097', '#ce9660'],
  ['Q153', '28098', '#b37b44'],
  ['Q154', '28099', '#f7dec3'],
  ['Q155', '28100', '#f8cea5'],
  ['Q156', '28101', '#f4c18e'],
  ['Q157 Mehiläinen', '24628', '#efb67d'],
  ['Q158', '28102', '#e8a970'],
  ['Q159', '28103', '#e09e5f'],
  ['Q160', '28104', '#f3d5b4'],
  ['Q161', '28105', '#edc49a'],
  ['Q162', '28106', '#e0ad79'],
  ['Q163', '28107', '#d59a62'],
  ['Q164', '28108', '#ba7d46'],
  ['Q165', '28109', '#956642'],
  ['Q166', '28110', '#f7e4ce'],
  ['Q167', '28111', '#f8d6ba'],
  ['Q168', '28112', '#f4c8a7'],
  ['Q169', '28113', '#e9ae81'],
  ['Q170', '28114', '#db9d73'],
  ['Q171', '28115', '#c58863'],
  ['Q172', '28116', '#f5d5b9'],
  ['Q173', '28117', '#f2caa7'],
  ['Q174', '28118', '#efbd91'],
  ['Q175', '28119', '#dc9f6f'],
  ['Q176', '28120', '#d08b58'],
  ['Q177', '28121', '#b27248'],
  ['Q178', '28122', '#f5e0d3'],
  ['Q179', '28123', '#f2d2bd'],
  ['Q180', '28124', '#ecc1aa'],
  ['Q181', '28125', '#e4af90'],
  ['Q182', '28126', '#d38f6a'],
  ['Q183', '28127', '#af6d4e'],
  ['Q184', '28128', '#f2d6c3'],
  ['Q185', '28129', '#f3ceb8'],
  ['Q186', '28130', '#e2b7a0'],
  ['Q187 Lohi', '25192', '#d7a790'],
  ['Q188', '28131', '#ba856b'],
  ['Q189 Moreeni', '25193', '#ad7558'],
  ['Q190', '28132', '#e9d5c8'],
  ['Q191', '28133', '#e3c9b9'],
  ['Q192 Iltarusko', '25194', '#cfa892'],
  ['Q193', '28134', '#be9074'],
  ['Q194', '28135', '#ad7a5b'],
  ['Q195 Rousku', '25195', '#865c46'],
  ['Q300', '28136', '#ebd5cc'],
  ['Q301', '28137', '#e5cbc1'],
  ['Q302', '28138', '#d6b5a7'],
  ['Q303 Närhi', '25196', '#c29786'],
  ['Q304', '28139', '#aa7c6a'],
  ['Q305 Tiili', '25197', '#A26F5C'],
  ['Q306', '28140', '#eacbc1'],
  ['Q307', '28141', '#dfb1a5'],
  ['Q308', '28142', '#d39e90'],
  ['Q309', '28143', '#bc806e'],
  ['Q310', '28144', '#a66450'],
  ['Q311 Ruoste', '25198', '#95533e'],
  ['Q312', '28146', '#F3D4C8'],
  ['Q313', '28147', '#e9b9a7'],
  ['Q314', '28148', '#e0a793'],
  ['Q315', '28149', '#ca836b'],
  ['Q316', '28150', '#ab6047'],
  ['Q317', '28145', '#954c3a'],
  ['Q318', '28151', '#e8bdb5'],
  ['Q319', '28152', '#d39689'],
  ['Q320', '28153', '#ba705f'],
  ['Q321', '28154', '#a75f51'],
  ['Q322', '28155', '#7d493e'],
  ['Q323', '28156', '#7b473e'],
  ['Q324', '28157', '#eed1c9'],
  ['Q325', '28158', '#e7c0b9'],
  ['Q326', '28159', '#d7a39b'],
  ['Q327', '28160', '#ca8e81'],
  ['Q328', '28161', '#b47166'],
  ['Q329', '28162', '#a56152'],
  ['Q330', '28163', '#eec9bc'],
  ['Q331', '28164', '#dda99b'],
  ['Q332', '28165', '#c9897d'],
  ['Q333', '28166', '#a15e52'],
  ['Q334', '28167', '#79463d'],
  ['Q335', '28168', '#6f443e'],
  ['Q336', '28169', '#f1d9d4'],
  ['Q337', '28170', '#e0bbb6'],
  ['Q338', '28171', '#d3a49e'],
  ['Q339', '28172', '#c6928d'],
  ['Q340', '28173', '#b37972'],
  ['Q341', '28174', '#965a52'],
  ['Q342', '28176', '#ebcec6'],
  ['Q343', '28177', '#e5bbb3'],
  ['Q344', '28175', '#c59086'],
  ['Q345', '28178', '#ae776e'],
  ['Q346', '28179', '#855448'],
  ['Q347', '28180', '#794a41'],
  ['Q348', '28181', '#debcb6'],
  ['Q349', '28182', '#d09b93'],
  ['Q350', '28183', '#b07971'],
  ['Q351', '28184', '#a06a65'],
  ['Q352', '28185', '#83473e'],
  ['Q353 Majatalo', '25199', '#7c4942'],
  ['Q354', '28187', '#f1e3de'],
  ['Q355', '28186', '#e3c9c5'],
  ['Q356', '28188', '#c39690'],
  ['Q357', '28189', '#9f716a'],
  ['Q358', '28190', '#865750'],
  ['Q359', '28191', '#775955'],
  ['Q360', '28192', '#e4cdc3'],
  ['Q361', '28193', '#e5c6bb'],
  ['Q362', '28194', '#d8b8b1'],
  ['Q363', '28195', '#986d66'],
  ['Q364', '28196', '#734c42'],
  ['Q365', '28197', '#6b4c41'],
  ['Q366', '28198', '#e9dbd6'],
  ['Q367', '28199', '#dcc9c4'],
  ['Q368', '28200', '#cab6b1'],
  ['Q369', '28201', '#b79f9a'],
  ['Q370', '28202', '#967a74'],
  ['Q371', '28203', '#79564e'],
  ['Q372', '28204', '#e4d5d4'],
  ['Q373', '28205', '#d7c0bf'],
  ['Q374', '28206', '#c7acad'],
  ['Q375', '28207', '#b19293'],
  ['Q376', '28208', '#987575'],
  ['Q377', '28209', '#865957'],
  ['Q378', '28210', '#c9bcc0'],
  ['Q379', '28211', '#b6a4a9'],
  ['Q380', '28212', '#8f7479'],
  ['Q381', '28213', '#7a5d5f'],
  ['Q382', '28214', '#725251'],
  ['Q383', '28215', '#5e4745'],
  ['Q384', '28216', '#f0e8e3'],
  ['Q385', '28217', '#dfd8d8'],
  ['Q386', '28218', '#d3c9cd'],
  ['Q387', '28219', '#c7bcc2'],
  ['Q388', '28220', '#b3a4ab'],
  ['Q389', '28221', '#9d8d93'],
  ['Q400', '28222', '#dfe0e1'],
  ['Q401', '28223', '#d7d9dd'],
  ['Q402', '28224', '#c1c2ca'],
  ['Q403', '28225', '#aaacb9'],
  ['Q404', '28226', '#9597a6'],
  ['Q405', '28227', '#898b99'],
  ['Q406', '28228', '#c7d7e2'],
  ['Q407', '28229', '#b4c7d6'],
  ['Q408', '28230', '#99afc4'],
  ['Q409', '28231', '#7a8ba1'],
  ['Q410', '28232', '#60697d'],
  ['Q411', '28233', '#585f6f'],
  ['Q412', '28234', '#e1e5e7'],
  ['Q413', '28235', '#d6dde2'],
  ['Q414', '28236', '#cdd5dc'],
  ['Q415', '28237', '#b3bfcc'],
  ['Q416', '28238', '#97a3b2'],
  ['Q417', '28239', '#596576'],
  ['Q418', '28240', '#e4e9e9'],
  ['Q419', '28241', '#d1dada'],
  ['Q420 Lampi', '25200', '#b9c6ce'],
  ['Q421', '28242', '#a5b6c3'],
  ['Q422', '28243', '#7d92a3'],
  ['Q423', '28244', '#506174'],
  ['Q424', '28245', '#cedadb'],
  ['Q425', '28246', '#b4c4cb'],
  ['Q426', '28247', '#a1b6c0'],
  ['Q427', '28248', '#7797a7'],
  ['Q428', '28249', '#587284'],
  ['Q429 Koli', '25201', '#465e6f'],
  ['Q430', '28250', '#dbe2e4'],
  ['Q431', '28251', '#c5d4da'],
  ['Q432', '28252', '#b1c5ce'],
  ['Q433', '28253', '#a0b8c1'],
  ['Q434', '28254', '#7b9ba8'],
  ['Q435 Puro', '25202', '#5f7c8b'],
  ['Q436', '28255', '#D9E7E8'],
  ['Q437', '28256', '#bbd1d7'],
  ['Q438', '28257', '#A3C1CD'],
  ['Q439', '28258', '#688fa2'],
  ['Q440', '28259', '#466e85'],
  ['Q441', '28260', '#445c6b'],
  ['Q442', '28261', '#ccd8dd'],
  ['Q443', '28262', '#bbcad1'],
  ['Q444', '28263', '#a8bec7'],
  ['Q445', '28264', '#7192a3'],
  ['Q446', '28265', '#587480'],
  ['Q447', '28266', '#4e666f'],
  ['Q448', '28267', '#c3cbd1'],
  ['Q449', '28268', '#a3b4c2'],
  ['Q450', '28269', '#9cafbc'],
  ['Q451', '28270', '#7f93a1'],
  ['Q452 Kaamos', '25203', '#5a6c79'],
  ['Q453', '28271', '#535960'],
  ['Q454', '28272', '#d8e1e3'],
  ['Q455', '28273', '#cbd8db'],
  ['Q456', '28274', '#bdcfd4'],
  ['Q457', '28275', '#97b4be'],
  ['Q458', '28276', '#7a9ba5'],
  ['Q459', '28277', '#546c75'],
  ['Q460', '28278', '#d0dadb'],
  ['Q461', '28279', '#a3b8bf'],
  ['Q462', '28280', '#859da5'],
  ['Q463 Virta', '25204', '#6a828b'],
  ['Q464', '28281', '#5c7179'],
  ['Q465 Kettinki', '25205', '#485156'],
  ['Q466', '28282', '#d6e1df'],
  ['Q467', '28283', '#c6d5d7'],
  ['Q468', '28284', '#a5bfc4'],
  ['Q469', '28285', '#89a8b1'],
  ['Q470', '28286', '#688a96'],
  ['Q471', '28287', '#618390'],
  ['Q500', '28288', '#e3e8e4'],
  ['Q501', '28289', '#d0dad6'],
  ['Q502', '28290', '#c2d0ce'],
  ['Q503', '28291', '#95a5a3'],
  ['Q504', '28292', '#7e8f8f'],
  ['Q505', '28293', '#545f5d'],
  ['Q506', '28294', '#e4eae6'],
  ['Q507', '28295', '#d2dfdd'],
  ['Q508', '28296', '#c5d5d4'],
  ['Q509', '28297', '#B1C6C6'],
  ['Q510', '28298', '#a0b7b6'],
  ['Q511', '28299', '#8da9a9'],
  ['Q512', '28300', '#e1e7e2'],
  ['Q513', '28301', '#a4c1c1'],
  ['Q514', '28302', '#92b2ae'],
  ['Q515', '28303', '#7ea09e'],
  ['Q516', '28304', '#577a7a'],
  ['Q517', '28305', '#527071'],
  ['Q518', '28306', '#eaede8'],
  ['Q519', '28307', '#d8e3dd'],
  ['Q520', '28308', '#b5cac1'],
  ['Q521', '28309', '#809993'],
  ['Q522', '28310', '#5e7c74'],
  ['Q523', '28311', '#52625d'],
  ['Q524', '28312', '#d9e1df'],
  ['Q525', '28313', '#b6c1be'],
  ['Q526', '28314', '#939c94'],
  ['Q527 Kunnas', '25206', '#707972'],
  ['Q528', '28315', '#5b625b'],
  ['Q529', '28316', '#556058'],
  ['Q530', '28317', '#dbded8'],
  ['Q531', '28318', '#ccd1cc'],
  ['Q532', '28319', '#c0c7c3'],
  ['Q533', '28320', '#afb8b3'],
  ['Q534', '28321', '#8b9791'],
  ['Q535', '28322', '#656e63'],
  ['Q536', '28323', '#d1d5c9'],
  ['Q537', '28324', '#a8b3a5'],
  ['Q538', '28325', '#89988c'],
  ['Q539', '28326', '#7b887d'],
  ['Q540', '28327', '#637168'],
  ['Q541', '28328', '#58645a'],
  ['Q542', '28329', '#e2e8e0'],
  ['Q543', '28330', '#d3dbd2'],
  ['Q544', '28331', '#c0cfc5'],
  ['Q545', '28332', '#b1c2b7'],
  ['Q546', '28333', '#99ada0'],
  ['Q547', '28334', '#728976'],
  ['Q548', '28335', '#d9dfd3'],
  ['Q549', '28336', '#bcc8bb'],
  ['Q550', '28337', '#a2b1a1'],
  ['Q551', '28338', '#8f9f91'],
  ['Q552', '28339', '#66736a'],
  ['Q553', '28340', '#5a655b'],
  ['Q554', '28341', '#dadcce'],
  ['Q555', '28342', '#d3d6c7'],
  ['Q556', '28343', '#c4c9b9'],
  ['Q557', '28344', '#afb5a2'],
  ['Q558', '28345', '#909982'],
  ['Q559', '28346', '#727c5e'],
  ['Q560', '28347', '#e9e8db'],
  ['Q561', '28348', '#d9dbca'],
  ['Q562', '28349', '#c3c3af'],
  ['Q563', '28350', '#959887'],
  ['Q564', '28351', '#707668'],
  ['Q565 Sembra', '25207', '#5c6152'],
  ['Q566', '28352', '#e5e4d6'],
  ['Q567', '28353', '#d8dac8'],
  ['Q568 Verso', '25208', '#b6bca5'],
  ['Q569', '28354', '#9ca287'],
  ['Q570', '28355', '#687055'],
  ['Q571', '28356', '#60634c'],
  ['Q572', '28357', '#cecdbc'],
  ['Q573', '28358', '#b8b7a4'],
  ['Q574 Karviainen', '25209', '#a8a78f'],
  ['Q575 Horsma', '25210', '#7b7b65'],
  ['Q576', '28359', '#5b5f4d'],
  ['Q577', '28360', '#545646'],
  ['Q578', '28361', '#dce2d2'],
  ['Q579', '28362', '#c6d1bd'],
  ['Q580', '28363', '#a7b89e'],
  ['Q581', '28364', '#9aae91'],
  ['Q582', '28365', '#859977'],
  ['Q583', '28366', '#748c64'],
  ['Q584', '28367', '#ece7d3'],
  ['Q585', '28368', '#e1dbc4'],
  ['Q586', '28369', '#d7d1b5'],
  ['Q587', '28370', '#c6bf9a'],
  ['Q588', '28371', '#b6af86'],
  ['Q589', '28372', '#97946d'],
  ['Q590', '28373', '#ece5cf'],
  ['Q591', '28374', '#e0d3b6'],
  ['Q592 Korte', '25211', '#cbc0a6'],
  ['Q593', '28375', '#a89c7d'],
  ['Q594', '28376', '#867954'],
  ['Q595', '28377', '#7f7353'],
  ['Q600', '28378', '#e8dac2'],
  ['Q601 Motti', '25212', '#ddcaaf'],
  ['Q602 Sarvi', '25213', '#d3bc9e'],
  ['Q603', '28380', '#b69770'],
  ['Q604', '28381', '#9d7d5a'],
  ['Q605', '28382', '#876b4d'],
  ['Q606 Vaahto', '25214', '#eae3d7'],
  ['Q607 Suola', '25215', '#e7decf'],
  ['Q608 Ranta', '25216', '#dccfbb'],
  ['Q609 Ilpotar', '28383', '#cabba5'],
  ['Q610', '28384', '#b49e81'],
  ['Q611', '28385', '#a18a68'],
  ['Q612 Vuokko', '25217', '#f2eadf'],
  ['Q613', '28386', '#e0d4c2'],
  ['Q614', '28387', '#d5c6ae'],
  ['Q615 Hiesu', '25218', '#c6b497'],
  ['Q616', '28388', '#ac9a7d'],
  ['Q617', '28389', '#9c8a6e'],
  ['Q618', '28391', '#efeadf'],
  ['Q619 Orakas', '25219', '#e5ddcd'],
  ['Q620', '28392', '#d0c3a8'],
  ['Q621', '28393', '#a99c7c'],
  ['Q622', '28390', '#8f8162'],
  ['Q623', '28394', '#736a52'],
  ['Q624', '28395', '#e6e2d7'],
  ['Q625', '28396', '#d8d4c9'],
  ['Q626 Silmu', '25220', '#c0bbaa'],
  ['Q627', '28397', '#b4ad99'],
  ['Q628', '28398', '#9d9684'],
  ['Q629', '28399', '#7f7963'],
  ['Q630 Metsätähti', '25221', '#E7E2D4'],
  ['Q631', '28400', '#d9d3c3'],
  ['Q632', '28401', '#c6bea9'],
  ['Q633', '28402', '#ada58d'],
  ['Q634', '28403', '#75705c'],
  ['Q635', '28404', '#69614b'],
  ['Q636', '28405', '#e1ddd4'],
  ['Q637 Taival', '25222', '#d0c8be'],
  ['Q638', '28406', '#c0b9ae'],
  ['Q639 Norppa', '25223', '#B3AA9C'],
  ['Q640', '28407', '#999081'],
  ['Q641', '28408', '#736a57'],
  ['Q642 Pellava', '25224', '#dfd0bf'],
  ['Q643 Ilves', '25225', '#ccb8a2'],
  ['Q644 Kääpä', '25226', '#b8a28a'],
  ['Q645', '28409', '#a18870'],
  ['Q646', '28410', '#806952'],
  ['Q647', '28411', '#625449'],
  ['Q648', '28412', '#efe2d5'],
  ['Q649', '28413', '#e5d4c0'],
  ['Q650', '28414', '#dfc5ae'],
  ['Q651', '28415', '#ceac99'],
  ['Q652', '28416', '#a57b65'],
  ['Q653', '28417', '#926851'],
  ['Q654', '28418', '#ecdfce'],
  ['Q655 Kanjoni', '25227', '#dec4ac'],
  ['Q656', '28419', '#caaa8e'],
  ['Q657 Varsa', '25228', '#ac846a'],
  ['Q658', '28420', '#8b654c'],
  ['Q659', '28421', '#74543f'],
  ['Q660 Santa', '25229', '#e8dacd'],
  ['Q661', '28422', '#d3baa5'],
  ['Q662', '28423', '#b69883'],
  ['Q663', '28424', '#957561'],
  ['Q664', '28425', '#836351'],
  ['Q665', '28426', '#775a48'],
  ['Q666', '28427', '#ddcdbf'],
  ['Q667 Kuusama', '25230', '#d4bbaa'],
  ['Q668', '28428', '#a08579'],
  ['Q669', '28429', '#8a6a5d'],
  ['Q670', '28430', '#745144'],
  ['Q671 Hirvi', '25231', '#6b4a3f'],
  ['Q672', '28431', '#e0d5cd'],
  ['Q673', '28432', '#d7c7bb'],
  ['Q674', '28433', '#cebcaf'],
  ['Q675', '28434', '#b39b8b'],
  ['Q676', '28435', '#937d6e'],
  ['Q677 Pahka', '25232', '#735c4c'],
  ['Q678', '28436', '#f2e8dd'],
  ['Q679', '28437', '#d8cec5'],
  ['Q680', '28438', '#c0afa3'],
  ['Q681', '28439', '#9b877c'],
  ['Q682', '28440', '#786559'],
  ['Q683 Tervas', '25233', '#5d4d44'],
  ['Q684', '28441', '#f1ece4'],
  ['Q685', '28442', '#eae5dc'],
  ['Q686', '28443', '#dbd0c3'],
  ['Q687', '28444', '#c2b5a7'],
  ['Q688 Vaarna', '28445', '#a09185'],
  ['Q689', '28446', '#867668'],
  ['Q690 Airut', '28447', '#eceae3'],
  ['Q691', '28448', '#d8d3cc'],
  ['Q692', '28449', '#d1c9c1'],
  ['Q693', '28450', '#baafa3'],
  ['Q694', '28451', '#92857b'],
  ['Q695 Maa', '25234', '#746557'],
  ['Q696', '28452', '#e6dcd2'],
  ['Q697', '28453', '#dad3cc'],
  ['Q698 Tilhi', '25235', '#bab0a6'],
  ['Q699 Retki', '25236', '#887c6d'],
  ['Q700', '28454', '#6f6357'],
  ['Q701 Humus', '25237', '#63584c'],
  ['Q702', '28455', '#e4e3de'],
  ['Q703 Salvos', '28456', '#d5d1ca'],
  ['Q704', '28457', '#bab1a6'],
  ['Q705', '28458', '#887d70'],
  ['Q706', '28459', '#594d44'],
  ['Q707 Peikko', '25238', '#514943'],
  ['Q708', '28460', '#dad9d8'],
  ['Q709', '28461', '#cdc9c4'],
  ['Q710 Aapa', '25239', '#a29a92'],
  ['Q711', '28462', '#8c857e'],
  ['Q712', '28463', '#686258'],
  ['Q713', '28464', '#544f47'],
  ['Q800 Pöllö', '25241', '#d6d2ca'],
  ['Q801 Satakieli', '25240', '#c7c1b8'],
  ['Q802 Haikara', '25242', '#b7b1a8'],
  ['Q803 Hiiri', '25243', '#938e85'],
  ['Q804 Nummi', '25244', '#7f7b71'],
  ['Q805 Liuske', '25245', '#605f5c'],
  ['Q806', '28466', '#d3d2c8'],
  ['Q807', '28467', '#c5c4bb'],
  ['Q808 Tokka', '25246', '#bbb9b1'],
  ['Q809', '28468', '#908f86'],
  ['Q810', '28465', '#7d7a71'],
  ['Q811', '28469', '#6b6c66'],
  ['Q812', '28470', '#dfe1db'],
  ['Q813', '28471', '#b5b6ad'],
  ['Q814 Haapa', '25247', '#8b8f84'],
  ['Q815', '28472', '#777b72'],
  ['Q816', '28473', '#525850'],
  ['Q817', '28474', '#50564e'],
  ['Q818 Tiira', '25276', '#F2F0EB'],
  ['Q819 Kide', '25248', '#e9e7e2'],
  ['Q820', '28475', '#d2d1c7'],
  ['Q821', '28476', '#aeaba3'],
  ['Q822 Pitkos', '25249', '#6c6c67'],
  ['Q823', '28477', '#484641'],
  ['Q824 Tunturi', '25250', '#dcdad3'],
  ['Q825 Käkönen', '28478', '#bebcb5'],
  ['Q826', '28479', '#a9a8a2'],
  ['Q827 Valli', '25251', '#888986'],
  ['Q828', '28480', '#757470'],
  ['Q829 Nuotio', '25252', '#474542'],
  ['Q830', '28481', '#e4e6e5'],
  ['Q831 Viehe', '25253', '#bcc0bf'],
  ['Q832', '28482', '#9ca09e'],
  ['Q833', '28483', '#83898a'],
  ['Q834', '28484', '#666b6b'],
  ['Q835 Ahjo', '28485', '#4e5151'],
  ['Q836', '28486', '#d2d9db'],
  ['Q837', '28487', '#adb3b7'],
  ['Q838', '28488', '#808589'],
  ['Q839', '28489', '#696e71'],
  ['Q840 Suvanto', '28490', '#54595c'],
  ['Q841 Noki', '25254', '#464647'],
  ['Q842 Tuisku', '25255', '#d5d8d9'],
  ['Q843 Kuura', '25256', '#c4c7ca'],
  ['Q844', '28491', '#b3b7ba'],
  ['Q845 Halli', '25257', '#93979b'],
  ['Q846', '28492', '#828689'],
  ['Q847', '28493', '#5e6063'],
  ['Q848 Riekko', '25258', '#e4e3df'],
  ['Q849', '28494', '#d1d1d1'],
  ['Q850', '28495', '#bab9b9'],
  ['Q851', '28496', '#a3a4a6'],
  ['Q852', '28497', '#8b8b8d'],
  ['Q853', '28498', '#646263'],
  ['Q854 Pyry', '25259', '#ebebe8'],
  ['Q855', '28499', '#cbcfd1'],
  ['Q856', '28500', '#b7bbbd'],
  ['Q857 Kyyhky', '25260', '#929596'],
  ['Q858', '28501', '#64676a'],
  ['Q859 Myrskylintu', '25261', '#545659'],
  ['Q860', '28502', '#cdd1d1'],
  ['Q861 Solina', '25262', '#BFC4C6'],
  ['Q862', '28503', '#adb1b4'],
  ['Q863', '28504', '#91969B'],
  ['Q864', '28505', '#5e6267'],
  ['Q865', '28506', '#52555A'],
  ['Q866', '28507', '#d3d9de'],
  ['Q867', '28508', '#b3bdc9'],
  ['Q868', '28509', '#9599a5'],
  ['Q869', '28510', '#7b818c'],
  ['Q870', '28511', '#6a6e7a'],
  ['Q871', '28512', '#595d68'],
  ['Q872', '28513', '#d1cfd0'],
  ['Q873', '28514', '#9f9c9c'],
  ['Q874', '28515', '#959294'],
  ['Q875', '28516', '#69696d'],
  ['Q876', '28517', '#565254'],
  ['Q877', '28518', '#464547'],
  ['Q878', '28519', '#e8e0dd'],
  ['Q879', '28520', '#e0d4d3'],
  ['Q880', '28521', '#cbbebe'],
  ['Q881', '28522', '#9d9192'],
  ['Q882', '28523', '#6d6364'],
  ['Q883', '28524', '#474241'],
  ['U100', '28525', '#f9ecb8'],
  ['U101', '28526', '#f9e497'],
  ['U102', '28527', '#fddc79'],
  ['U103', '28528', '#f2ca34'],
  ['U104', '28529', '#f9e19a'],
  ['U105', '28530', '#fadb81'],
  ['U106', '28531', '#fac524'],
  ['U107', '28532', '#f2b835'],
  ['U108', '28533', '#f8eed7'],
  ['U109', '28534', '#f9e6b2'],
  ['U110', '28535', '#f9e199'],
  ['U111', '28536', '#fbd371'],
  ['U112', '28537', '#fae4a8'],
  ['U113', '28538', '#f7d58a'],
  ['U114', '28539', '#f9cc73'],
  ['U115', '28540', '#fbc361'],
  ['U116', '28541', '#f6e8c6'],
  ['U117', '28542', '#f9e1ae'],
  ['U118', '28543', '#f9cb7a'],
  ['U119', '28544', '#eaa32e'],
  ['U120', '28545', '#f8d0a6'],
  ['U121', '28546', '#f7b269'],
  ['U122', '28547', '#f1993f'],
  ['U123', '28548', '#ee9147'],
  ['U124', '28549', '#f5dcc9'],
  ['U125', '28550', '#f4cfb6'],
  ['U126', '28551', '#edb18a'],
  ['U127', '28552', '#e59266'],
  ['U128', '28553', '#facba9'],
  ['U129', '28554', '#f7ad7f'],
  ['U130', '28555', '#e7925f'],
  ['U131', '28556', '#d67641'],
  ['U132', '28557', '#f3ccb3'],
  ['U133', '28558', '#eead8a'],
  ['U134', '28559', '#e38a5a'],
  ['U135', '28560', '#c0653c'],
  ['U136', '28561', '#f1c5a8'],
  ['U137', '28562', '#eaad95'],
  ['U138', '28563', '#d8937e'],
  ['U139', '28564', '#BC643F'],
  ['U140', '28565', '#f2bfb3'],
  ['U141', '28566', '#eb9d8d'],
  ['U142', '28567', '#db715d'],
  ['U143', '28568', '#c35744'],
  ['U144', '28569', '#f2c3b9'],
  ['U145', '28570', '#e4847a'],
  ['U146', '28571', '#de6258'],
  ['U147', '28572', '#c94238'],
  ['U148', '28573', '#d98b90'],
  ['U149', '28574', '#c66163'],
  ['U150', '28575', '#a74644'],
  ['U151', '28576', '#9d4849'],
  ['U152', '28577', '#f3a4a9'],
  ['U153', '28578', '#e4828d'],
  ['U154', '28579', '#db616b'],
  ['U155', '28580', '#cc434a'],
  ['U156', '28581', '#f2d5d6'],
  ['U157', '28582', '#e8b0b9'],
  ['U158', '28583', '#d27b88'],
  ['U159', '28584', '#b5515c'],
  ['U160', '28585', '#e6d0df'],
  ['U161', '28586', '#d1b0cc'],
  ['U162', '28587', '#c29bbf'],
  ['U163', '28588', '#b78cb2'],
  ['U164', '28589', '#ddc1d8'],
  ['U165', '28590', '#d2afc8'],
  ['U166', '28591', '#c39ab3'],
  ['U167', '28592', '#b98a9c'],
  ['U168', '28593', '#e1c1ca'],
  ['U169', '28594', '#cca1ae'],
  ['U170', '28595', '#be8f9e'],
  ['U171', '28596', '#a07881'],
  ['U172', '28597', '#cdb6c7'],
  ['U173', '28598', '#b898ac'],
  ['U174', '28599', '#9b7d89'],
  ['U175', '28600', '#81636a'],
  ['U176', '28601', '#cec9e1'],
  ['U177', '28602', '#c0b7da'],
  ['U178', '28603', '#9c8eb2'],
  ['U179', '28604', '#887d98'],
  ['U180', '28605', '#c7badb'],
  ['U181', '28606', '#b6a7d0'],
  ['U182', '28607', '#a694c4'],
  ['U183', '28608', '#a293c3'],
  ['U184', '28609', '#c7c4dd'],
  ['U185', '28610', '#a8a8d4'],
  ['U186', '28611', '#8b91c7'],
  ['U187', '28612', '#8789c0'],
  ['U188', '28613', '#b3b5d5'],
  ['U189', '28614', '#a0a4c8'],
  ['U190', '28615', '#898cb6'],
  ['U191', '28616', '#8285ae'],
  ['U192', '28617', '#a3b7e0'],
  ['U193', '28618', '#8ba3d6'],
  ['U194', '28619', '#7b91c5'],
  ['U195', '28620', '#6e82b4'],
  ['U196', '28621', '#8eaed9'],
  ['U197', '28622', '#6f96d0'],
  ['U198', '28623', '#5d86c2'],
  ['U199', '28624', '#517bb6'],
  ['U200', '28625', '#afcae3'],
  ['U201', '28626', '#8eaed3'],
  ['U202', '28627', '#6a8fbc'],
  ['U203', '28628', '#517db0'],
  ['U204', '28629', '#7eb0d8'],
  ['U205', '28630', '#5e9ace'],
  ['U206', '28631', '#4887c1'],
  ['U207', '28632', '#487bad'],
  ['U208', '28633', '#97bed7'],
  ['U209', '28634', '#5f90bd'],
  ['U210', '28635', '#4980b1'],
  ['U211', '28636', '#4378a8'],
  ['U212', '28637', '#b7cad7'],
  ['U213', '28638', '#98b6ca'],
  ['U214', '28639', '#7196b4'],
  ['U215', '28640', '#517a99'],
  ['U216', '28641', '#c9dae3'],
  ['U217', '28642', '#b0cddf'],
  ['U218', '28643', '#9cbdd0'],
  ['U219', '28644', '#689bbd'],
  ['U220', '28645', '#4ebbd1'],
  ['U221', '28646', '#0094AC'],
  ['U222', '28647', '#1f89a0'],
  ['U223', '28648', '#308194'],
  ['U224', '28649', '#c9e0e5'],
  ['U225', '28650', '#b1dbe5'],
  ['U226', '28651', '#7ccbde'],
  ['U227', '28652', '#28b3ca'],
  ['U228', '28653', '#a3dbe1'],
  ['U229', '28654', '#49b8c5'],
  ['U230', '28655', '#198a98'],
  ['U231', '28656', '#417583'],
  ['U232', '28657', '#8ed1c8'],
  ['U233', '28658', '#68cbbb'],
  ['U234', '28659', '#3b9c92'],
  ['U235', '28660', '#2c8684'],
  ['U236', '28661', '#e0e9df'],
  ['U237', '28662', '#bce1ce'],
  ['U238', '28663', '#97ccb3'],
  ['U239', '28664', '#72ad92'],
  ['U240', '28665', '#bee1cf'],
  ['U241', '28666', '#7bb599'],
  ['U242', '28667', '#4b8e6a'],
  ['U243', '28668', '#537762'],
  ['U244', '28669', '#b3dfb7'],
  ['U245', '28670', '#7dc581'],
  ['U246', '28671', '#63b773'],
  ['U247', '28672', '#539d6c'],
  ['U248', '28673', '#cddeb4'],
  ['U249', '28674', '#b3c985'],
  ['U250', '28675', '#97b761'],
  ['U251', '28676', '#7fab54'],
  ['U252', '28677', '#dad994'],
  ['U253', '28678', '#bfc26f'],
  ['U254', '28679', '#a4a74c'],
  ['U255', '28680', '#929044'],
  ['U256', '28681', '#e9e19f'],
  ['U257', '28682', '#dccc76'],
  ['U258', '28683', '#d4be3c'],
  ['U259', '28684', '#c4b03c'],
  ['D100 Pumpuli', '25263', '#eee2d1'],
  ['D101 Ohra', '25264', '#eadcc7'],
  ['D102', '28685', '#efdcbe'],
  ['D103 Kartano', '25265', '#f2c58c'],
  ['D104', '28686', '#d39f68'],
  ['D105 Sinappi', '25266', '#bc8647'],
  ['D106', '28687', '#8a4f3f'],
  ['D107 Uuni', '25267', '#7b473e'],
  ['D108 Tonttu', '25268', '#85453a'],
  ['D109 Torppa', '25269', '#7d443b'],
  ['D110 Menninkäinen', '25270', '#65413F'],
  ['D111 Kajakki', '25271', '#644741'],
  ['D112', '28688', '#e9cdc1'],
  ['D113', '28689', '#BA9282'],
  ['D114', '28690', '#924c3b'],
  ['D115 Vaskitsa', '25272', '#8a4e3f'],
  ['D116 Kekäle', '25273', '#85423d'],
  ['D117', '28691', '#7c453d'],
  ['D118 Seitti', '25274', '#ebe8de'],
  ['D119', '28692', '#d9d5c8'],
  ['D120', '28693', '#9fa192'],
  ['D121', '28694', '#959067'],
  ['D122', '28695', '#8c999c'],
  ['D123', '28696', '#587682'],
  ['D124', '28697', '#626150'],
  ['D125', '28698', '#546b55'],
  ['D126', '28699', '#7E463F'],
  ['D127', '28700', '#68493f'],
  ['D128', '28701', '#50463f'],
  ['D129 Korppi', '25275', '#3C3C3D'],
  ['D130', '28702', '#bbb6ab'],
  ['D131', '28703', '#b2b3b1'],
  ['D132', '28704', '#949798'],
  ['D133', '28705', '#8f9691'],
  ['D134', '28706', '#7c9ca9'],
  ['D135', '28707', '#535456'],
  ['D136', '28708', '#d7d3d0'],
  ['D137', '28709', '#CBCBCB'],
  ['D138', '28710', '#a7b0b5'],
  ['D139', '28711', '#a29d9a'],
  ['D140', '28712', '#9c928a'],
  ['D141 Kivimäki', '28713', '#7c7e7d'],
  ['D142', '28714', '#d73837'],
  ['D143', '28715', '#2d719e'],
  ['D144', '28716', '#f8c026'],
  ['D145', '28717', '#ac3738'],
  ['D146', '28718', '#53865d'],
  ['D147', '28719', '#4b6058'],
]

export default colorsTikkurilaFacade750
