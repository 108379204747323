import { Color } from '../reducers/modules/colors'
import { DefaultDistanceType, DistanceType } from './colorDistances'
import { comparators } from './colorDistances'

export const DOUBLE_PI = Math.PI * 2

export const addRadialDistance = (pointRad: number, lengthRad: number): number => {
  const offsetWithPi = Math.PI + pointRad + lengthRad
  const clamped = offsetWithPi % DOUBLE_PI
  return clamped - Math.PI
}

// Values have to be between [-PI, PI]
export const calculateRadialDistance = (rad1: number, rad2: number): number => {
  let distance = rad1 - rad2
  // eslint-disable-next-line prettier/prettier
  distance += distance > Math.PI ? -DOUBLE_PI : distance < -Math.PI ? DOUBLE_PI : 0
  return distance
}

export const distanceOfColors = (
  reference: Color,
  desired: Color,
  distanceType = DefaultDistanceType,
): number => {
  return comparators[distanceType](reference, desired)
}

// const differenceOfColors = (reference, desired) =>
//   Math.max(reference.r, desired.r) -
//   Math.min(reference.r, desired.r) +
//   Math.max(reference.g, desired.g) -
//   Math.min(reference.g, desired.g) +
//   Math.max(reference.b, desired.b) -
//   Math.min(reference.b, desired.b)

export const closestNeighbourFromSet = (color: Color, desiredSet: Color[]): Color | undefined => {
  if (!desiredSet) {
    return color
  } else
    return desiredSet.reduce<Color | undefined>(
      (memo, candidate) =>
        !memo || distanceOfColors(memo, color) > distanceOfColors(candidate, color)
          ? candidate
          : memo,
      undefined,
    )
}

export type NeighbourghOptions = {
  sweepType?: DistanceType
}

export const neighboursFromSet = (
  color: Color,
  desiredSet: Color[],
  options: NeighbourghOptions = {},
): Color[] => {
  const { sweepType } = options
  return desiredSet.sort(
    (a, b) => distanceOfColors(a, color, sweepType) - distanceOfColors(b, color, sweepType),
  )
}
export const neighbours =
  (desiredSet: Color[]) =>
  (color: Color, options?: NeighbourghOptions): Color[] =>
    neighboursFromSet(color, desiredSet, options)

// const distanceOfColorSets = (
//   referenceSet: Color[],
//   desiredSet: Color[],
// ): number =>
//   referenceSet.reduce<number>(
//     (memo, color) => memo + closestNeighbourFromSet(color, desiredSet),
//     0,
//   ) / referenceSet.length
