import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { verboseTypeHeader } from '../../containers/paletteDisplay'
import { hex2readability, rgb2readability } from '../../modules/colors'
import { useTsSelector } from '../../reducers'
import { Color } from '../../reducers/modules/colors'
import {
  MAX_TYPE,
  MIN_TYPE,
  PaletteArrayType,
  paletteArrayTypeNumberValues,
  palettes,
} from '../../reducers/modules/palettes'
import classNames from '../colorControls.module.sass'
import { ColorPicker } from './colorPicker'
import { NumberPicker } from './numberPicker'

interface ColorControls {
  id: string
  permutationsAmount: number
  type: PaletteArrayType
  color: Color
  isFirstOfTree?: boolean
  background: boolean
}

interface MenuIcon {
  color: string
  background: string
}

const color2readability = (color: Color): string => {
  return rgb2readability(
    {
      r: color.r,
      g: color.g,
      b: color.b,
    },
    { smoothed: true },
  )
}

export const readability = (color: Color): React.CSSProperties => {
  return {
    color: color2readability(color),
  }
}

const border = (color: Color): React.CSSProperties => {
  return {
    borderColor: color2readability(color),
  }
}

const MenuIcon: React.FC<MenuIcon> = ({ color, background }) => (
  <svg className={classNames.icon} viewBox="0 0 200 200" stroke={color} strokeWidth="16">
    <circle cx="100" cy="100" r="90" fill={background} />
    <path d="M50,60 150,60" />
    <path d="M50,100 150,100" />
    <path d="M50,140 150,140" />
  </svg>
)

export const ColorControls: React.FC<ColorControls> = ({
  permutationsAmount,
  type,
  id,
  color,
  background = false,
}) => {
  const [expanded, setExpanded] = useState(false)
  const dispatch = useDispatch()
  const amountOfSwatches = useTsSelector((state) => state.gui.amountOfSwatches)
  const colorSetName = useTsSelector((state) => state.gui.usedColorSet)
  const toggleExpanded = (): void => setExpanded((value) => !value)
  const iconColor = hex2readability(color.hex, {
    smoothed: true,
  })

  const iconBackground = hex2readability(color.hex, {
    smoothed: true,
    inverse: true,
  })

  const handleColorChange = (e): void => {
    dispatch(
      palettes.actions.modifyPalette({
        colorHex: e.currentTarget.value,
        id,
        permutationsAmount: permutationsAmount,
      }),
    )
  }

  const handlePermutationsChange = (e): void => {
    dispatch(
      palettes.actions.modifyPalette({
        colorHex: color.hex,
        id,
        permutationsAmount: Number(e.currentTarget.value),
      }),
    )
  }

  const handleTypeChange = (e): void => {
    dispatch(
      palettes.actions.modifyPalette({
        colorHex: color.hex,
        id,
        permutationsAmount: permutationsAmount,
        type: e.currentTarget.value - 1,
      }),
    )
  }

  const choooseNextStyle = (): void => {
    dispatch(
      palettes.actions.modifyPalette({
        colorHex: color.hex,
        id,
        permutationsAmount: permutationsAmount,
        type: type + 1,
      }),
    )
  }

  const choosePreviousStyle = (): void => {
    dispatch(
      palettes.actions.modifyPalette({
        colorHex: color.hex,
        id,
        permutationsAmount: permutationsAmount,
        type: type - 1,
      }),
    )
  }

  const increaseAmountOfColors = (): void => {
    dispatch(
      palettes.actions.modifyPalette({
        colorHex: color.hex,
        id,
        permutationsAmount: Number(permutationsAmount) + 1,
      }),
    )
  }

  const decreaseAmountOfColors = (): void => {
    dispatch(
      palettes.actions.modifyPalette({
        colorHex: color.hex,
        id,
        permutationsAmount: permutationsAmount - 1,
      }),
    )
  }

  const handleBackgroundChange = (): void => {
    dispatch(
      palettes.actions.modifyPalette({
        colorHex: color.hex,
        id,
        permutationsAmount: permutationsAmount,
        background: !background,
      }),
    )
  }

  const isColorSetHex = colorSetName === 'hex'
  return (
    <div
      className={[classNames.wrapper, expanded ? classNames.expanded : classNames.collapsed].join(
        ' ',
      )}
      style={{ ...readability(color) }}
    >
      <div className={[classNames.container].join(' ')}>
        <div className={classNames.afterDiv} style={{ ...border(color) }}>
          &nbsp;
        </div>
        <div className={classNames.settingsContainer}>
          <div
            style={{ ...border(color) }}
            className={[classNames.column, classNames.expandedOnly].join(' ')}
          >
            <div className={[classNames.value, classNames.expandedOnly].join(' ')}>
              <label>Amount of colors: </label>
              {isColorSetHex ? permutationsAmount : amountOfSwatches * permutationsAmount}
            </div>
            <div className={classNames.setting}>
              <button
                className={classNames.previous}
                onClick={decreaseAmountOfColors}
                disabled={permutationsAmount < 1}
                title={
                  permutationsAmount === 0
                    ? 'Decrease the amount of colors - disabled tue to the minimum amount of colors having been reached'
                    : 'Decrease the amount of colors in this palette'
                }
              >
                &lt;&lt;
              </button>
              <NumberPicker
                value={permutationsAmount}
                min={1}
                changeHandler={handlePermutationsChange}
              />
              <button
                className={classNames.previous}
                onClick={increaseAmountOfColors}
                title="Increase the amount of colors in this palette"
              >
                &gt;&gt;
              </button>
              <span style={{ margin: 'auto 0', flexShrink: 0, width: '3.5em' }}>
                &nbsp;x {amountOfSwatches}
              </span>
            </div>
          </div>
          <div style={{ ...border(color) }} className={classNames.column}>
            <div className={[classNames.value].join(' ')}>
              <label className={classNames.expandedOnly}>Background:</label>
            </div>

            <div className={[classNames.setting, classNames.expandedOnly].join(' ')}>
              <input
                type="checkbox"
                checked={Boolean(background)}
                onChange={handleBackgroundChange}
              />
            </div>
          </div>
          <div style={{ ...border(color) }} className={classNames.column}>
            <div className={[classNames.value].join(' ')}>
              <label className={classNames.expandedOnly}>Base Color: </label>
              {color.name}
            </div>

            <div className={[classNames.setting, classNames.expandedOnly].join(' ')}>
              <ColorPicker value={color.hex} changeHandler={handleColorChange} />
            </div>
          </div>
          <div style={{ ...border(color) }} className={classNames.column}>
            <div className={[classNames.value].join(' ')}>
              <label className={classNames.expandedOnly}>Palette Type: </label>
              {verboseTypeHeader[type]}
            </div>
            {
              <div className={[classNames.setting, classNames.expandedOnly].join(' ')}>
                <button
                  className={classNames.previous}
                  onClick={choosePreviousStyle}
                  disabled={type === MIN_TYPE}
                  title={'Previous palette style'}
                >
                  &lt;&lt;
                </button>
                <NumberPicker
                  value={type + 1}
                  min={1}
                  max={paletteArrayTypeNumberValues.length}
                  changeHandler={handleTypeChange}
                />
                <button
                  className={classNames.next}
                  onClick={choooseNextStyle}
                  disabled={type === MAX_TYPE}
                  title={'Next palette style'}
                >
                  &gt;&gt;
                </button>
              </div>
            }
          </div>
        </div>

        <div className={classNames.expander} onClick={toggleExpanded}>
          <MenuIcon color={iconColor} background={iconBackground} />
        </div>
      </div>
    </div>
  )
}

export default ColorControls
