import React from 'react'
import { useTsSelector } from '../../reducers'
import { NumberPicker } from '../colorControls/numberPicker'

interface AmountOfSwatchesProps {
  changeHandler: (e: React.FormEvent<HTMLInputElement>) => void
  value: number
  max?: number
}
const AmountOfSwatches: React.FC<AmountOfSwatchesProps> = (props) => {
  const colorSetName = useTsSelector((state) => state.gui.usedColorSet)
  const isColorSetHex = colorSetName === 'hex'
  return (
    <label
      style={{
        transition: 'opacity 2s',
        display: isColorSetHex ? 'none' : 'flex',
      }}
    >
      <p style={{ alignItems: 'end', padding: 'none' }}>Nr. of swatches:</p>
      {/* <p style={{ fontSize: '0.8em' }}>(color amount multiplier)</p> */}
      <NumberPicker
        min={1}
        max={props.max}
        value={isColorSetHex ? 1 : props.value}
        changeHandler={props.changeHandler}
      />
    </label>
  )
}
export default AmountOfSwatches
