import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'

import { App } from './components/App'
import { store } from './store'
import { BrowserRouter as Router } from 'react-router-dom'

if (process.env.NODE_ENV === 'development' && module.hot) {
  console.log('hotswapping reducers')
  module.hot.accept('./reducers/index', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('./reducers/index').default
    store.replaceReducer(newRootReducer)
  })
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('app'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
