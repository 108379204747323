// Constants
import { hex2color } from '../../modules/colors'
import montana94 from './paletteset-montana94'
import montanaHC from './paletteset-montana-hc'
import loop from './paletteset-loop'
import tikkurilaFacade760 from './paletteset-tikkurila-facade760'
import tikkurilaSymphony from './paletteset-tikkurila-symphony'
import { Color, Hex } from './colors'

type ColorDataArray = [name: string, productCode: string | number, hex: Hex]

export const DEFAULT_COLOR_SET_NAME = 'hex'

const arrayToColor = (c: ColorDataArray): Color => {
  const color = hex2color(c[2])
  return {
    name: c[0],
    productCode: String(c[1]),
    ...color,
  }
}

export const sets = {
  hex: undefined,
  montana94: (montana94 as ColorDataArray[]).map(arrayToColor),
  montanaHC: (montanaHC as ColorDataArray[]).map(arrayToColor),
  loop: (loop as ColorDataArray[]).map(arrayToColor),
  tikkurilaSymphony: (tikkurilaSymphony as ColorDataArray[]).map(arrayToColor),
  tikkurilaFacade760: (tikkurilaFacade760 as ColorDataArray[]).map(arrayToColor),
}
