import React from 'react'
import { rgb2readability } from '../modules/colors'
import { Color } from '../reducers/modules/colors'
import classNames from './ColorSwatch.module.sass'

interface ColorSwatch {
  color: Color
}
export const ColorSwatch: React.FC<ColorSwatch> = ({ color, children }) => {
  return (
    <div
      key={`${color.r}, ${color.g}, ${color.b}`}
      style={{
        background: `rgba(${color.r}, ${color.g}, ${color.b}, 1)`,
        color: rgb2readability(color, { smoothed: true }),
        boxShadow: `0 0 18px -10px ${rgb2readability(color, {
          smoothed: true,
        })}`,
      }}
      className={classNames.colorSwatch}
    >
      <p>{color.name || color.hex}</p>
      {children}
    </div>
  )
}
