import { Color } from '../reducers/modules/colors'
import { DefaultPaletteArrayType, PaletteArrayType } from '../reducers/modules/palettes'
import * as colors from './colors'
import { okhsv2rgb, okhsv2wb, okwb2okhsv } from './colors/okSpace'
import { addRadialDistance, DOUBLE_PI } from './colorSets'

type EvennessProp = keyof Color

const shouldHideFirstColor = (type: PaletteArrayType): boolean =>
  [PaletteArrayType.FullH, PaletteArrayType.FullHLab].includes(type)

export interface SweepSettings {
  baseOffset?: number
  spectrum?: number
}

// type SettingsForSweep = Record<
//   keyof typeof mapPaletteTypeToEvennessProp,
//   SweepSettings
// >
interface ArrayGenOptions {
  isFullSweep?: boolean // Start sweep from zero or from the given color
  isLuminosityCorrected?: boolean
  isChromaCorrected?: boolean
}

type PaletteArrayGen = (
  color: Color,
  amount: number,
  options?: ArrayGenOptions,
) => (_, i: number) => Color

// Generate a palette which is full in spectrum, filling the SAT/VAL evenly
// const paletteArrayFullFromLinear = (type: EvennessProp): PaletteArrayGen => (
//   color: Color,
//   amount: number,
// ) => {
//   // if (type === 'h') {
//   //   throw new Error('trying to use radial')
//   // }
//   console.log({ type })
//   const baseOffset = 0.01
//   // Width of the spectrum
//   const spectrum = 1 - baseOffset * 2
//   const stepLength = spectrum / amount
//   return (_, i: number) => {
//     const value = Math.min(0, Math.max(baseOffset + i * stepLength), 1)
//     return colors.hsv2color({
//       ...color,
//       [type]: value,
//     })
//   }
// }

const paletteArrayFullFromLinearCyclic = (
  color: Color,
  amount: number,
): ReturnType<PaletteArrayGen> => {
  // If using evenness of hue, offset the values based on initial color
  const baseOffset = color.h
  // Width of the spectrum
  const spectrum = 1
  const stepLength = spectrum / (amount + 2)
  return (_, i: number) => {
    const h = (baseOffset + i * stepLength) % 1
    return colors.hsv2color({
      ...color,
      h,
    })
  }
}

const paletteArrayFullFromLABH: PaletteArrayGen = (color: Color, amount: number) => {
  const stepLength = DOUBLE_PI / amount
  const { lLab } = color
  return (_, i: number) => {
    const h = addRadialDistance(Number(color.hLab), i * stepLength)

    const aLab = colors.aLab(color.cLab, h)
    const bLab = colors.bLab(color.cLab, h)
    return colors.lab2color({
      aLab,
      bLab,
      lLab,
    })
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const paletteArrayFullToWhiteness: PaletteArrayGen = (
  color: Color,
  amount: number,
  options = {},
) => {
  const { isFullSweep = true } = options
  const { okBlackness, okWhiteness } = okhsv2wb(color)
  const baseOffset = isFullSweep ? 0 : okWhiteness
  const topOffset = 0.0
  const spectrumWidth = 1 - (baseOffset + topOffset)
  const stepLength = spectrumWidth / amount
  const { hueOkHsv } = color
  return (_, i: number) => {
    const okWhiteness = Math.max(0, Math.min(baseOffset + stepLength * i, 1))
    const { saturationOkHsv, valueOkHsv } = okwb2okhsv({ okBlackness, okWhiteness })
    const rgb = okhsv2rgb({ hueOkHsv, saturationOkHsv, valueOkHsv })
    return colors.hex2color(colors.rgb2hex(rgb))
  }
}

export const paletteArrayFullWack: PaletteArrayGen = (
  color: Color,
  amount: number,
  options = {},
) => {
  const { isFullSweep = true } = options
  const { okBlackness, okWhiteness } = okhsv2wb(color)
  const baseOffset = isFullSweep ? 0 : okWhiteness
  // const topOffset = 0.8
  const spectrumWidth = 1
  const stepLength = spectrumWidth / ((amount / 5) * 3)
  const { hueOkHsv } = color
  return (_, i: number) => {
    const okWhiteness = Math.max(0, Math.min(baseOffset + stepLength * i, 1))
    const { saturationOkHsv, valueOkHsv } = okwb2okhsv({ okBlackness, okWhiteness })
    const rgb = okhsv2rgb({ hueOkHsv, saturationOkHsv, valueOkHsv })
    return colors.hex2color(colors.rgb2hex(rgb))
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const paletteArrayFullToBlackness: PaletteArrayGen = (
  color: Color,
  amount: number,
  options = {},
) => {
  const { isFullSweep = true } = options
  const { okWhiteness, okBlackness } = okhsv2wb(color)
  const baseOffset = isFullSweep ? 0 : okBlackness
  const topOffset = 0.7
  const spectrumWidth = 1 - (baseOffset + topOffset)
  const stepLength = spectrumWidth / (amount * 1.5)
  const { hueOkHsv } = color
  return (_, i: number) => {
    const okBlackness = Math.max(0, Math.min(baseOffset + stepLength * i, 1))
    const { saturationOkHsv, valueOkHsv } = okwb2okhsv({ okBlackness, okWhiteness })
    const rgb = okhsv2rgb({ hueOkHsv, saturationOkHsv, valueOkHsv })
    return colors.hex2color(colors.rgb2hex(rgb))
  }
}

export const paletteArrayFullFromLABC: PaletteArrayGen = (
  color: Color,
  amount: number,
  options = {},
) => {
  const isLuminosityCorrected = options?.isLuminosityCorrected || true
  const baseOffset = 0.3 / amount
  const topOffset = 0.3 / amount
  const spectrumWidth = 1 - (baseOffset + topOffset)
  const stepLength = spectrumWidth / amount
  return (_, i: number) => {
    const cLab = Math.max(0, Math.min(baseOffset + stepLength * i, 1))
    const aLab = colors.aLab(cLab, color.hLab)
    const bLab = colors.bLab(cLab, color.hLab)
    const lLab = isLuminosityCorrected ? 0.5 : color.lLab

    return colors.lab2color({ lLab, aLab, bLab })
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const paletteArrayFullFromLABL: PaletteArrayGen = (
  color: Color,
  amount: number,
  options,
) => {
  const isChromaCorrected = options?.isChromaCorrected || true
  const baseOffset = 0.3 / amount
  const topOffset = 0.3 / amount
  const spectrumWidth = 1 - (baseOffset + topOffset)
  const stepLength = spectrumWidth / amount

  return (_, i: number): Color => {
    const lLab = Math.max(0, Math.min(baseOffset + stepLength * i, 1))
    if (isChromaCorrected) {
      const cLab = 0.5
      const aLab = colors.aLab(cLab, color.hLab)
      const bLab = colors.bLab(cLab, color.hLab)
      return colors.lab2color({
        aLab,
        bLab,
        lLab,
      })
    } else {
      const { aLab, bLab } = color

      return colors.lab2color({
        aLab,
        bLab,
        lLab,
      })
    }
  }
}

// type NonComparableProps = 'productCode' | 'name'

// type PaletteGeneratorMap = Record<PaletteType, EvennessProp>

type PropsForPaletteSweeps = Record<PaletteArrayType, EvennessProp>

// export const mapPaletteTypeToEvennessPropLinear: Partial<PropsForPaletteSweeps> = {
//   [PaletteArrayType.FullS]: 's',
//   [PaletteArrayType.FullV]: 'v',
// }

export const mapPaletteTypeToEvennessPropLAB: Partial<PropsForPaletteSweeps> = {
  // [PaletteArrayType.FullCLab]: 'cLab',
  // [PaletteArrayType.FullLLab]: 'lLab',
  // [PaletteArrayType.FullSOk]: 'saturationOkHsv',
  // [PaletteArrayType.FullVOk]: 'valueOkHsv',
  [PaletteArrayType.WhitenessOk]: 'okWhiteness',
  [PaletteArrayType.BlacknessOk]: 'okBlackness',
}

export const mapPaletteTypeToEvennessPropRadial: Partial<PropsForPaletteSweeps> = {
  [PaletteArrayType.FullHLab]: 'hLab',
  [PaletteArrayType.FullH]: 'h',
}

export const paletteSortProps = {
  // [PaletteArrayType.FullCLab]: 'cLab',
  // [PaletteArrayType.FullLLab]: 'lLab',
  // [PaletteArrayType.FullSOk]: 'saturationOkHsv',
  // [PaletteArrayType.FullVOk]: 'valueOkHsv',
  [PaletteArrayType.WhitenessOk]: 'okWhiteness',
  [PaletteArrayType.BlacknessOk]: 'okBlackness',
}

export const createPaletteArray = (
  color: Color,
  amount = 3,
  type: PaletteArrayType = DefaultPaletteArrayType,
): Color[] => {
  // handler for generating more complex arrays
  let handler

  // Compare linear [0, 1] props
  // if (type in mapPaletteTypeToEvennessPropLinear) {
  //   handler = paletteArrayFullFromLinear(
  //     mapPaletteTypeToEvennessPropLinear[type],
  //   )
  // } // compare oklab
  // else
  switch (type) {
    case PaletteArrayType.FullHLab:
      handler = paletteArrayFullFromLABH
      break
    case PaletteArrayType.FullH:
      handler = paletteArrayFullFromLinearCyclic
      break
    case PaletteArrayType.WhitenessOk:
      handler = paletteArrayFullToWhiteness
      break
    case PaletteArrayType.BlacknessOk:
      handler = paletteArrayFullToBlackness
      break
    case PaletteArrayType.Wack:
      handler = paletteArrayFullWack
      break
    default:
      console.log('Missing array handler for ', type)
      break
  }

  if (handler) {
    const amountToGenerate = shouldHideFirstColor(type) ? amount + 1 : amount
    const colors = Array.from(Array(amountToGenerate)).map<Color>(handler(color, amountToGenerate))
    return shouldHideFirstColor(type) ? colors.splice(1) : colors
  } else {
    return []
  }
}
