import { lab2rgb, OkHSV, OkHWB, RGB, _rgb2lab } from '../colors'

interface LC {
  lcL: number
  lcC: number
}

interface ST {
  stT: number
  stS: number
}

// OkHSV, OkHSL spec: https://bottosson.github.io/posts/colorpicker/#okhwb
export const okhsv2wb = (hsv: OkHSV): OkHWB => {
  return {
    okWhiteness: (1 - hsv.saturationOkHsv) * hsv.valueOkHsv,
    okBlackness: 1 - hsv.valueOkHsv,
  }
}

// OkHSV, OkHSL spec: https://bottosson.github.io/posts/colorpicker/#okhwb
export const okwb2okhsv = (wb: OkHWB): Omit<OkHSV, 'hueOkHsv'> => {
  return {
    saturationOkHsv: 1 - wb.okWhiteness / (1 - wb.okBlackness),
    valueOkHsv: 1 - wb.okBlackness,
  }
}

// OkHSV, OkHSL spec: https://bottosson.github.io/posts/colorpicker
// toe function for L_r
const toe = (x: number): number => {
  const k_1 = 0.206
  const k_2 = 0.03
  const k_3 = (1 + k_1) / (1 + k_2)
  return 0.5 * (k_3 * x - k_1 + Math.sqrt((k_3 * x - k_1) * (k_3 * x - k_1) + 4 * k_2 * k_3 * x))
}

// OkHSV, OkHSL spec: https://bottosson.github.io/posts/colorpicker
// inverse toe function for L_r
const toeInv = (x: number): number => {
  const k_1 = 0.206
  const k_2 = 0.03
  const k_3 = (1 + k_1) / (1 + k_2)
  return (x * x + k_1 * x) / (k_3 * (x + k_2))
}

// OkHSV, OkHSL spec: https://bottosson.github.io/posts/gamutclipping/
// Finds the maximum saturation possible for a given hue that fits in sRGB
// Saturation here is defined as S = C/L
// a and b must be normalized so a^2 + b^2 == 1
const compute_max_saturation = (a: number, b: number): number => {
  // Max saturation will be when one of r, g or b goes below zero.

  // Select different coefficients depending on which component goes below zero first
  let k0, k1, k2, k3, k4, wl, wm, ws: number

  if (-1.88170328 * a - 0.80936493 * b > 1) {
    // Red component
    k0 = +1.19086277
    k1 = +1.76576728
    k2 = +0.59662641
    k3 = +0.75515197
    k4 = +0.56771245

    wl = +4.0767416621
    wm = -3.3077115913
    ws = +0.2309699292
  } else if (1.81444104 * a - 1.19445276 * b > 1) {
    // Green component
    k0 = +0.73956515
    k1 = -0.45954404
    k2 = +0.08285427
    k3 = +0.1254107
    k4 = +0.14503204

    wl = -1.2684380046
    wm = +2.6097574011
    ws = -0.3413193965
  } else {
    // Blue component
    k0 = +1.35733652
    k1 = -0.00915799
    k2 = -1.1513021
    k3 = -0.50559606
    k4 = +0.00692167

    wl = -0.0041960863
    wm = -0.7034186147
    ws = +1.707614701
  }

  // Approximate max saturation using a polynomial:
  let S = k0 + k1 * a + k2 * b + k3 * a * a + k4 * a * b

  // Do one step Halley's method to get closer
  // this gives an error less than 10e6, except for some blue hues where the dS/dh is close to infinite
  // this should be sufficient for most applications, otherwise do two/three steps

  const k_l = +0.3963377774 * a + 0.2158037573 * b
  const k_m = -0.1055613458 * a - 0.0638541728 * b
  const k_s = -0.0894841775 * a - 1.291485548 * b

  {
    const l_ = 1 + S * k_l
    const m_ = 1 + S * k_m
    const s_ = 1 + S * k_s

    const l = l_ * l_ * l_
    const m = m_ * m_ * m_
    const s = s_ * s_ * s_

    const l_dS = 3 * k_l * l_ * l_
    const m_dS = 3 * k_m * m_ * m_
    const s_dS = 3 * k_s * s_ * s_

    const l_dS2 = 6 * k_l * k_l * l_
    const m_dS2 = 6 * k_m * k_m * m_
    const s_dS2 = 6 * k_s * k_s * s_

    const f = wl * l + wm * m + ws * s
    const f1 = wl * l_dS + wm * m_dS + ws * s_dS
    const f2 = wl * l_dS2 + wm * m_dS2 + ws * s_dS2

    S = S - (f * f1) / (f1 * f1 - 0.5 * f * f2)
  }

  return S
}

const findCusp = (a: number, b: number): LC => {
  // First, find the maximum saturation (saturation S = C/L)
  const S_cusp = compute_max_saturation(a, b)

  // Convert to linear sRGB to find the first point where at least one of r,g or b >= 1:
  const { linearR: _r, linearG: _g } = lab2rgb({
    lLab: 1,
    aLab: S_cusp * a,
    bLab: S_cusp * b,
  })
  const L_cusp = 1 / Math.max(Math.max(_r, _g), b)
  const C_cusp = L_cusp * S_cusp

  return { lcL: L_cusp, lcC: C_cusp }
}

const lc2st = (cusp: LC): ST => {
  const { lcL, lcC } = cusp
  return { stS: lcC / lcL, stT: lcC / (1 - lcL) }
}

// https://bottosson.github.io/posts/colorpicker/#hsv-2
export const okhsv2rgb = (hsv: OkHSV): RGB => {
  const h = hsv.hueOkHsv
  const s = hsv.saturationOkHsv
  const v = hsv.valueOkHsv

  const a_ = Math.cos(2 * Math.PI * h)
  const b_ = Math.sin(2 * Math.PI * h)

  const cusp: LC = findCusp(a_, b_)
  const ST_max: ST = lc2st(cusp)
  const S_max = ST_max.stS
  const T_max = ST_max.stT
  const S_0 = 0.5

  const k = 1 - S_0 / S_max

  // first we compute L and V as if the gamut is a perfect triangle:

  // L, C when v==1:
  const L_v = 1 - (s * S_0) / (S_0 + T_max - T_max * k * s)
  const C_v = (s * T_max * S_0) / (S_0 + T_max - T_max * k * s)

  let L = v * L_v
  let C = v * C_v

  // then we compensate for both toe and the curved top part of the triangle:
  const L_vt = toeInv(L_v)
  const C_vt = (C_v * L_vt) / L_v

  const L_new = toeInv(L)
  C = (C * L_new) / L
  L = L_new

  const { linearR: _r, linearG: _g, linearB: _b } = lab2rgb({
    lLab: L_vt,
    aLab: a_ * C_vt,
    bLab: b_ * C_vt,
  })
  const scale_L = Math.cbrt(1 / Math.max(Math.max(_r, _g), Math.max(_b, 0)))

  L = L * scale_L
  C = C * scale_L

  const { r, g, b } = lab2rgb({ lLab: L, aLab: C * a_, bLab: C * b_ })
  return {
    r,
    g,
    b,
  }
}

// https://bottosson.github.io/posts/colorpicker/#hsv-2
export const rgb2okHsv = (rgb: RGB): OkHSV => {
  const lab = _rgb2lab(rgb)

  let C = Math.sqrt(lab.aLab * lab.aLab + lab.bLab * lab.bLab)
  const a_ = lab.aLab / C
  const b_ = lab.bLab / C

  let L = lab.lLab
  const h = 0.5 + (0.5 * Math.atan2(-lab.bLab, -lab.aLab)) / Math.PI

  const cusp: LC = findCusp(a_, b_)
  const ST_max: ST = lc2st(cusp)
  const S_max = ST_max.stS
  const T_max = ST_max.stT
  const S_0 = 0.5
  const k = 1 - S_0 / S_max

  // first we find L_v, C_v, L_vt and C_vt

  const t = T_max / (C + L * T_max)
  const L_v = t * L
  const C_v = t * C

  const L_vt = toeInv(L_v)
  const C_vt = (C_v * L_vt) / L_v

  // we can then use these to invert the step that compensates for the toe and the curved top part of the triangle:
  const rgb_scale = lab2rgb({ lLab: L_vt, aLab: a_ * C_vt, bLab: b_ * C_vt })
  const scale_L = Math.cbrt(
    1 / Math.max(Math.max(rgb_scale.linearR, rgb_scale.linearG), Math.max(rgb_scale.linearB, 0)),
  )

  L = L / scale_L
  C = C / scale_L

  C = (C * toe(L)) / L
  L = toe(L)

  // we can now compute v and s:

  const v = L / L_v
  const s = ((S_0 + T_max) * C_v) / (T_max * S_0 + T_max * k * C_v)

  return { hueOkHsv: h, saturationOkHsv: s, valueOkHsv: v }
}
