const colorsLoop = [
  ['WHITE', 'LP-100', '#FFFFFF'],
  ['GLOSS WHITE', 'LP-101', '#FFFFFF'],
  ['BLACK MATT', 'LP-103', '#232124'],
  ['BLACK', 'LP-104', '#1A171B'],
  ['BLACK', 'LP-105', '#000000'],
  ['GLOSS DOLLY NOIRE', 'LP-450', '#000000'],
  ['MADRID', 'LP-106', '#FBF193'],
  ['BARCELONA', 'LP-107', '#FFEC65'],
  ['VALENCIA', 'LP-108', '#FFDD00'],
  ['SEVILLA', 'LP-109', '#FDCE07'],
  ['MALAGA', 'LP-110', '#F9B300'],
  ['AMSTERDAM', 'LP-120', '#F3991B'],
  ['HAARLEM', 'LP-121', '#E78010'],
  ['UTRECHT', 'LP-122', '#EC6F07'],

  ['BRUSSELS', 'LP-131', '#FF3300'],
  ['ROTTERDAM', 'LP-123', '#BB4914'],
  ['EINDHOVEN', 'LP-124', '#FEDCA3'],
  ['BREDA', 'LP-125', '#F8B14C'],
  ['HELMOND', 'LP-126', '#F2921F'],
  ['ROSENDHAL', 'LP-127', '#EB6D0F'],
  ['ARNHEM', 'LP-128', '#E75811'],
  ['TILBURG', 'LP-129', '#E04D14'],
  ['OSS', 'LP-130', '#E24415'],
  ['MANCHESTER', 'LP-136', '#E33617'],
  ['LIVERPOOL', 'LP-137', '#D33118'],
  ['NEWCASTLE', 'LP-138', '#BE2C1C'],
  ['YORK', 'LP-139', '#A6241C'],
  ['LEEDS', 'LP-140', '#7B1417'],

  ['SWORDS', 'LP-155', '#E7A4A9'],
  ['CORK', 'LP-156', '#8F443C'],
  ['SLIGO', 'LP-157', '#CC7270'],
  ['CAMBRIDGE', 'LP-141', '#4E0F0F'],
  ['BIRMINGHAM', 'LP-147', '#FF6699'],
  ['NOTTINGHAM', 'LP-148', '#EF7C71'],
  ['WARSAW', 'LP-149', '#B50156'],
  ['COIMBRA', 'LP-160', '#E85A82'],
  ['PORTO', 'LP-161', '#C63D65'],
  ['DERBY', 'LP-142', '#E8586D'],
  ['BRISTOL', 'LP-143', '#E53049'],
  ['BRIGHTON', 'LP-144', '#DA0531'],
  ['OXFORD', 'LP-145', '#963034'],
  ['LONDON', 'LP-146', '#330000'],

  ['DUBLIN', 'LP-150', '#FAEDE2'],
  ['GALWAY', 'LP-151', '#F5B9A6'],
  ['DUNDALK', 'LP-152', '#EE847F'],
  ['WATERFORD', 'LP-153', '#BD4140'],
  ['LIMERICK', 'LP-154', '#F8CBCD'],
  ['FARO', 'LP-158', '#F4B2C1'],
  ['BEJA', 'LP-159', '#F095A7'],
  ['AMADORA', 'LP-162', '#9E174F'],
  ['THESSALONIKI', 'LP-164', '#660033'],
  ['LISBOA', 'LP-163', '#88072F'],
  ['VILNIUS', 'LP-180', '#CCCCFF'],
  ['KALMAR', 'LP-181', '#C9A7CB'],
  ['MALMO', 'LP-182', '#AD83AF'],
  ['GOTEBORG', 'LP-183', '#8B5D8E'],

  ['MORA', 'LP-184', '#591861'],
  ['FALUN', 'LP-185', '#320939'],
  ['STOCKHOLM', 'LP-186', '#EFD1CF'],
  ['HELSINKI', 'LP-187', '#ECC0BE'],
  ['OULU', 'LP-188', '#D78A8C'],
  ['OSLO', 'LP-189', '#AB5265'],
  ['RIGA', 'LP-193', '#663366'],
  ['COPENHAGEN', 'LP-190', '#5B0E45'],
  ['MOSS', 'LP-191', '#6D2C75'],
  ['TALLINN', 'LP-194', '#330033'],
  ['SKAGEN', 'LP-192', '#600D20'],
  ['PARIS', 'LP-200', '#CEE6EC'],
  ['NANCY', 'LP-201', '#A4CAD9'],
  ['MONACO', 'LP-199', '#93D5F5'],

  ['STRASBOURG', 'LP-202', '#7CB3CD'],
  ['METS', 'LP-203', '#3E8E87'],
  ['LENS', 'LP-204', '#1E74A7'],
  ['LILLE', 'LP-205', '#004A7C'],
  ['RENNES', 'LP-206', '#003361'],
  ['CALAIS', 'LP-207', '#142754'],
  ['BREST', 'LP-208', '#0E1A45'],
  ['BORDEAUX', 'LP-209', '#B9CED0'],
  ['NANTES', 'LP-210', '#77A5BF'],
  ['GRENOBLES', 'LP-211', '#5188A4'],
  ['GENEVA', 'LP-220', '#003366'],
  ['LYON', 'LP-212', '#004868'],
  ['ATHENS', 'LP-222', '#004155'],
  ['LIMASSOL', 'LP-223', '#1D2E3F'],

  ['MARSEILLE', 'LP-213', '#C9DBE9'],
  ['NIMES', 'LP-214', '#B1C4D4'],
  ['MONPELLIER', 'LP-215', '#7097B4'],
  ['TOULOUSE', 'LP-216', '#4F6091'],
  ['TAVERNY', 'LP-219', '#336699'],
  ['NICE', 'LP-217', '#0E2D52'],
  ['AJACCIO', 'LP-218', '#1F2A44'],
  ['BASEL', 'LP-221', '#1D327B'],
  ['TORINO', 'LP-250', '#BBDFD8'],
  ['COMO', 'LP-249', '#99CCCC'],
  ['MILANO', 'LP-251', '#61BEBC'],
  ['BERGAMO', 'LP-252', '#13A9A1'],
  ['BRESCIA', 'LP-253', '#007B77'],
  ['VENEZIA', 'LP-254', '#005155'],

  ['SAN MARINO', 'LP-248', '#004553'],
  ['BOLZANO', 'LP-255', '#002E28'],
  ['PIACENZA', 'LP-256', '#D2E8D6'],
  ['PARMA', 'LP-257', '#9ACB9E'],
  ['MODENA', 'LP-258', '#71B26D'],
  ['REGGIO EMILIA', 'LP-259', '#438151'],
  ['BOLOGNA', 'LP-260', '#1E5142'],
  ['FERRARA', 'LP-261', '#163B2B'],
  ['LA SPEZIA', 'LP-262', '#D8DF74'],
  ['PISA', 'LP-263', '#C1D678'],
  ['PRATO', 'LP-264', '#B6CA70'],
  ['LUCCA', 'LP-265', '#86B54B'],
  ['FIRENZE', 'LP-266', '#4E9825'],
  ['SIENA', 'LP-267', '#638234'],

  ['LIVORNO', 'LP-268', '#224A1E'],
  ['PERUGIA', 'LP-269', '#183419'],
  ['SASSARI', 'LP-270', '#DCE3D1'],
  ['CAGLIARI', 'LP-271', '#BFCDB2'],
  ['MESSINA', 'LP-272', '#8E9E75'],
  ['CATANIA', 'LP-273', '#2D4823'],
  ['PALERMO', 'LP-274', '#29341B'],
  ['ROMA', 'LP-275', '#EBE886'],
  ['MORIMONDO', 'LP-276', '#E5E14C'],
  ['NAPOLI', 'LP-277', '#DAD627'],
  ['SALERNO', 'LP-278', '#B9CD28'],
  ['FOGGIA', 'LP-279', '#9FA72D'],
  ['BARI', 'LP-280', '#725F1D'],
  ['CATANZARO', 'LP-281', '#534A19'],

  ['ZAGREB', 'LP-300', '#F4EDB3'],
  ['BRATISLAVA', 'LP-301', '#DDD491'],
  ['BUCAREST', 'LP-302', '#CBBF5C'],
  ['ISTANBUL', 'LP-299', '#C0B561'],
  ['BUDAPEST', 'LP-303', '#AA8413'],
  ['PRAGA', 'LP-304', '#917022'],
  ['BELGRADE', 'LP-305', '#5C4826'],
  ['VIENNA', 'LP-306', '#F6E4C2'],
  ['KREMS', 'LP-307', '#DDC69E'],
  ['LINZ', 'LP-308', '#D3A96B'],
  ['INNSBRUCK', 'LP-327', '#B59253'],
  ['WELS', 'LP-309', '#8A5623'],
  ['SALZBURG', 'LP-310', '#6D4B2A'],
  ['GRAZ', 'LP-311', '#543B25'],

  ['BADEN', 'LP-312', '#2B1307'],
  ['BERLIN', 'LP-312', '#F0D0B1'],
  ['HAMBURG', 'LP-314', '#EAA77D'],
  ['BREMEN', 'LP-315', '#EFA368'],
  ['LEIPZIG', 'LP-326', '#CC6633'],
  ['ZURICH', 'LP-330', '#C4763F'],
  ['HANNOVER', 'LP-316', '#D56112'],
  ['DORTMUND', 'LP-317', '#A55520'],
  ['HALLE', 'LP-318', '#613B23'],
  ['MUNCHEN', 'LP-319', '#F8E3CC'],
  ['STUTTGART', 'LP-320', '#D4B99C'],
  ['NURNBERG', 'LP-321', '#AC825D'],
  ['BERN', 'LP-329', '#8C5B34'],
  ['MAINZ', 'LP-322', '#7B3F2C'],

  ['DUESSELDORF', 'LP-328', '#522A00'],
  ['FRANKFURT', 'LP-323', '#6F4629'],
  ['BONN', 'LP-324', '#775135'],
  ['KOLN', 'LP-325', '#3B1612'],
  ['LOS ANGELES', 'LP-340', '#ECE9E1'],
  ['SAN DIEGO', 'LP-341', '#CCC9C1'],
  ['SAN JOSE', 'LP-342', '#BDB9B0'],
  ['SAN FRANCISCO', 'LP-343', '#A9A5A0'],
  ['OAKLAND', 'LP-344', '#7E7970'],
  ['SACRAMENTO', 'LP-345', '#545551'],
  ['LONG BEACH', 'LP-346', '#444342'],
  ['HOUSTON', 'LP-347', '#E5D4C5'],
  ['SAN ANTONIO', 'LP-348', '#D1B4A2'],
  ['DALLAS', 'LP-349', '#9F7D74'],

  ['AUSTIN', 'LP-350', '#5F4444'],
  ['EL PASO', 'LP-351', '#403439'],
  ['MELBOURNE', 'LP-370', '#F8C8B7'],
  ['SYDNAY', 'LP-371', '#DEAA9A'],
  ['BRISBANE', 'LP-372', '#CF9684'],
  ['CHRISTCHURCH', 'LP-373', '#B4857B'],
  ['WELLINGTON', 'LP-374', '#8B554C'],
  ['MOSCOW', 'LP-375', '#FFA38B'],
  ['ST.PETERSBURG', 'LP-376', '#FF8D6D'],
  ['KRASNODAR', 'LP-377', '#FF6A39'],
  ['NEW YORK', 'LP-352', '#D7D8D7'],
  ['BOSTON', 'LP-353', '#C2C3C9'],
  ['CHICAGO', 'LP-354', '#ABAAB4'],
  ['INDIANAPOLIS', 'LP-368', '#666666'],

  ['PHILLY', 'LP-355', '#666177'],
  ['WASHINGTON', 'LP-356', '#3A3740'],
  ['MIAMI', 'LP-357', '#D8D5C2'],
  ['ORLANDO', 'LP-358', '#B8B39E'],
  ['TAMPA', 'LP-359', '#9B937B'],
  ['JACKSONVILLE', 'LP-360', '#766850'],
  ['KEY WEST', 'LP-361', '#564C42'],
  ['FORT MYERS', 'LP-362', '#382E2C'],
  ['SEATTLE', 'LP-363', '#D8CCC1'],
  ['PORTLANDLAS', 'LP-364', '#B6A18B'],
  ['VEGAS', 'LP-365', '#8C7464'],
  ['DETROIT', 'LP-366', '#644E41'],
  ['BALTIMORE', 'LP-367', '#453536'],

  ['DUBAI', 'LP-378', '#89C275'],
  ['SAO PAULO', 'LP-379', '#1652A1'],
  ['SANTIAGO', 'LP-380', '#EA4890'],

  ['SILVER', 'LP-400', '#BEC2C5'],
  ['GOLD', 'LP-401', '#D5A940'],
  ['COPPER', 'LP-402', '#AA6255'],
  ['FLUO YELLOW', 'LP-410', '#FFED00'],
  ['FLUO ORANGE', 'LP-411', '#F08A00'],
  ['FLUO RED', 'LP-412', '#E53517'],
  ['FLUO', 'LP-413', '#E30045'],
  ['PINK FLUO GREEN', 'LP-414', '#57AB27'],
  ['FLUO BLUE', 'LP-409', '#006699'],
  ['ASPHALT', 'LP-438', '#000000'],
  ['NEON GREEN', 'LP-500', '#FFFF99'],

  ['ASPHALT', 'LP-435', '#000000'],
  ['CHROME', 'LP-436', '#BEC2C5'],
  ['GOLD', 'LP-440', '#D5A940'],
]

export default colorsLoop
