import React from 'react'

export interface NumberPicker {
  changeHandler: (e: React.FormEvent<HTMLInputElement>) => void
  value: number
  min?: number
  max?: number
  disabled?: boolean
}
export const NumberPicker: React.FC<NumberPicker> = ({
  value,
  changeHandler,
  min = 2,
  max = 21,
  disabled = false,
}) => (
  <input
    type="number"
    min={String(min)}
    max={String(max)}
    value={value}
    onInput={(e) => changeHandler(e)}
    disabled={disabled}
  />
)
