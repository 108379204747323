import React from 'react'
import { useDispatch } from 'react-redux'

import { useTsSelector } from '../reducers'

// import classNames from './toolBar.module.sass'
import { gui } from '../reducers/modules/gui'

export const InfoBar: React.FC = () => {
  const dispatch = useDispatch()
  const showInfo = useTsSelector((state) => state.gui.showInfo)

  return showInfo ? (
    <>
      <div
        onClick={() => dispatch(gui.actions.setShowInfo(false))}
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          background: 'rgba(0, 0, 0, 0.4)',
          width: '100vw',
          height: '100vh',
          zIndex: 2000,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '5vh',
            right: '5vw',
            bottom: '5vh',
            left: '5vw',
            width: '90vw',
            height: '90vh',
            background: 'rgba(222, 222, 222, 1)',
            zIndex: 20001,
            display: 'flex',
            overflow: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <div
            style={{ margin: 'auto', padding: '4em' }}
            onClick={(e): void => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <h1>Something Else Than Black</h1>

            <h2>What</h2>
            <p>
              Let users create nice color palettes from restricted sets of colors. Primarily for
              real-world applications where expression is limited to a certain amount of colors -
              200 paints for example.
            </p>
            <h3>Why</h3>
            <p>
              The exact same colors are not available between mediums. Color-ideas are difficult to
              transfer from designs to execution. Finding the restrictions - and possibilities - of
              a color set requires exploration. This tool enables the exploration.
            </p>
            <p>
              Brochures with lists of pigments are difficult to utilize. The colors are in a tight
              grid. One cannot compare two non-adjacent colors without having all of the other
              colors screaming simultaneously from the grid.
            </p>
            <p>
              This has been created in Finland, where everyone wears mostly black - hence the name.
            </p>
            <h3>How</h3>
            <p>
              The idea is to let users explore different combinations of colors, with a little bit
              of help from the computer. It tries to suggest colors which go well with the selected
              color. The colors are presented in a non-distracting and straightforward manner.
            </p>
            <h3>Credits</h3>
            <p>
              The color palettes are from corresponding manufacturers. The data used is what has
              been made publicly available. The color conversions are made in{' '}
              <a href="https://bottosson.github.io/posts/oklab/">Oklab</a> color space.
            </p>
            <h3>Changelog</h3>
            <p>This is work in progress. Below will be a list of changes (newest first):</p>
            <ul>
              <li>
                April 2022: Add button for creating palette from listing (previously from swatch
                only)
              </li>
              <li>April 2022: Add more color sets</li>
              <li>April 2022: Add sharing palettes</li>
              <li>February 2022: add OkHSV color space</li>
              <li>January 2022: add Loop color set</li>
            </ul>

            <div style={{ display: 'flex', paddingTop: '1em' }}>
              <button
                onClick={() => dispatch(gui.actions.setShowInfo(false))}
                style={{ marginLeft: 'auto' }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null
}
