const colorsTikkurilaSymphony = [
  ['F300 Kamelia', '24958', '#F6F3E6'],
  ['G300', '34240', '#F9EFD8'],
  ['H300 Lemonade', '27817', '#FCE8B3'],
  ['J300', '34584', '#FAE29B'],
  ['K300', '34756', '#FFD879'],
  ['L300', '34933', '#ffc824'],
  ['M300', '35107', '#FFC407'],
  ['N300', '35270', '#EFB734'],
  ['S300', '35441', '#F2C24D'],
  ['V300', '35599', '#F1CD64'],
  ['X300', '35753', '#F6E193'],
  ['Y300', '35906', '#F4EACE'],
  ['F301', '34086', '#F7F4E3'],
  ['G301', '34241', '#F8F1D8'],
  ['H301', '34414', '#FCEAB2'],
  ['J301', '34585', '#FCE4A1'],
  ['K301', '27839', '#FFDB76'],
  ['L301', '34934', '#FFCC4B'],
  ['M301', '35108', '#FFC634'],
  ['N301', '35271', '#F0BC3C'],
  ['S301', '35442', '#F3D378'],
  ['V301', '35600', '#F8E08A'],
  ['X301', '35754', '#FBEAAC'],
  ['Y301', '35907', '#FAEDC5'],
  ['F302 Parmesan', '24856', '#FBF2D9'],
  ['G302', '34242', '#FCEBB6'],
  ['H302', '34415', '#FCE2A3'],
  ['J302 Banaani', '24699', '#FBDC8A'],
  ['K302', '34757', '#FFCD4A'],
  ['L302', '34935', '#ffc227'],
  ['M302', '35109', '#FEB700'],
  ['N302', '35272', '#F0B500'],
  ['S302 Greippi', '24731', '#EEC156'],
  ['V302', '35601', '#F2CF75'],
  ['X302', '35755', '#F6E2A6'],
  ['Y302', '28777', '#F2E8D0'],
  ['F303', '34087', '#F9F0D8'],
  ['G303', '34243', '#FBEECD'],
  ['H303 Duuri', '24722', '#FFE5AA'],
  ['J303', '34586', '#FFD687'],
  ['K303 Rentukka', '24877', '#FFC66A'],
  ['L303', '34936', '#FEB23B'],
  ['M303', '35110', '#FDAA0F'],
  ['N303', '35273', '#ECA32B'],
  ['S303', '35443', '#F5AD36'],
  ['V303', '35602', '#FFC869'],
  ['X303', '35756', '#F5D9A3'],
  ['Y303', '35908', '#FAE4BA'],
  ['F304', '34088', '#F9F0D8'],
  ['G304', '34244', '#FAEBC5'],
  ['H304', '27842', '#FFE29F'],
  ['J304', '34587', '#FFD883'],
  ['K304', '34758', '#FFCD71'],
  ['L304', '34937', '#FFB73D'],
  ['M304', '35111', '#FDAB0F'],
  ['N304', '35274', '#f59e18'],
  ['S304', '35444', '#f8a31a'],
  ['V304', '35603', '#FDB136'],
  ['X304', '35757', '#FFC766'],
  ['Y304', '35909', '#F6E3B8'],
  ['F305', '34089', '#F4EBD6'],
  ['G305', '34245', '#F5E7C7'],
  ['H305', '34416', '#FADDB0'],
  ['J305', '34588', '#FCCF89'],
  ['K305', '34759', '#FFBE63'],
  ['L305', '34938', '#FFAF3D'],
  ['M305', '35112', '#FFAB34'],
  ['N305', '35275', '#E69333'],
  ['S305', '35445', '#F2A546'],
  ['V305', '35604', '#FDD99F'],
  ['X305', '35758', '#F5E4C3'],
  ['Y305', '35910', '#F4EBD8'],
  ['F306 Sorbetti', '24910', '#F8EBD1'],
  ['G306', '34246', '#FAEACE'],
  ['H306', '34417', '#FDE3BE'],
  ['J306', '34589', '#FED69E'],
  ['K306', '34760', '#FDC989'],
  ['L306', '34939', '#FFB254'],
  ['M306', '35113', '#F7A233'],
  ['N306', '35276', '#EBA02A'],
  ['S306', '35446', '#F7B654'],
  ['V306', '28789', '#F2CA98'],
  ['X306 Kuskus', '24780', '#F1DAB6'],
  ['Y306', '35911', '#F5E8D4'],
  ['F307 Soft ice', '24909', '#FBF1DE'],
  ['G307', '34247', '#FBEECD'],
  ['H307', '34418', '#FBDCAE'],
  ['J307', '34590', '#FFD493'],
  ['K307', '34761', '#FFC97C'],
  ['L307', '34940', '#FFB957'],
  ['M307', '35114', '#FFA635'],
  ['N307', '35277', '#F6AA54'],
  ['S307', '35447', '#FCB964'],
  ['V307', '27825', '#FFD488'],
  ['X307', '35759', '#F9E0C1'],
  ['Y307', '28762', '#F4EBDB'],
  ['F308', '34090', '#FAE8D1'],
  ['G308', '34248', '#F8DDB9'],
  ['H308', '27822', '#FDD8A5'],
  ['J308', '34591', '#FFCB7F'],
  ['K308', '34762', '#FFC163'],
  ['L308', '34941', '#FFA937'],
  ['M308', '35115', '#FFA127'],
  ['N308', '35278', '#F19F3D'],
  ['S308', '35448', '#FFAD35'],
  ['V308', '35605', '#F9BD65'],
  ['X308 Bearnaise', '24703', '#F6CB89'],
  ['Y308 Munatoti', '24827', '#FADDA5'],
  ['F309 Gardenia', '24727', '#F7F0E4'],
  ['G309 Mousse', '24824', '#FAE9D4'],
  ['H309', '34419', '#FBE5CC'],
  ['J309', '34592', '#FFD8AD'],
  ['K309', '34763', '#FFBE7A'],
  ['L309', '34942', '#FEA244'],
  ['M309', '27830', '#FA943A'],
  ['N309', '35279', '#D88030'],
  ['S309 Päiväntasaaja', '24872', '#CA782F'],
  ['V309', '35606', '#F9A14B'],
  ['X309', '35760', '#FFB773'],
  ['Y309', '35912', '#FFCE8E'],
  ['F310', '34091', '#F4ECDE'],
  ['G310', '34249', '#FBE2BE'],
  ['H310', '34420', '#FED8A1'],
  ['J310 Omeletti', '24846', '#FDCC8A'],
  ['K310', '27834', '#FBBA6C'],
  ['L310', '34943', '#FDAC50'],
  ['M310', '35116', '#ED8B33'],
  ['N310', '35280', '#CC7E43'],
  ['S310', '35449', '#E0904E'],
  ['V310', '35607', '#ECAB72'],
  ['X310', '35761', '#F5C597'],
  ['Y310', '28786', '#F5E7D3'],
  ['F311', '34092', '#FAE5CA'],
  ['G311 Kikherne', '24770', '#FBE2C3'],
  ['H311', '34421', '#FFD9A4'],
  ['J311', '34593', '#FECC8D'],
  ['K311 Juice', '24752', '#F4B469'],
  ['L311', '34944', '#F09E44'],
  ['M311', '35117', '#DF8936'],
  ['N311', '35281', '#CD7B39'],
  ['S311 Helle', '24736', '#DA8C51'],
  ['V311', '35608', '#EFB080'],
  ['X311', '35762', '#F0C8A3'],
  ['Y311', '35913', '#FADDBD'],
  ['F312', '34093', '#FAE9DA'],
  ['G312', '34250', '#F9DBC3'],
  ['H312', '34422', '#FBD0B3'],
  ['J312', '34594', '#FFC59B'],
  ['K312', '34764', '#FFB279'],
  ['L312', '34945', '#FCA163'],
  ['M312', '35118', '#F68836'],
  ['N312', '35282', '#F37F2F'],
  ['S312', '35450', '#EF9357'],
  ['V312', '35609', '#F4AC7D'],
  ['X312', '35763', '#F7D9BF'],
  ['Y312', '35914', '#F6E6D5'],
  ['F313 Galleria', '24726', '#F4ECDF'],
  ['G313 Viuhka', '24952', '#FDECDA'],
  ['H313', '34423', '#FBDCC6'],
  ['J313', '34595', '#FDCBA8'],
  ['K313', '34765', '#FFAE79'],
  ['L313', '34946', '#FF945E'],
  ['M313', '35119', '#E87945'],
  ['N313', '35283', '#CA6636'],
  ['S313', '35451', '#EE8955'],
  ['V313', '35610', '#F9AC7D'],
  ['X313', '35764', '#FCD2B8'],
  ['Y313', '35915', '#F3E0CF'],
  ['F314', '34094', '#FBE6D0'],
  ['G314', '34251', '#FBDDC0'],
  ['H314', '27837', '#FACEAE'],
  ['J314', '34596', '#FEAC7F'],
  ['K314', '34766', '#EE8F5D'],
  ['L314', '27821', '#EA824C'],
  ['M314', '35120', '#E67245'],
  ['N314', '35284', '#DF7046'],
  ['S314', '35452', '#EA8557'],
  ['V314', '35611', '#F4A277'],
  ['X314', '35765', '#F3B99A'],
  ['Y314', '35916', '#FBD8BC'],
  ['F315', '34095', '#FAE8D6'],
  ['G315', '34252', '#F8DFCC'],
  ['H315', '34424', '#FFC8A9'],
  ['J315', '27840', '#FEBC97'],
  ['K315', '34767', '#F79363'],
  ['L315', '34947', '#E77246'],
  ['M315', '28760', '#E56A40'],
  ['N315', '35285', '#C76745'],
  ['S315 Folklore', '30865', '#D1774C'],
  ['V315', '28739', '#E59266'],
  ['X315', '35766', '#F0B692'],
  ['Y315', '35917', '#F7DCC6'],
  ['F316', '34096', '#F9EEEA'],
  ['G316', '34253', '#FCE1D3'],
  ['H316', '34425', '#FEBA9B'],
  ['J316', '34597', '#F79E7A'],
  ['K316', '34768', '#F28160'],
  ['L316', '34948', '#DE6448'],
  ['M316 Tomaatti', '24929', '#D45F49'],
  ['N316', '27841', '#BC5A47'],
  ['S316', '35453', '#D96D54'],
  ['V316', '35612', '#E0866F'],
  ['X316', '35767', '#F2A894'],
  ['Y316', '35918', '#F8D0C3'],
  ['F317 Veistos', '24946', '#F6E8DD'],
  ['G317', '34254', '#F9DBCA'],
  ['H317', '34426', '#FDCDB7'],
  ['J317', '34598', '#F8AE91'],
  ['K317', '34769', '#EA8F73'],
  ['L317', '34949', '#E37961'],
  ['M317', '35121', '#CF6350'],
  ['N317', '35286', '#A84F3A'],
  ['S317', '35454', '#B0553E'],
  ['V317', '35613', '#D57E67'],
  ['X317', '35768', '#EEB09E'],
  ['Y317', '35919', '#F6DED7'],
  ['F318', '34097', '#F6E6E0'],
  ['G318', '34255', '#F6D8CF'],
  ['H318', '34427', '#F2C0B5'],
  ['J318', '34599', '#EB9F8E'],
  ['K318', '28736', '#D8725E'],
  ['L318', '34950', '#C6634E'],
  ['M318', '27828', '#C25846'],
  ['N318', '35287', '#B65E49'],
  ['S318', '35455', '#C16B58'],
  ['V318', '35614', '#E79D89'],
  ['X318', '35769', '#F2BFAF'],
  ['Y318', '35920', '#F7DAD0'],
  ['F319', '34098', '#FAEBE3'],
  ['G319', '34256', '#FCE3DA'],
  ['H319', '34428', '#F9D6CC'],
  ['J319', '34600', '#F5A49A'],
  ['K319 Flamingo', '28720', '#ED8A7E'],
  ['L319', '34951', '#9B564F'],
  ['M319 Riutta', '28722', '#C15140'],
  ['N319', '35288', '#C46052'],
  ['S319', '35456', '#D47669'],
  ['V319', '35615', '#F7A795'],
  ['X319', '35770', '#F7D4C5'],
  ['Y319 Omenankukka', '24847', '#F6E5DB'],
  ['F320', '34099', '#F4E3DD'],
  ['G320', '34257', '#F7D8CC'],
  ['H320 Magnolia', '28721', '#F3C3B7'],
  ['J320', '34601', '#EE9B8E'],
  ['K320', '34770', '#E5857B'],
  ['L320', '34952', '#DC6359'],
  ['M320', '35122', '#C5443A'],
  ['N320', '35289', '#CD6158'],
  ['S320', '35457', '#DC6B60'],
  ['V320', '35616', '#EB9486'],
  ['X320', '35771', '#F9D7CE'],
  ['Y320', '35921', '#F1E2DC'],
  ['F321', '34100', '#F7E4DE'],
  ['G321 Bebe', '24704', '#F7DAD4'],
  ['H321', '34429', '#F5CEC8'],
  ['J321', '34602', '#F0B6AC'],
  ['K321', '34771', '#E3817C'],
  ['L321', '34953', '#D25F52'],
  ['M321', '35123', '#C2473D'],
  ['N321', '35290', '#A44F44'],
  ['S321', '28737', '#B4625E'],
  ['V321', '35617', '#DC9A97'],
  ['X321', '35772', '#EFCBC8'],
  ['Y321', '35922', '#F1DBD4'],
  ['F322', '34101', '#F7DBDB'],
  ['G322', '34258', '#F5D1D1'],
  ['H322', '34430', '#F9C2C1'],
  ['J322', '34603', '#F8A8AD'],
  ['K322', '34772', '#DF7379'],
  ['L322', '34954', '#D3595D'],
  ['M322', '35124', '#BE484C'],
  ['N322', '35291', '#B94B4E'],
  ['S322', '35458', '#CC525E'],
  ['V322', '35618', '#DF7E86'],
  ['X322', '35773', '#F7C7C1'],
  ['Y322', '35923', '#FAD9D1'],
  ['F323', '34102', '#F8E2E1'],
  ['G323', '34259', '#F7D6D5'],
  ['H323', '34431', '#F7C4C4'],
  ['J323', '34604', '#EEA2A3'],
  ['K323', '34773', '#E17F7F'],
  ['L323', '34955', '#D85757'],
  ['M323 Hertta', '30866', '#C03336'],
  ['N323', '28761', '#B54142'],
  ['S323', '35459', '#CC6068'],
  ['V323', '35619', '#EB979A'],
  ['X323', '35774', '#F4D5D3'],
  ['Y323', '35924', '#F4E5E3'],
  ['F324', '34103', '#F9D3D7'],
  ['G324', '34260', '#FBC9CD'],
  ['H324', '34432', '#F5A7AB'],
  ['J324', '34605', '#E6848E'],
  ['K324', '34774', '#E26970'],
  ['L324', '34956', '#DC6069'],
  ['M324', '35125', '#CC444B'],
  ['N324', '35292', '#C74147'],
  ['S324', '35460', '#D35C6A'],
  ['V324', '35620', '#C97E86'],
  ['X324', '35775', '#F4B3B6'],
  ['Y324', '35925', '#F7C2C6'],
  ['F325', '34104', '#F4E5E2'],
  ['G325', '34261', '#F5D1D0'],
  ['H325', '34433', '#F5BBBD'],
  ['J325', '34606', '#EB9697'],
  ['K325', '34775', '#E48386'],
  ['L325', '34957', '#CB555F'],
  ['M325', '35126', '#AC3F40'],
  ['N325', '35293', '#A04748'],
  ['S325', '35461', '#B4575E'],
  ['V325', '35621', '#D59BA0'],
  ['X325', '35776', '#E2B8BB'],
  ['Y325', '35926', '#E7D0D0'],
  ['F326', '34105', '#F7DEDB'],
  ['G326', '34262', '#EEC1C3'],
  ['H326', '34434', '#E19A9E'],
  ['J326', '34607', '#D88B90'],
  ['K326', '34776', '#C76264'],
  ['L326', '34958', '#B85253'],
  ['M326 Marmeladi', '28835', '#A64845'],
  ['N326', '35294', '#9F4A4A'],
  ['S326', '35462', '#B55D63'],
  ['V326', '35622', '#CD8C92'],
  ['X326', '35777', '#EDBFC7'],
  ['Y326', '35927', '#F2D7DA'],
  ['F327', '37018', '#fadce0'],
  ['G327', '34263', '#F6BCC6'],
  ['H327', '34435', '#EA98A5'],
  ['J327', '34608', '#E78291'],
  ['K327', '34777', '#DA5E6A'],
  ['L327', '34959', '#D4525D'],
  ['M327', '35127', '#C23C40'],
  ['N327', '35295', '#B63D3B'],
  ['S327 Daalia', '24718', '#C65962'],
  ['V327', '35623', '#DD919C'],
  ['X327', '35778', '#EEB7C0'],
  ['Y327', '35928', '#F6D6DA'],
  ['F328', '34106', '#F3D4DA'],
  ['G328', '34264', '#F4C7D1'],
  ['H328', '34436', '#EFB4C0'],
  ['J328', '34609', '#E68F9F'],
  ['K328', '34778', '#DF7989'],
  ['L328', '34960', '#D86471'],
  ['M328', '35128', '#C34858'],
  ['N328', '35296', '#B95262'],
  ['S328', '35463', '#CA6A7E'],
  ['V328', '35624', '#DD96A9'],
  ['X328', '35779', '#EDBECA'],
  ['Y328', '35929', '#EED6DD'],
  ['F329', '34107', '#FBEAE7'],
  ['G329', '34265', '#F7DAE1'],
  ['H329', '34437', '#F4B6C6'],
  ['J329', '34610', '#E191A5'],
  ['K329', '34779', '#D76C7F'],
  ['L329', '34961', '#C95062'],
  ['M329', '35129', '#B73E49'],
  ['N329', '35297', '#AD4254'],
  ['S329 Freesia', '30869', '#C85D76'],
  ['V329', '35625', '#D97E93'],
  ['X329', '35780', '#E9ABBC'],
  ['Y329', '35930', '#F1C6CF'],
  ['F330', '34108', '#F5D2D8'],
  ['G330', '34266', '#EDB9C8'],
  ['H330', '34438', '#E9A3B7'],
  ['J330', '34611', '#E28EA3'],
  ['K330', '34780', '#CA657A'],
  ['L330', '34962', '#B84A59'],
  ['M330', '35130', '#A8414B'],
  ['N330', '35298', '#9C4248'],
  ['S330', '35464', '#A45059'],
  ['V330', '35626', '#C0777F'],
  ['X330', '35781', '#D9A0A8'],
  ['Y330', '35931', '#F0D2D8'],
  ['F331', '34109', '#F9E0E4'],
  ['G331', '34267', '#F5D1D8'],
  ['H331', '34439', '#F0BEC9'],
  ['J331', '34612', '#EC9CAC'],
  ['K331', '34781', '#D77A8A'],
  ['L331', '34963', '#B75061'],
  ['M331', '35131', '#B24256'],
  ['N331', '35299', '#99515A'],
  ['S331', '35465', '#B3606C'],
  ['V331', '35627', '#C57A88'],
  ['X331', '35782', '#E6B1BA'],
  ['Y331', '35932', '#F4D5D3'],
  ['F332', '34110', '#F5DCE0'],
  ['G332', '34268', '#EFC1C8'],
  ['H332', '34440', '#E9B0B9'],
  ['J332', '34613', '#E19DA9'],
  ['K332', '34782', '#D27581'],
  ['L332', '34964', '#B4515A'],
  ['M332', '35132', '#AB484F'],
  ['N332 Sakura', '24891', '#A35059'],
  ['S332', '35466', '#B25F6C'],
  ['V332', '35628', '#C67B88'],
  ['X332', '35783', '#DEB9BF'],
  ['Y332', '35933', '#E8CACA'],
  ['F333', '34111', '#F4E5E2'],
  ['G333', '34269', '#EFD8DB'],
  ['H333', '34441', '#E6C3C9'],
  ['J333', '34614', '#DEA7B0'],
  ['K333', '34783', '#C77986'],
  ['L333', '34965', '#B15E68'],
  ['M333', '35133', '#A35159'],
  ['N333', '35300', '#944D51'],
  ['S333', '35467', '#BF757E'],
  ['V333', '35629', '#E0B7BB'],
  ['X333', '35784', '#E6CAC9'],
  ['Y333', '35934', '#EEDCDB'],
  ['F334', '34112', '#F4E5E2'],
  ['G334', '34270', '#F2D3D5'],
  ['H334', '34442', '#E9C1C8'],
  ['J334', '34615', '#DFACB5'],
  ['K334', '34784', '#CB8897'],
  ['L334', '34966', '#A35563'],
  ['M334 Kardinaali', '24764', '#8D4553'],
  ['N334', '35301', '#8B4957'],
  ['S334', '35468', '#9A5569'],
  ['V334', '35630', '#CD8D9F'],
  ['X334', '35785', '#EFB4BF'],
  ['Y334', '35935', '#F4D3D9'],
  ['F335', '34113', '#F6E7E6'],
  ['G335', '34271', '#F2DDDA'],
  ['H335', '34443', '#F0CCD0'],
  ['J335', '34616', '#E1A2AD'],
  ['K335', '34785', '#CD7988'],
  ['L335', '34967', '#B7586C'],
  ['M335', '35134', '#984156'],
  ['N335', '35302', '#99495D'],
  ['S335', '35469', '#B2607B'],
  ['V335', '35631', '#CB7D93'],
  ['X335', '35786', '#E5BDC2'],
  ['Y335 Purkka', '24870', '#EDDEE2'],
  ['F336 Vaahtokarkki', '24940', '#F7E5E4'],
  ['G336', '34272', '#F3D6DC'],
  ['H336 Leivos', '24793', '#EEB9C8'],
  ['J336', '34617', '#E38DA5'],
  ['K336 Karamelli', '24762', '#D2738E'],
  ['L336', '34968', '#BA5571'],
  ['M336 Hibiscus', '24740', '#9F425D'],
  ['N336', '35303', '#85415E'],
  ['S336', '35470', '#A65777'],
  ['V336', '35632', '#CE8AA8'],
  ['X336', '35787', '#E1C1CE'],
  ['Y336', '35936', '#F0DEDD'],
  ['F337', '27971', '#f6e7e6'],
  ['G337', '34273', '#F0DAE1'],
  ['H337', '34444', '#F0D0DC'],
  ['J337', '34618', '#DCA6BF'],
  ['K337', '34786', '#BC7895'],
  ['L337', '34969', '#A55776'],
  ['M337', '35135', '#994367'],
  ['N337', '35304', '#975B75'],
  ['S337', '35471', '#BF88A3'],
  ['V337', '35633', '#D8AEC3'],
  ['X337 Pioni', '24861', '#E4C7D3'],
  ['Y337', '35937', '#E9D5DB'],
  ['F338', '34114', '#F2DBE3'],
  ['G338', '34274', '#EECFDE'],
  ['H338', '34445', '#EBC7DB'],
  ['J338 Barbie', '24700', '#E0A6CA'],
  ['K338', '34787', '#CB81AF'],
  ['L338', '34970', '#AE5788'],
  ['M338', '35136', '#9F4973'],
  ['N338 Villiruusu', '24951', '#955070'],
  ['S338 Syklaami', '24916', '#AB6385'],
  ['V338', '35634', '#BC7DA1'],
  ['X338', '35788', '#E0B9D0'],
  ['Y338', '35938', '#ECCCDE'],
  ['F339 Tutu', '24962', '#F3E3EC'],
  ['G339', '34275', '#EAD6DF'],
  ['H339', '34446', '#E3C6D2'],
  ['J339', '34619', '#D4ACBB'],
  ['K339', '34788', '#C18B9F'],
  ['L339', '34971', '#A35877'],
  ['M339 Tango', '30879', '#66424B'],
  ['N339 Renessanssi', '24876', '#7F4E62'],
  ['S339', '35472', '#955773'],
  ['V339', '35635', '#B484A0'],
  ['X339', '35789', '#DEC1D3'],
  ['Y339 Malvikki', '24810', '#ECE0E9'],
  ['F340', '34115', '#F4E9ED'],
  ['G340', '34276', '#E8D4E0'],
  ['H340', '34447', '#E0C3D3'],
  ['J340', '34620', '#C395AE'],
  ['K340', '34789', '#AB7795'],
  ['L340', '34972', '#996381'],
  ['M340', '35137', '#78495E'],
  ['N340', '35305', '#694C5B'],
  ['S340', '35473', '#88607B'],
  ['V340', '35636', '#B795AF'],
  ['X340', '35790', '#CDB1C5'],
  ['Y340', '35939', '#E3D1DC'],
  ['F341', '34116', '#E0D0E3'],
  ['G341', '34277', '#D5C0D7'],
  ['H341', '34448', '#C2A3C3'],
  ['J341', '34621', '#A37A9E'],
  ['K341', '34790', '#7F5777'],
  ['L341', '34973', '#6A4A63'],
  ['M341', '35138', '#584150'],
  ['N341', '35306', '#7B5A71'],
  ['S341', '35474', '#8C6A83'],
  ['V341', '35637', '#B094AF'],
  ['X341', '35791', '#CEB9CF'],
  ['Y341', '35940', '#E0D1E1'],
  ['F342', '27972', '#EFE0EA'],
  ['G342 Marilyn', '24813', '#E6D3E4'],
  ['H342', '34449', '#D2BBDA'],
  ['J342', '34622', '#C8ACD5'],
  ['K342', '34791', '#9B74A9'],
  ['L342', '34974', '#745182'],
  ['M342', '35139', '#6C4B78'],
  ['N342', '35307', '#6F5576'],
  ['S342', '35475', '#8E729A'],
  ['V342', '35638', '#A68BB1'],
  ['X342 Hyasintti', '24744', '#C7B5D0'],
  ['Y342', '35941', '#DCCEE0'],
  ['F343', '34117', '#EBE9EE'],
  ['G343', '34278', '#E3DCEA'],
  ['H343', '34450', '#D0C3DF'],
  ['J343', '34623', '#C2B3D7'],
  ['K343', '34792', '#AA9AC7'],
  ['L343', '34975', '#8A73AA'],
  ['M343', '35140', '#675285'],
  ['N343', '35308', '#675979'],
  ['S343', '35476', '#85759C'],
  ['V343', '35639', '#9D8DB1'],
  ['X343', '35792', '#CBC6DF'],
  ['Y343', '35942', '#DEDAE7'],
  ['F344', '34118', '#E6E1E5'],
  ['G344', '34279', '#DFDAE7'],
  ['H344', '34451', '#CDCCE2'],
  ['J344', '34624', '#BEBAD7'],
  ['K344', '34793', '#867AAE'],
  ['L344', '34976', '#685D90'],
  ['M344', '35141', '#504B70'],
  ['N344', '35309', '#65618F'],
  ['S344', '35477', '#8A84B2'],
  ['V344', '35640', '#BFBBD5'],
  ['X344 Menuetti', '30884', '#CECADB'],
  ['Y344 Orvokki', '30881', '#E1E0E8'],
  ['F345', '34119', '#E6E2E6'],
  ['G345', '34280', '#DAD3E4'],
  ['H345', '34452', '#C9C1E2'],
  ['J345', '34625', '#B9B2DC'],
  ['K345', '34794', '#867EB6'],
  ['L345', '34977', '#665F97'],
  ['M345', '35142', '#45435C'],
  ['N345', '35310', '#565571'],
  ['S345', '35478', '#857EA6'],
  ['V345', '35641', '#B9B3D4'],
  ['X345', '35793', '#DAD4E2'],
  ['Y345', '35943', '#E4DFE9'],
  ['F346', '34120', '#E5E3ED'],
  ['G346', '34281', '#D2CFE8'],
  ['H346', '34453', '#C4C1E4'],
  ['J346', '34626', '#9E9BD1'],
  ['K346', '34795', '#7670B0'],
  ['L346', '34978', '#4B4875'],
  ['M346', '35143', '#424054'],
  ['N346', '35311', '#52507B'],
  ['S346', '35479', '#70749D'],
  ['V346', '35642', '#A7ABCD'],
  ['X346', '35794', '#C3C5DD'],
  ['Y346', '35944', '#DADAE7'],
  ['F347', '34121', '#E8E9EE'],
  ['G347', '34282', '#D1D5E9'],
  ['H347', '34454', '#BBC2E2'],
  ['J347', '34627', '#A0A9D7'],
  ['K347', '34796', '#6E78B5'],
  ['L347', '34979', '#57609D'],
  ['M347', '35144', '#424773'],
  ['N347', '35312', '#566092'],
  ['S347', '35480', '#6F799E'],
  ['V347', '35643', '#AAB3D0'],
  ['X347', '35795', '#C6CCE0'],
  ['Y347', '35945', '#DCDFE9'],
  ['F348', '34122', '#E4E5EB'],
  ['G348', '34283', '#C8D1E3'],
  ['H348', '34455', '#B2C0DF'],
  ['J348', '34628', '#99AFD9'],
  ['K348', '34797', '#677DB4'],
  ['L348', '34980', '#596DA6'],
  ['M348', '35145', '#434D81'],
  ['N348 Nocturne', '30885', '#505B75'],
  ['S348 Loitsu', '24800', '#697593'],
  ['V348', '35644', '#8B95AE'],
  ['X348 Ladylike', '24789', '#C7CEDE'],
  ['Y348 Antarktis', '24743', '#D7DBE5'],
  ['F349', '34123', '#DBDFEA'],
  ['G349', '34284', '#C1CEE4'],
  ['H349', '34456', '#A3B6D8'],
  ['J349', '34629', '#8EA5CD'],
  ['K349', '34798', '#667FB4'],
  ['L349', '34981', '#566EA5'],
  ['M349', '35146', '#485478'],
  ['N349', '35313', '#53618A'],
  ['S349', '35481', '#8299BC'],
  ['V349', '35645', '#A0B1CD'],
  ['X349', '35796', '#BECADD'],
  ['Y349', '35946', '#D3DCE6'],
  ['F350', '34124', '#E2E6EC'],
  ['G350', '34285', '#D8DEE9'],
  ['H350', '34457', '#C0CCE3'],
  ['J350', '34630', '#A3B5D6'],
  ['K350', '34799', '#6B7DA2'],
  ['L350', '28793', '#526184'],
  ['M350 Mustekynä', '24829', '#3D4360'],
  ['N350', '35314', '#535B70'],
  ['S350', '35482', '#677088'],
  ['V350', '35646', '#8593AF'],
  ['X350', '35797', '#AFB8CC'],
  ['Y350', '35947', '#D4D9E3'],
  ['F351 Kaiku', '24754', '#DDE7EE'],
  ['G351 Pisara', '24864', '#D0DFEC'],
  ['H351', '34458', '#B1CBE6'],
  ['J351', '34631', '#86A8D3'],
  ['K351', '34800', '#6587BD'],
  ['L351', '34982', '#4D6CA6'],
  ['M351', '27818', '#40568A'],
  ['N351', '35315', '#4F73A2'],
  ['S351', '35483', '#6E91BC'],
  ['V351', '35647', '#8FACD2'],
  ['X351', '35798', '#A5C2DF'],
  ['Y351', '35948', '#D3DCE8'],
  ['F352', '34125', '#E3E7EC'],
  ['G352', '34286', '#D3DDE9'],
  ['H352', '34459', '#C6D6E5'],
  ['J352', '34632', '#B2CBE2'],
  ['K352', '34801', '#769CCB'],
  ['L352', '34983', '#4B6C9C'],
  ['M352', '35147', '#314871'],
  ['N352', '35316', '#486C99'],
  ['S352 Sinisiipi', '24906', '#7794B5'],
  ['V352', '35648', '#A1BBD6'],
  ['X352', '35799', '#B2C7DE'],
  ['Y352', '35949', '#D4DDE8'],
  ['F353', '34126', '#DDE6EC'],
  ['G353', '34287', '#C3D3E5'],
  ['H353 Lemmikki', '24794', '#B3C8DE'],
  ['J353', '34633', '#9EB9D6'],
  ['K353', '34802', '#608BB7'],
  ['L353', '34984', '#415E8A'],
  ['M353', '35148', '#394767'],
  ['N353', '35317', '#4A6F9D'],
  ['S353', '35484', '#6991BC'],
  ['V353 Miete', '24820', '#9CB5CC'],
  ['X353', '35800', '#C1D1E0'],
  ['Y353', '35950', '#DBE3E9'],
  ['F354', '34127', '#D8E6EE'],
  ['G354', '34288', '#CFE0EA'],
  ['H354', '34460', '#B2D0E4'],
  ['J354 Päiväuni', '24873', '#88B3D4'],
  ['K354', '34803', '#5A96CC'],
  ['L354', '34985', '#276FAD'],
  ['M354 Ara', '28726', '#2D62A0'],
  ['N354', '35318', '#3B5A83'],
  ['S354', '35485', '#4B79A7'],
  ['V354', '35649', '#749EBF'],
  ['X354', '35801', '#B6CFDE'],
  ['Y354 Cumulus', '24716', '#C6D9E5'],
  ['F355', '34128', '#D3E5EE'],
  ['G355', '34289', '#BAD6E9'],
  ['H355', '34461', '#91BFE0'],
  ['J355', '34634', '#6FA9D3'],
  ['K355', '34804', '#4E87BC'],
  ['L355', '34986', '#3A6DA4'],
  ['M355', '35149', '#365688'],
  ['N355', '35319', '#3C6998'],
  ['S355', '35486', '#5082B1'],
  ['V355', '35650', '#93B8D1'],
  ['X355', '35802', '#B4CFE0'],
  ['Y355', '35951', '#CEE0E8'],
  ['F356', '34129', '#D0DEEB'],
  ['G356', '34290', '#BDD7E9'],
  ['H356', '34462', '#A2C9E6'],
  ['J356 Taivas', '28725', '#80B1D9'],
  ['K356', '34805', '#3B8DC3'],
  ['L356', '28751', '#186CA6'],
  ['M356', '35150', '#225580'],
  ['N356', '35320', '#305574'],
  ['S356', '35487', '#76A4C2'],
  ['V356', '35651', '#A1C2D9'],
  ['X356', '35803', '#C8D9E6'],
  ['Y356', '35952', '#DAE4EB'],
  ['F357', '34130', '#DCE5E6'],
  ['G357', '34291', '#C6DBE4'],
  ['H357', '34463', '#A3CFE4'],
  ['J357', '34635', '#8DBDD9'],
  ['K357', '34806', '#3B85AB'],
  ['L357', '34987', '#2F6B90'],
  ['M357', '35151', '#364559'],
  ['N357', '35321', '#354A5C'],
  ['S357', '35488', '#356280'],
  ['V357', '35652', '#6AA0BC'],
  ['X357', '35804', '#A4CADA'],
  ['Y357', '35953', '#D1E3E6'],
  ['F358 Scuba', '24897', '#E8EFED'],
  ['G358', '34292', '#CCE2E6'],
  ['H358', '34464', '#BBDAE6'],
  ['J358', '34636', '#70B9DD'],
  ['K358', '34807', '#0191C5'],
  ['L358', '34988', '#007EB8'],
  ['M358', '35152', '#006295'],
  ['N358', '35322', '#0E5B89'],
  ['S358', '35489', '#51A5C9'],
  ['V358', '35653', '#86C7E0'],
  ['X358', '35805', '#C1DAE4'],
  ['Y358', '35954', '#DEEBEB'],
  ['F359', '34131', '#E3ECEB'],
  ['G359', '34293', '#CBDFE3'],
  ['H359', '34465', '#ABD3E5'],
  ['J359', '34637', '#69AFCF'],
  ['K359', '34808', '#008DBC'],
  ['L359', '34989', '#0073A1'],
  ['M359', '35153', '#00628E'],
  ['N359', '35323', '#274D68'],
  ['S359', '35490', '#257296'],
  ['V359', '35654', '#6FAEC7'],
  ['X359', '35806', '#BFD8DF'],
  ['Y359', '35955', '#E0ECEB'],
  ['F360', '34132', '#DCEBEB'],
  ['G360', '34294', '#BBE5EF'],
  ['H360', '34466', '#8ED6EC'],
  ['J360', '34638', '#53C1E5'],
  ['K360', '34809', '#007EB4'],
  ['L360', '34990', '#22517D'],
  ['M360', '35154', '#354264'],
  ['N360', '35324', '#295070'],
  ['S360', '35491', '#007BA5'],
  ['V360', '35655', '#64BAD6'],
  ['X360', '35807', '#BDE1E9'],
  ['Y360', '35956', '#D3E6E9'],
  ['F361', '34133', '#D6E9EB'],
  ['G361', '34295', '#BCDFE7'],
  ['H361', '27836', '#9AD0E2'],
  ['J361', '34639', '#6CB6D6'],
  ['K361', '34810', '#3392BF'],
  ['L361', '34991', '#287197'],
  ['M361', '35155', '#1B5477'],
  ['N361', '35325', '#274E67'],
  ['S361', '35492', '#24698A'],
  ['V361', '35656', '#56A3C6'],
  ['X361', '35808', '#87C4DF'],
  ['Y361', '35957', '#C1E0EC'],
  ['F362', '34134', '#DAE7E8'],
  ['G362', '34296', '#BCDAE6'],
  ['H362', '34467', '#9FC8D8'],
  ['J362', '34640', '#79B8D3'],
  ['K362', '34811', '#4B9BBB'],
  ['L362', '34992', '#217295'],
  ['M362', '35156', '#266180'],
  ['N362', '35326', '#376782'],
  ['S362', '35493', '#497B95'],
  ['V362', '35657', '#81B4CA'],
  ['X362', '35809', '#A6CBDA'],
  ['Y362', '35958', '#D7E5E7'],
  ['F363', '34135', '#D1E9ED'],
  ['G363', '34297', '#B5DFEA'],
  ['H363', '34468', '#8FD0E3'],
  ['J363', '34641', '#67B5CB'],
  ['K363', '34812', '#3294AE'],
  ['L363', '34993', '#14738E'],
  ['M363', '35157', '#21637D'],
  ['N363', '35327', '#236379'],
  ['S363', '35494', '#248099'],
  ['V363', '35658', '#59A5BC'],
  ['X363', '35810', '#94CCDD'],
  ['Y363', '35959', '#D1E5E9'],
  ['F364', '34136', '#DAEAEB'],
  ['G364', '34298', '#BFDDE1'],
  ['H364', '34469', '#AFD7E2'],
  ['J364', '34642', '#67C1D5'],
  ['K364', '34813', '#1A90AA'],
  ['L364', '34994', '#007891'],
  ['M364', '35158', '#256276'],
  ['N364', '35328', '#217189'],
  ['S364', '35495', '#3091AA'],
  ['V364', '35659', '#94C4D2'],
  ['X364', '35811', '#C5DEE4'],
  ['Y364', '35960', '#D8E7E8'],
  ['F365', '34137', '#E3EBEB'],
  ['G365', '34299', '#BFE2EA'],
  ['H365', '34470', '#A0D8E8'],
  ['J365', '34643', '#4ABAD0'],
  ['K365', '34814', '#0098B1'],
  ['L365', '34995', '#007D94'],
  ['M365', '35159', '#00657B'],
  ['N365', '35329', '#29687D'],
  ['S365', '35496', '#3B7D93'],
  ['V365', '35660', '#7AAEBD'],
  ['X365', '35812', '#ADCBD5'],
  ['Y365', '35961', '#D3DFE2'],
  ['F366', '34138', '#D8E8EA'],
  ['G366', '34300', '#BFE2E5'],
  ['H366', '34471', '#A1D8DE'],
  ['J366', '34644', '#46B9C6'],
  ['K366', '34815', '#22A7B1'],
  ['L366', '34996', '#008993'],
  ['M366 Riikinkukko', '30886', '#165865'],
  ['N366', '35330', '#28606D'],
  ['S366', '28771', '#0A8091'],
  ['V366', '35661', '#62B1C1'],
  ['X366', '35813', '#BCDCE1'],
  ['Y366', '35962', '#E3E9E8'],
  ['F367', '34139', '#D6E9EB'],
  ['G367', '34301', '#B6D7DA'],
  ['H367', '34472', '#99C3C7'],
  ['J367 Mosaiikki', '24823', '#6FA4AA'],
  ['K367', '34816', '#32858C'],
  ['L367', '34997', '#00676A'],
  ['M367 Akvamariini', '24666', '#1A5659'],
  ['N367', '35331', '#3A7077'],
  ['S367', '35497', '#86AAAC'],
  ['V367', '35662', '#B5CACB'],
  ['X367', '35814', '#CDDAD8'],
  ['Y367', '35963', '#D9E3E1'],
  ['F368', '34140', '#E3E7E1'],
  ['G368', '34302', '#D9E4DE'],
  ['H368', '34473', '#BFDEDC'],
  ['J368', '34645', '#8FBDBD'],
  ['K368', '34817', '#52918F'],
  ['L368', '34998', '#3D7876'],
  ['M368', '35160', '#2E5F65'],
  ['N368', '35332', '#467172'],
  ['V368', '35663', '#B7D1CC'],
  ['X368', '35815', '#D6E4E2'],
  ['S368', '35498', '#749A96'],
  ['Y368', '35964', '#E3ECEB'],
  ['F369', '34141', '#E4EFEA'],
  ['G369', '34303', '#C1DBD7'],
  ['H369', '34474', '#A5C9C5'],
  ['J369', '34646', '#7CA7A0'],
  ['K369', '34818', '#457871'],
  ['L369', '34999', '#294B46'],
  ['M369', '35161', '#374F4C'],
  ['N369', '35333', '#41645F'],
  ['S369', '35499', '#83A3A0'],
  ['V369', '35664', '#A4C6C1'],
  ['X369', '35816', '#CEE0DB'],
  ['Y369', '35965', '#E0EAE5'],
  ['F370', '34142', '#E8EEE6'],
  ['G370', '34304', '#DFE9DF'],
  ['H370', '34475', '#C3DFD8'],
  ['J370', '34647', '#A6D6CC'],
  ['K370', '34819', '#7FBDB6'],
  ['L370', '35000', '#3F867B'],
  ['M370', '35162', '#21605E'],
  ['N370', '35334', '#38716D'],
  ['S370', '35500', '#58938C'],
  ['V370', '35665', '#84B8B0'],
  ['X370 Tiffany', '24925', '#A7CFC8'],
  ['Y370 Capri', '24711', '#BFE0D4'],
  ['F371', '34143', '#E5EEED'],
  ['G371 Kristalli', '28730', '#D7EEE5'],
  ['H371', '34476', '#9DE3D5'],
  ['J371', '34648', '#5CCDB8'],
  ['K371', '34820', '#009C7E'],
  ['L371', '35001', '#007867'],
  ['M371', '35163', '#036152'],
  ['N371', '35335', '#016E5D'],
  ['S371', '35501', '#00A18A'],
  ['V371', '35666', '#5CC2B1'],
  ['X371', '35817', '#A6E1D6'],
  ['Y371', '35966', '#D6EBE3'],
  ['F372', '34144', '#E7F1E8'],
  ['G372', '34305', '#DBEEE4'],
  ['H372', '34477', '#C0E6D7'],
  ['J372', '34649', '#90DAC2'],
  ['K372', '34821', '#38B68D'],
  ['L372', '35002', '#00966C'],
  ['M372', '35164', '#00805A'],
  ['N372', '35336', '#2B8363'],
  ['S372', '35502', '#4FB491'],
  ['V372', '35667', '#8CCEB5'],
  ['X372', '35818', '#C0E3D6'],
  ['Y372', '35967', '#D9E8E1'],
  ['F373', '34145', '#EAF2E8'],
  ['G373', '34306', '#D3EADC'],
  ['H373', '34478', '#B0E2CB'],
  ['J373', '34650', '#82CFAF'],
  ['K373', '34822', '#4CA988'],
  ['L373 Smaragdi', '28728', '#309173'],
  ['M373', '35165', '#037058'],
  ['N373', '35337', '#206B5B'],
  ['S373 Bospori', '24707', '#578D7C'],
  ['V373', '35668', '#97C4B2'],
  ['X373', '35819', '#C5DDD0'],
  ['Y373', '35968', '#DAE8DE'],
  ['F374', '34146', '#EAECE4'],
  ['G374', '34307', '#DCEDE2'],
  ['H374', '34479', '#B7DCC9'],
  ['J374', '34651', '#94C8AD'],
  ['K374', '34823', '#70AB8C'],
  ['L374', '35003', '#5A9677'],
  ['M374', '35166', '#47785C'],
  ['N374', '35338', '#447463'],
  ['S374', '35503', '#88A89B'],
  ['V374', '35669', '#ADC7BD'],
  ['X374', '35820', '#CEE1D4'],
  ['Y374', '35969', '#DDE8DE'],
  ['F375', '34147', '#E1EFDA'],
  ['G375', '34308', '#C8E7C3'],
  ['H375', '34480', '#AEDCB3'],
  ['J375', '34652', '#6BBB75'],
  ['K375', '34824', '#4B9E68'],
  ['L375', '35004', '#3D895B'],
  ['M375', '35167', '#326645'],
  ['N375', '35339', '#42644F'],
  ['S375', '35504', '#7AA17F'],
  ['V375', '35670', '#A8C1A5'],
  ['X375', '35821', '#D1DBCA'],
  ['Y375', '35970', '#E4EAE0'],
  ['F376', '34148', '#E8EAE0'],
  ['G376', '34309', '#CFDDC6'],
  ['H376', '34481', '#BCD1B9'],
  ['J376', '34653', '#8FAE8B'],
  ['K376', '34825', '#6D8B6D'],
  ['L376', '35005', '#597358'],
  ['M376', '35168', '#405843'],
  ['N376', '35340', '#425547'],
  ['S376', '35505', '#587265'],
  ['V376 Merenneito', '24818', '#708B7B'],
  ['X376', '35822', '#9BB3A7'],
  ['Y376', '35971', '#D5E0D7'],
  ['F377', '34149', '#E8ECE3'],
  ['G377', '34310', '#DCE3D5'],
  ['H377', '34482', '#C5D5C3'],
  ['J377', '34654', '#B3C7B1'],
  ['K377', '34826', '#8DA884'],
  ['L377', '35006', '#547352'],
  ['M377', '35169', '#3E5845'],
  ['N377', '35341', '#547458'],
  ['S377', '35506', '#668A6E'],
  ['V377', '35671', '#ACBEAA'],
  ['X377', '35823', '#DFE6DB'],
  ['Y377', '35972', '#ECEEE6'],
  ['F378', '34150', '#ECEFE6'],
  ['G378', '34311', '#E4F0D5'],
  ['H378', '34483', '#C4DBBE'],
  ['J378', '34655', '#A8C9A2'],
  ['K378', '34827', '#6D9474'],
  ['L378', '35007', '#56785C'],
  ['M378', '35170', '#3A5D41'],
  ['N378 Ficus', '30891', '#434A41'],
  ['S378', '35507', '#5B685C'],
  ['V378', '35672', '#839282'],
  ['X378', '35824', '#B9C4B1'],
  ['Y378', '35973', '#CDD9CB'],
  ['F379', '34151', '#E3E7DD'],
  ['G379', '34312', '#D9E4CE'],
  ['H379', '34484', '#BBDDA2'],
  ['J379', '34656', '#7FB472'],
  ['K379', '34828', '#5B9A57'],
  ['L379', '35008', '#37783E'],
  ['M379', '35171', '#386340'],
  ['N379', '35342', '#627F63'],
  ['S379', '34077', '#809F83'],
  ['V379', '35673', '#AAC1A7'],
  ['X379', '35825', '#D8E3D5'],
  ['Y379', '35974', '#E6ECE0'],
  ['F380', '34152', '#D7EBC5'],
  ['G380', '34313', '#B9DDA3'],
  ['H380', '34485', '#97CD88'],
  ['J380', '34657', '#7BB775'],
  ['K380', '34829', '#6BA560'],
  ['L380', '35009', '#58934F'],
  ['M380', '35172', '#488547'],
  ['N380', '35343', '#477D48'],
  ['S380', '35508', '#609958'],
  ['V380', '35674', '#78AD6B'],
  ['X380', '35826', '#9ECD8D'],
  ['Y380', '35975', '#CCE1BC'],
  ['F381', '34153', '#EAF1E0'],
  ['G381', '34314', '#D5E8C3'],
  ['H381', '34486', '#CCE7B2'],
  ['J381', '34658', '#A6DA86'],
  ['K381', '34830', '#88BC6C'],
  ['L381', '35010', '#52923F'],
  ['M381', '35173', '#3F7644'],
  ['N381', '35344', '#5A7955'],
  ['S381', '35509', '#A2B584'],
  ['V381', '35675', '#C3D3AA'],
  ['X381 Aaloe', '24683', '#D4DDB8'],
  ['Y381', '35976', '#E4E9D1'],
  ['F382', '34154', '#F0F2DC'],
  ['G382', '34315', '#EBF1D3'],
  ['H382', '34487', '#DDECBF'],
  ['J382', '34659', '#C8E094'],
  ['K382', '34831', '#93BA58'],
  ['L382', '35011', '#72A53E'],
  ['M382', '35174', '#6D9F43'],
  ['N382', '35345', '#89AC5B'],
  ['S382', '35510', '#AAC582'],
  ['V382', '35676', '#C6D9AA'],
  ['X382', '35827', '#DBE5C2'],
  ['Y382', '35977', '#E8EED6'],
  ['F383 Tafti', '24919', '#F1F5E3'],
  ['G383', '34316', '#E9EFD7'],
  ['H383', '34488', '#D6E0BC'],
  ['J383 Helinä', '24735', '#BACD98'],
  ['K383', '34832', '#92A855'],
  ['L383', '35012', '#749438'],
  ['M383', '35175', '#587647'],
  ['N383', '35346', '#526C51'],
  ['S383', '35511', '#778C65'],
  ['V383', '35677', '#9DB089'],
  ['X383', '35828', '#BECBAA'],
  ['Y383 Kurkku', '24778', '#E5E9D2'],
  ['F384', '34155', '#EBF0E5'],
  ['G384', '34317', '#DFE6D7'],
  ['H384', '34489', '#D3DDC6'],
  ['J384', '34660', '#BDCCAD'],
  ['K384 Papukaija', '24855', '#95A875'],
  ['L384', '35013', '#798E5A'],
  ['M384 Basilika', '24702', '#6A7A4C'],
  ['N384', '35347', '#637655'],
  ['S384', '35512', '#97A480'],
  ['V384 Kanaali', '24759', '#AFBB9E'],
  ['X384', '35829', '#CFD6C1'],
  ['Y384 Kuohu', '24777', '#E9EDE4'],
  ['F385', '34156', '#F1F0DE'],
  ['G385 Muusa', '30893', '#EDEDD5'],
  ['H385', '34490', '#E4E8C8'],
  ['J385', '34661', '#D8DEA7'],
  ['K385 Keto', '24769', '#B6BD83'],
  ['L385 Lime', '24798', '#878952'],
  ['M385', '35176', '#6B7142'],
  ['N385', '35348', '#747958'],
  ['S385 Ansari', '24695', '#8D9473'],
  ['V385', '35678', '#BBC2A6'],
  ['X385 Monsuuni', '24822', '#C9CCB6'],
  ['Y385', '35978', '#E3E3D6'],
  ['F386', '34157', '#F1F0E6'],
  ['G386', '34318', '#E7E6D2'],
  ['H386', '34491', '#E2DFBA'],
  ['J386', '34662', '#CDCA8E'],
  ['K386', '34833', '#B6B26B'],
  ['L386', '35014', '#989147'],
  ['M386 Amazon', '28729', '#747142'],
  ['N386', '35349', '#888761'],
  ['S386 Lehvästö', '24791', '#ACAC84'],
  ['V386 Niili', '30894', '#C2C3A4'],
  ['X386 Uskudar', '24939', '#DFE0D2'],
  ['Y386', '35979', '#EBEBE4'],
  ['F387', '34158', '#ECF1CF'],
  ['G387', '34319', '#E8E9BD'],
  ['H387', '34492', '#E3E3AA'],
  ['J387', '34663', '#D7D893'],
  ['K387', '34834', '#C0C671'],
  ['L387', '35015', '#A6A84C'],
  ['M387', '35177', '#969545'],
  ['N387', '35350', '#888D4A'],
  ['S387', '27816', '#A3A763'],
  ['V387', '35679', '#D0CE93'],
  ['X387', '35830', '#E3DBA8'],
  ['Y387', '35980', '#EFEAC9'],
  ['F388', '34159', '#F7F4DF'],
  ['G388', '34320', '#F3EFC3'],
  ['H388', '34493', '#EDE9A4'],
  ['J388', '34664', '#E2DC82'],
  ['K388', '34835', '#CFC94D'],
  ['L388', '35016', '#C1BF39'],
  ['M388', '35178', '#A7AC45'],
  ['N388 Wasabi', '24945', '#C4C27A'],
  ['S388', '35513', '#D1CF88'],
  ['V388', '27829', '#DCDA9C'],
  ['X388 Chablis', '24713', '#EBE9C1'],
  ['Y388 Seljankukka', '24898', '#F7F4DC'],
  ['F389', '34160', '#F2ECD5'],
  ['G389', '34321', '#F3ECC0'],
  ['H389', '34494', '#ECE5AE'],
  ['J389', '34665', '#E8DF9B'],
  ['K389', '34836', '#DBCC74'],
  ['L389', '35017', '#C7B53E'],
  ['M389', '35179', '#bda621'],
  ['N389', '35351', '#CFBF6D'],
  ['S389', '35514', '#DBCC82'],
  ['V389 Valkea kuulas', '24944', '#E3D99E'],
  ['X389', '35831', '#ECE5BD'],
  ['Y389', '35981', '#F3ECD2'],
  ['F390 Piparjuuri', '24862', '#F3F2E4'],
  ['G390', '34322', '#F9F0C9'],
  ['H390', '34495', '#FCEFBA'],
  ['J390', '34666', '#FBE599'],
  ['K390', '34837', '#F7D775'],
  ['L390', '35018', '#E6BE33'],
  ['M390', '35180', '#d1ab18'],
  ['N390', '35352', '#DEC369'],
  ['S390', '28743', '#E8CF7F'],
  ['V390', '35680', '#F1DEA0'],
  ['X390', '35832', '#F9EFCF'],
  ['Y390', '35982', '#F8F1DB'],
  ['F391', '37019', '#f3edce'],
  ['G391', '34323', '#F0E5B1'],
  ['H391', '34496', '#ECDA97'],
  ['J391', '27833', '#E4D082'],
  ['K391', '34838', '#DFC874'],
  ['L391', '35019', '#CEB563'],
  ['M391', '35181', '#C6B157'],
  ['N391', '35353', '#C2AB4F'],
  ['S391', '35515', '#CEBC68'],
  ['V391', '35681', '#E6D588'],
  ['X391', '35833', '#F0E4AD'],
  ['Y391', '35983', '#F4EBC2'],
  ['F392 Kaakaovoi', '24957', '#F6E7C6'],
  ['G392 Samppanja', '24893', '#F1DBA4'],
  ['H392 Kirnuvoi', '24772', '#F0D291'],
  ['J392 Tuohus', '24935', '#E2BE7C'],
  ['K392', '34839', '#DAB165'],
  ['L392 Dukaatti', '28723', '#C0944A'],
  ['M392', '35182', '#B68A42'],
  ['N392', '35354', '#AC843D'],
  ['S392', '35516', '#CA9E52'],
  ['V392', '35682', '#DDC081'],
  ['X392', '28779', '#E9D297'],
  ['Y392 Papyrus', '30861', '#EEDFBB'],
  ['F393', '34161', '#F5E9C8'],
  ['G393', '34324', '#F6E1B7'],
  ['H393', '34497', '#F9D89F'],
  ['J393', '34667', '#F2CF8F'],
  ['K393', '34840', '#E4B86B'],
  ['L393', '35020', '#D6A761'],
  ['M393', '35183', '#BB8A4C'],
  ['N393', '35355', '#B18245'],
  ['S393', '35517', '#C89D61'],
  ['V393', '35683', '#DAB176'],
  ['X393', '35834', '#E7C598'],
  ['Y393', '35984', '#F2E3CA'],
  ['F394', '37020', '#f0ead9'],
  ['G394', '34325', '#F2E5CD'],
  ['H394', '34498', '#EED7B5'],
  ['J394', '34668', '#E9C68B'],
  ['K394', '34841', '#D7B174'],
  ['L394', '35021', '#B98B45'],
  ['M394', '35184', '#9C753E'],
  ['N394', '35356', '#8A6C4A'],
  ['S394', '35518', '#A3814E'],
  ['V394', '35684', '#C5A672'],
  ['X394 Lakeus', '28773', '#D1BA98'],
  ['Y394', '35985', '#F1E9DB'],
  ['F395', '37021', '#f8f1e2'],
  ['G395 Nuudeli', '24841', '#EEDABE'],
  ['H395 Viljapelto', '24950', '#EEC898'],
  ['J395', '34669', '#E6B478'],
  ['K395', '34842', '#D2A571'],
  ['L395 Curry', '24717', '#BB8B59'],
  ['M395', '35185', '#A0733E'],
  ['N395', '35357', '#896743'],
  ['S395', '35519', '#B18C62'],
  ['V395', '35685', '#C8A682'],
  ['X395 Playa', '24959', '#D9BC9B'],
  ['Y395', '35986', '#E1CFB9'],
  ['F396', '34162', '#F4ECDC'],
  ['G396 Onyksi', '24849', '#F5E6CD'],
  ['H396', '34499', '#F8D8B2'],
  ['J396', '34670', '#F4CC9F'],
  ['K396 Safari', '24890', '#D5A46C'],
  ['L396 Mehiläisvaha', '24816', '#C58F5B'],
  ['M396', '35186', '#A97545'],
  ['N396', '35358', '#8A6641'],
  ['S396 Siirappi', '30864', '#A67D54'],
  ['V396 Toffee', '24927', '#C6A078'],
  ['X396', '27970', '#CFB38F'],
  ['Y396 Travertiini', '24932', '#DEC6A6'],
  ['F397', '34163', '#F3ECDC'],
  ['G397', '34326', '#F4E8C6'],
  ['H397', '34500', '#FBE2AF'],
  ['J397', '34671', '#FBDA9A'],
  ['K397', '34843', '#F8CC7D'],
  ['L397', '35022', '#F7B64F'],
  ['M397', '35187', '#E2A343'],
  ['N397', '35359', '#CF994C'],
  ['S397', '35520', '#DAA44F'],
  ['V397', '28780', '#EABE76'],
  ['X397 Pai', '24852', '#F3D08B'],
  ['Y397', '35987', '#F5D79C'],
  ['F398 Taikina', '24920', '#F5E8D6'],
  ['G398 Manna', '24811', '#F0DEC6'],
  ['H398 Kaura', '24765', '#F0D3AE'],
  ['J398 Kenno', '24767', '#E8B874'],
  ['K398 Keisari', '24766', '#E1A863'],
  ['L398', '35023', '#D29345'],
  ['M398', '35188', '#C6893D'],
  ['N398', '35360', '#BC8548'],
  ['S398', '35521', '#CD9550'],
  ['V398', '35686', '#D9A563'],
  ['X398 Rikki', '24879', '#EAC18B'],
  ['Y398', '35988', '#EED7B4'],
  ['F399', '34164', '#F8F0E5'],
  ['G399', '34327', '#FAE9D7'],
  ['H399', '34501', '#F6DDC1'],
  ['J399 Balsa', '24698', '#F4CAA0'],
  ['K399', '34844', '#EFBC8C'],
  ['L399', '35024', '#CE9662'],
  ['M399', '35189', '#B17744'],
  ['N399', '35361', '#A07245'],
  ['S399', '35522', '#BE8957'],
  ['V399', '35687', '#D6A573'],
  ['X399', '35835', '#EDCDAD'],
  ['Y399 Cashew', '24712', '#F1D9BF'],
  ['F400 Onni', '24848', '#FCECD9'],
  ['G400', '34328', '#F4D5B3'],
  ['H400', '34502', '#EBC198'],
  ['J400', '34672', '#E0AC77'],
  ['K400', '34845', '#D39762'],
  ['L400', '35025', '#BC804E'],
  ['M400', '35190', '#956542'],
  ['N400', '35362', '#956D4D'],
  ['S400', '35523', '#BD8F68'],
  ['V400', '35688', '#D4AD87'],
  ['X400', '35836', '#DEBA96'],
  ['Y400', '35990', '#ECD3B7'],
  ['F401', '34165', '#FBE0C6'],
  ['G401 Persimoni', '24857', '#FBD7B7'],
  ['H401 Speltti', '24912', '#E8BA8F'],
  ['J401', '34673', '#D8A26D'],
  ['K401', '34846', '#CC8F55'],
  ['L401', '35026', '#B57741'],
  ['M401', '35191', '#9F653B'],
  ['N401', '35363', '#936948'],
  ['S401', '35524', '#A47955'],
  ['V401 Tiikerinsilmä', '24926', '#C49A74'],
  ['X401 Sitriini', '24908', '#DAB99A'],
  ['Y401 Kvartsi', '24781', '#EBD9C5'],
  ['F402', '34166', '#FBE3CB'],
  ['G402', '34329', '#FBDABC'],
  ['H402', '34503', '#FAD1AD'],
  ['J402 Aamurusko', '24685', '#F2B988'],
  ['K402', '28768', '#E39B5F'],
  ['L402', '35027', '#D4864A'],
  ['M402', '35192', '#C2733B'],
  ['N402', '35364', '#B5713D'],
  ['S402 Kurpitsa', '24779', '#CF884F'],
  ['V402 Amaretto', '24692', '#DCA87B'],
  ['X402', '35837', '#F1C095'],
  ['Y402', '35991', '#F4D5B7'],
  ['F403', '37022', '#fbe3cb'],
  ['G403', '34330', '#F5D5B9'],
  ['H403', '34504', '#F1C8A6'],
  ['J403', '34674', '#EFBC92'],
  ['K403', '34847', '#DB9E70'],
  ['L403 Punssi', '24869', '#CE8C5E'],
  ['M403 Chutney', '24715', '#B06E47'],
  ['N403', '35365', '#BC7951'],
  ['S403', '35525', '#D18F62'],
  ['V403 Savanni', '24894', '#DCA070'],
  ['X403 Pusta', '24871', '#F1C093'],
  ['Y403', '35992', '#F3DDC2'],
  ['F404', '37023', '#f6e4d1'],
  ['G404', '34331', '#F5D3B6'],
  ['H404', '34505', '#F2C5A4'],
  ['J404', '34675', '#E6AA81'],
  ['K404', '34848', '#D69367'],
  ['L404', '35028', '#CF8959'],
  ['M404', '35193', '#A8643F'],
  ['N404', '35366', '#B77655'],
  ['S404', '35526', '#C48563'],
  ['V404', '35689', '#E9B391'],
  ['X404', '35838', '#F5CEB3'],
  ['Y404', '35993', '#F7E1CF'],
  ['F405', '34167', '#FAE9D6'],
  ['G405 Niini', '24838', '#FCE2C8'],
  ['H405 Nukke', '24839', '#F4D1B9'],
  ['J405', '34676', '#EDC0A6'],
  ['K405', '34849', '#DA9B75'],
  ['L405', '35029', '#C37955'],
  ['M405', '35194', '#A25C40'],
  ['N405 Ruukku', '24885', '#9E5A44'],
  ['S405 Inka', '24748', '#BC7557'],
  ['V405', '35690', '#D59474'],
  ['X405', '35839', '#E9B79D'],
  ['Y405', '35994', '#F4E2D6'],
  ['F406', '34168', '#FCE3D4'],
  ['G406', '34332', '#F8D3BC'],
  ['H406', '34506', '#F6C4AA'],
  ['J406', '34677', '#EDAC8C'],
  ['K406', '34850', '#E19A72'],
  ['L406', '35030', '#CF8158'],
  ['M406', '35195', '#AF653E'],
  ['N406', '35367', '#A46240'],
  ['S406', '35527', '#B06C45'],
  ['V406 Sadonkorjuu', '30862', '#C88760'],
  ['X406', '35840', '#EDBC96'],
  ['Y406 Riviera', '24880', '#F5DCC8'],
  ['F407', '37024', '#f5e0d2'],
  ['G407', '34333', '#F3D3BF'],
  ['H407', '34507', '#ECC2AB'],
  ['J407 Siesta', '24902', '#E4B194'],
  ['K407', '34851', '#D38E6A'],
  ['L407', '35031', '#B36F50'],
  ['M407', '35196', '#855544'],
  ['N407', '35368', '#935F47'],
  ['S407', '28769', '#A76B4E'],
  ['V407', '28756', '#C28D6E'],
  ['X407', '35841', '#DAB59F'],
  ['Y407', '35995', '#E9CFBE'],
  ['F408', '37025', '#f0e1d9'],
  ['G408', '34334', '#E9D0C0'],
  ['H408', '34508', '#E6C4B1'],
  ['J408', '34678', '#D7A58D'],
  ['K408 Linssi', '24799', '#C98468'],
  ['L408 Karneoli', '24681', '#B16951'],
  ['M408', '35197', '#985241'],
  ['N408', '35369', '#89594A'],
  ['S408', '35528', '#C59483'],
  ['V408', '35691', '#D7B3A5'],
  ['X408', '35842', '#E2C5BB'],
  ['Y408', '35996', '#E9D2CB'],
  ['F409', '34169', '#F4DECE'],
  ['G409', '34335', '#F8D9C5'],
  ['H409', '34509', '#F3C1A6'],
  ['J409', '34679', '#CF8065'],
  ['K409', '34852', '#BB674C'],
  ['L409', '35032', '#9A4E3F'],
  ["M409 Xi'an", '30863', '#8B5348'],
  ['N409', '35370', '#984F40'],
  ['S409', '35529', '#AC593F'],
  ['V409', '35692', '#E69876'],
  ['X409', '35843', '#F0BB9E'],
  ['Y409', '35997', '#F4E0D3'],
  ['F410', '34170', '#F7E6DF'],
  ['G410', '34336', '#F6DDD1'],
  ['H410', '34510', '#F1CDBF'],
  ['J410', '34680', '#DA9D8A'],
  ['K410', '34853', '#C8816C'],
  ['L410', '35033', '#B46750'],
  ['M410', '35198', '#A55844'],
  ['N410', '35371', '#905644'],
  ['S410', '35530', '#A36754'],
  ['V410', '35693', '#CC9785'],
  ['X410', '35844', '#E3BCAE'],
  ['Y410', '35998', '#F2D9CE'],
  ['F411', '34171', '#F8DDD4'],
  ['G411', '34337', '#F7D2C5'],
  ['H411', '34511', '#EFB7A6'],
  ['J411', '34681', '#E7A391'],
  ['K411', '34854', '#D8846D'],
  ['L411', '35034', '#C56A54'],
  ['M411 Henna', '24738', '#924B40'],
  ['N411 Madras', '24807', '#A45C4D'],
  ['S411', '35531', '#C07968'],
  ['V411', '35694', '#DDAB9E'],
  ['X411', '35845', '#E9C0B3'],
  ['Y411 Pirtelö', '24863', '#EDCEC5'],
  ['F412', '34172', '#EFD4CD'],
  ['G412', '34338', '#EECDC5'],
  ['H412', '34512', '#E8BCB4'],
  ['J412', '34682', '#E5A8A0'],
  ['K412', '28735', '#CF7D72'],
  ['L412', '35035', '#AE5C50'],
  ['M412', '35199', '#A05144'],
  ['N412', '35372', '#884740'],
  ['S412', '35532', '#B46E64'],
  ['V412', '35695', '#CC978E'],
  ['X412', '35846', '#DEB3AC'],
  ['Y412', '35999', '#EDD1CC'],
  ['F413', '34173', '#F4E3DD'],
  ['G413', '34339', '#F5E1DB'],
  ['H413', '34513', '#E8BDB5'],
  ['J413', '34683', '#D1958A'],
  ['K413', '34855', '#BB6F5F'],
  ['L413', '35036', '#AD5E4F'],
  ['M413', '35200', '#8D483F'],
  ['N413', '35373', '#954D40'],
  ['S413', '35533', '#A96154'],
  ['V413', '35696', '#C88A80'],
  ['X413', '35847', '#E2C3B8'],
  ['Y413', '36000', '#EBD2C7'],
  ['F414', '34174', '#F1E0D8'],
  ['G414', '34340', '#F2D6CD'],
  ['H414', '34514', '#EECBC0'],
  ['J414', '34684', '#E0A499'],
  ['K414', '34856', '#C97C73'],
  ['L414', '35037', '#BC655C'],
  ['M414', '35201', '#92433D'],
  ['N414', '35374', '#834740'],
  ['S414', '35534', '#A1605B'],
  ['V414', '35697', '#B47975'],
  ['X414', '35848', '#D2918B'],
  ['Y414', '36001', '#E0C4C1'],
  ['F415', '34175', '#EFDEDA'],
  ['G415', '34341', '#E9D4CF'],
  ['H415', '34515', '#E8C6C2'],
  ['J415', '34685', '#E0B5AF'],
  ['K415', '34857', '#C98D83'],
  ['L415', '35038', '#AA655C'],
  ['M415', '27815', '#7C4A41'],
  ['N415', '35375', '#6C4A42'],
  ['S415', '35535', '#7B4D46'],
  ['V415', '35698', '#955F53'],
  ['X415', '35849', '#D2ABA3'],
  ['Y415 Posliinikukka', '24866', '#DDBCB6'],
  ['F416', '34176', '#F4E3DD'],
  ['G416', '34342', '#EBCFC8'],
  ['H416', '34516', '#E4BEB9'],
  ['J416', '34686', '#C4928E'],
  ['K416', '34858', '#AD7571'],
  ['L416', '35039', '#965B54'],
  ['M416', '28785', '#794A42'],
  ['N416', '35376', '#724A41'],
  ['S416', '35536', '#936053'],
  ['V416', '35699', '#BF958A'],
  ['X416', '35850', '#CEA99F'],
  ['Y416', '36002', '#DCBDB3'],
  ['F417', '34177', '#F4E5E2'],
  ['G417', '34343', '#E6C5C5'],
  ['H417 Näsiä', '24843', '#D5ADAB'],
  ['J417', '34687', '#B57573'],
  ['K417', '34859', '#A1605C'],
  ['L417', '35040', '#884845'],
  ['M417', '35202', '#744341'],
  ['N417 Morelli', '28776', '#674342'],
  ['S417 Foxtrot', '30878', '#876862'],
  ['V417', '35700', '#B89894'],
  ['X417 Paviljonki', '30867', '#D9B8B3'],
  ['Y417', '36003', '#F1DCDB'],
  ['F418', '34178', '#F1E0DE'],
  ['G418', '34344', '#EFCBC6'],
  ['H418', '34517', '#E8B1AE'],
  ['J418', '34688', '#C37D7C'],
  ['K418', '34860', '#B76C6B'],
  ['L418 Nektari', '24836', '#A95D59'],
  ['M418', '35203', '#87433D'],
  ['N418', '35377', '#7D4641'],
  ['S418', '35537', '#95544E'],
  ['V418 Rustiikki', '24884', '#BB7C76'],
  ['X418', '35851', '#D9A198'],
  ['Y418', '36004', '#E9CACA'],
  ['F419', '34179', '#F2E6E5'],
  ['G419', '34345', '#F1DDDB'],
  ['H419', '34518', '#E4C0C0'],
  ['J419', '34689', '#CB9797'],
  ['K419', '34861', '#B57676'],
  ['L419', '35041', '#A25D59'],
  ['M419 Cayenne', '25097', '#8A443E'],
  ['N419', '28742', '#794542'],
  ['S419', '35538', '#9B6060'],
  ['V419 Piruetti', '30868', '#BD8A8B'],
  ['X419', '35852', '#DEBEC1'],
  ['Y419', '36005', '#EBCECD'],
  ['F420', '34180', '#EEE7E6'],
  ['G420', '34346', '#EBDFDD'],
  ['H420', '34519', '#E0C5C1'],
  ['J420', '34690', '#D5AEAC'],
  ['K420', '34862', '#C27F80'],
  ['L420', '35042', '#975657'],
  ['M420', '35204', '#774341'],
  ['N420', '35378', '#7A4A42'],
  ['S420', '35539', '#8D6964'],
  ['V420', '35701', '#AF8F88'],
  ['X420 Neito', '24835', '#D2BBB0'],
  ['Y420', '36006', '#E6DDDA'],
  ['F421', '34181', '#F4E9ED'],
  ['G421', '34347', '#F3DDE0'],
  ['H421', '34520', '#ECC8CC'],
  ['J421', '34691', '#E8B6BB'],
  ['K421', '34863', '#CB8B92'],
  ['L421', '28753', '#B35F63'],
  ['M421', '35205', '#90433D'],
  ['N421 Berylli', '24705', '#9E4C4B'],
  ['S421', '35540', '#A75556'],
  ['V421', '35702', '#C57B7E'],
  ['X421', '35853', '#E4BABF'],
  ['Y421 Orkidea', '24851', '#EED1D3'],
  ['F422', '34182', '#F4E4DE'],
  ['G422', '34348', '#F1DCD5'],
  ['H422', '34521', '#F4C8C2'],
  ['J422', '34692', '#DFA8A4'],
  ['K422', '34864', '#B97A7B'],
  ['L422', '35043', '#95575B'],
  ['M422 Granaatti', '24675', '#684242'],
  ['N422 Rodoniitti', '24881', '#844D4D'],
  ['S422', '35541', '#9C6766'],
  ['V422', '35703', '#BB9594'],
  ['X422', '27820', '#DAC2C0'],
  ['Y422', '36007', '#E3CEC9'],
  ['F423', '34183', '#F6E5E5'],
  ['G423', '34349', '#F2D2D3'],
  ['H423', '34522', '#ECC5CB'],
  ['J423', '28734', '#CB8B98'],
  ['K423', '34865', '#B16978'],
  ['L423', '35044', '#9C525C'],
  ['M423 Granaattiomena', '24729', '#8A4246'],
  ['N423', '35379', '#99585C'],
  ['S423', '35542', '#A7686E'],
  ['V423 Oleanteri', '24844', '#D0A3AC'],
  ['X423', '35854', '#E7C9CD'],
  ['Y423', '36008', '#F0E3E1'],
  ['F424', '34184', '#E9D1D9'],
  ['G424', '34350', '#E4C2CC'],
  ['H424', '34523', '#D7AEBB'],
  ['J424 Runo', '24883', '#BD8D9D'],
  ['K424', '34866', '#AE7586'],
  ['L424', '35045', '#9C6576'],
  ['M424', '35206', '#82505D'],
  ['N424', '28784', '#7F5965'],
  ['S424', '35543', '#A17B8A'],
  ['V424', '35704', '#AA8798'],
  ['X424', '35855', '#BD9FB0'],
  ['Y424', '36009', '#D3BDC9'],
  ['F425', '34185', '#F4E9ED'],
  ['G425', '34351', '#E2D1DC'],
  ['H425', '34524', '#D1BAC9'],
  ['J425', '34693', '#B495A9'],
  ['K425', '34867', '#946A7F'],
  ['L425', '35046', '#80596B'],
  ['M425 Punajuuri', '24868', '#60444B'],
  ['N425', '35380', '#694E54'],
  ['S425', '35544', '#7D606B'],
  ['V425 Kanerva', '24760', '#917482'],
  ['X425 Mustikkamaito', '28836', '#B49FAE'],
  ['Y425', '36010', '#CEBCC7'],
  ['F426', '34186', '#ECE7E6'],
  ['G426', '34352', '#E3DADB'],
  ['H426 Tenho', '24960', '#CEC4CC'],
  ['J426 Syreeni', '28724', '#BCB1BB'],
  ['K426', '34868', '#A3939C'],
  ['L426 Myytti', '24832', '#816E77'],
  ['M426', '35207', '#6F5F69'],
  ['N426', '35381', '#776C75'],
  ['S426', '35545', '#92868C'],
  ['V426', '35705', '#BDB5BC'],
  ['X426', '35856', '#D3CACC'],
  ['Y426', '36011', '#E2DCDC'],
  ['F427', '34187', '#E0DDE2'],
  ['G427', '34353', '#D5D2D9'],
  ['H427', '34525', '#C7C1CB'],
  ['J427', '34694', '#B6B0BB'],
  ['K427', '34869', '#A095A4'],
  ['L427', '35047', '#807485'],
  ['M427', '28781', '#756B7D'],
  ['N427', '35382', '#756C76'],
  ['S427 Lumo', '24805', '#A8A1AC'],
  ['V427', '35706', '#C1BEC7'],
  ['X427', '35857', '#D7D6DD'],
  ['Y427', '36012', '#E3E3E8'],
  ['F428 Ketju', '24768', '#DBDCDD'],
  ['G428', '34354', '#CDCDD2'],
  ['H428', '34526', '#BBBDC7'],
  ['J428 Näköala', '24842', '#AFB3BF'],
  ['K428', '34870', '#888C99'],
  ['L428', '35048', '#6F7380'],
  ['M428 Ametisti', '24693', '#5D6371'],
  ['N428', '35383', '#5F646D'],
  ['S428', '35546', '#8B8F97'],
  ['V428', '35707', '#C0C1C7'],
  ['X428', '28758', '#D4D0D1'],
  ['Y428', '36013', '#E4E1E1'],
  ['F429', '34188', '#E1E7E7'],
  ['G429', '34355', '#CFD7D7'],
  ['H429', '34527', '#B9C5CC'],
  ['J429', '34695', '#A5B4C1'],
  ['K429', '34871', '#8093A2'],
  ['L429 Indigo', '30883', '#536476'],
  ['M429', '27827', '#455260'],
  ['N429 Denim', '28833', '#45515F'],
  ['S429', '35547', '#61707D'],
  ['V429', '35708', '#9FA9B1'],
  ['X429', '35858', '#B0BBC5'],
  ['Y429', '36014', '#CED5DB'],
  ['F430', '34189', '#E4E8EE'],
  ['G430', '34356', '#D7E1ED'],
  ['H430', '34528', '#CFD9E7'],
  ['J430', '34696', '#C1CEE1'],
  ['K430', '34872', '#A2B6D0'],
  ['L430 Saana', '24887', '#74849B'],
  ['M430', '35208', '#54637A'],
  ['N430', '35384', '#5C6878'],
  ['S430 Suitsuke', '24914', '#9AA3B1'],
  ['V430', '35709', '#B5BECA'],
  ['X430', '35859', '#CED5DD'],
  ['Y430', '36015', '#D6DFEA'],
  ['F431', '34190', '#D7E6EE'],
  ['G431', '27838', '#C6DCEA'],
  ['H431', '34529', '#93B9D0'],
  ['J431', '34697', '#6F97B5'],
  ['K431', '34873', '#527B9A'],
  ['L431', '35049', '#476987'],
  ['M431', '35209', '#31536C'],
  ['N431', '35385', '#395067'],
  ['S431 Delft', '24720', '#5E778F'],
  ['V431 Nukkumatti', '24840', '#7E95AA'],
  ['X431', '35860', '#B5C0C9'],
  ['Y431', '36016', '#CBD2D7'],
  ['F432', '34191', '#DFE7EE'],
  ['G432', '34357', '#CEDCE8'],
  ['H432', '34530', '#B8C9DA'],
  ['J432', '34698', '#9FB6CE'],
  ['K432', '34874', '#7296B5'],
  ['L432', '35050', '#557697'],
  ['M432', '35210', '#3E5977'],
  ['N432', '35386', '#456078'],
  ['S432', '35548', '#738B9D'],
  ['V432', '35710', '#9CB4C1'],
  ['X432', '35861', '#ADC2CE'],
  ['Y432', '36017', '#DFE5EB'],
  ['F433', '34192', '#D8E1ED'],
  ['G433', '34358', '#CED7E4'],
  ['H433', '34531', '#B5C5D8'],
  ['J433', '34699', '#A1B5CD'],
  ['K433', '34875', '#849EBC'],
  ['L433 Atlantis', '28727', '#597796'],
  ['M433', '35211', '#506580'],
  ['N433', '35387', '#546177'],
  ['S433', '35549', '#7394AE'],
  ['V433', '35711', '#A6BDCD'],
  ['X433', '35862', '#D1DDE4'],
  ['Y433', '36018', '#DEE6EE'],
  ['F434', '34193', '#E4E8EE'],
  ['G434', '34359', '#D7E0ED'],
  ['H434', '34532', '#CBD7E5'],
  ['J434', '34700', '#BFCDDF'],
  ['K434', '34876', '#A3B7CD'],
  ['L434', '35051', '#7594B4'],
  ['M434', '35212', '#445B7F'],
  ['N434', '35388', '#3D5875'],
  ['S434', '35550', '#4F7A9D'],
  ['V434', '35712', '#7294B4'],
  ['X434', '35863', '#ADC0D3'],
  ['Y434', '36019', '#D7E1ED'],
  ['F435 Emali', '24723', '#E6EAE9'],
  ['G435', '34360', '#D6E1E8'],
  ['H435', '34533', '#BACFDD'],
  ['J435', '34701', '#9AB8CC'],
  ['K435', '34877', '#7399B6'],
  ['L435', '35052', '#527B9B'],
  ['M435', '35213', '#406685'],
  ['N435', '35389', '#4D6273'],
  ['S435', '35551', '#728B9C'],
  ['V435', '35713', '#9FB3C1'],
  ['X435', '35864', '#C8D4DC'],
  ['Y435 Jääkenttä', '30882', '#DCE3E8'],
  ['F436 Vesiputous', '24947', '#DAE5E6'],
  ['G436', '34361', '#C2D6DB'],
  ['H436', '34534', '#AFCAD5'],
  ['J436', '34702', '#A4C2CD'],
  ['K436', '34878', '#6F91A3'],
  ['L436', '35053', '#517589'],
  ['M436 Royal', '30887', '#3F5B6C'],
  ['N436', '35390', '#5C7A84'],
  ['S436 Regatta', '24875', '#7292A0'],
  ['V436', '35714', '#A9BDC5'],
  ['X436', '35865', '#BAC9CF'],
  ['Y436', '36020', '#CAD6DA'],
  ['F437', '34194', '#E3ECEA'],
  ['G437', '34362', '#CFE4EC'],
  ['H437', '34535', '#B3D5E5'],
  ['J437', '34703', '#92BACE'],
  ['K437', '34879', '#417C96'],
  ['L437', '35054', '#395C75'],
  ['M437', '35214', '#394F63'],
  ['N437', '35391', '#486E7D'],
  ['S437', '27824', '#6993A3'],
  ['V437', '35715', '#98BAC8'],
  ['X437', '35866', '#CCDADE'],
  ['Y437', '36021', '#D3DFE1'],
  ['F438', '34195', '#D6DFE1'],
  ['G438 Horisontti', '24742', '#CBD6DA'],
  ['H438', '34536', '#BDCED3'],
  ['J438', '34704', '#A3BAC2'],
  ['K438', '34880', '#7F9EA6'],
  ['L438', '35055', '#496C77'],
  ['M438', '35215', '#435055'],
  ['N438', '35392', '#516971'],
  ['S438', '35552', '#6E8791'],
  ['V438', '35716', '#97A8AD'],
  ['X438 Salaisuus', '24892', '#B6C3C6'],
  ['Y438', '36022', '#CBD6D8'],
  ['F439', '34196', '#E3E9E4'],
  ['G439', '34363', '#D7DEDA'],
  ['H439', '34537', '#C1CECC'],
  ['J439', '28754', '#AEBAB8'],
  ['K439', '34881', '#94A4A1'],
  ['L439', '35056', '#7E8E8C'],
  ['M439', '35216', '#566765'],
  ['N439 Syvänne', '24917', '#56605E'],
  ['S439', '35553', '#8A9697'],
  ['V439', '35717', '#BAC4C1'],
  ['X439', '35867', '#CFD8D4'],
  ['Y439', '36023', '#E4E7E2'],
  ['F440', '34197', '#E0E6E1'],
  ['G440', '34364', '#CCD9D8'],
  ['H440 Aalto', '24684', '#A8C3C1'],
  ['J440', '34705', '#96B2AF'],
  ['K440', '34882', '#82A19D'],
  ['L440', '35057', '#597C79'],
  ['M440', '35217', '#384A4A'],
  ['N440', '35393', '#577A7B'],
  ['S440 Silkkitie', '24905', '#718F8C'],
  ['V440', '35718', '#B7C5C3'],
  ['X440 Haiku', '24732', '#D5DAD8'],
  ['Y440', '36024', '#DFE2DF'],
  ['F441 Henkäys', '24737', '#E9F0EC'],
  ['G441', '34365', '#DCE7E3'],
  ['H441 Pasaatituuli', '30889', '#C0D6D4'],
  ['J441', '34706', '#9BB7B3'],
  ['K441', '34883', '#86A4A0'],
  ['L441', '35058', '#688988'],
  ['M441', '35218', '#4B6967'],
  ['N441', '35394', '#4F6365'],
  ['S441', '35554', '#5C7374'],
  ['V441', '35719', '#779090'],
  ['X441', '35868', '#A7BEBE'],
  ['Y441 Akvarelli', '24689', '#C3D4D5'],
  ['F442', '34198', '#E4E9E0'],
  ['G442', '34366', '#DFE9DF'],
  ['H442', '34538', '#C6D8D0'],
  ['J442 Mentoli', '24817', '#92B1A6'],
  ['K442', '34884', '#739389'],
  ['L442', '35059', '#617F75'],
  ['M442 Vuono', '24954', '#465D55'],
  ['N442', '28792', '#44534E'],
  ['S442', '35555', '#819991'],
  ['V442', '35720', '#B6C9C1'],
  ['X442 Spa', '24911', '#D8E4DE'],
  ['Y442', '36025', '#E9EFEC'],
  ['F443', '34199', '#EFF3EE'],
  ['G443', '34367', '#E4EFE5'],
  ['H443', '34539', '#BED7C8'],
  ['J443', '34707', '#90B5A2'],
  ['K443', '34885', '#5C8A79'],
  ['L443', '35060', '#497769'],
  ['M443', '35219', '#4E6962'],
  ['N443', '35395', '#57776E'],
  ['S443', '35556', '#7CA298'],
  ['V443', '35721', '#94B7B0'],
  ['X443', '35869', '#B4CFC8'],
  ['Y443', '36026', '#CFE0DA'],
  ['F444 Hiljaisuus', '24904', '#E1E4DD'],
  ['G444 Gorgonzola', '24728', '#D0D4C9'],
  ['H444', '34540', '#A8B2A5'],
  ['J444', '34708', '#89978A'],
  ['K444', '34886', '#7A887C'],
  ['L444', '35061', '#657369'],
  ['M444', '35220', '#556159'],
  ['N444', '35396', '#5D645C'],
  ['S444', '35557', '#727B73'],
  ['V444', '35722', '#939C95'],
  ['X444', '35870', '#B7C1BE'],
  ['Y444', '36027', '#D8DFDD'],
  ['F445', '34200', '#E5E9E2'],
  ['G445', '34368', '#DADED3'],
  ['H445', '34541', '#C5CCBC'],
  ['J445', '34709', '#ACB5A1'],
  ['K445', '34887', '#8A9785'],
  ['L445', '35062', '#717E6C'],
  ['M445', '35221', '#56625A'],
  ['N445', '35397', '#667469'],
  ['S445', '35558', '#808F84'],
  ['V445', '35723', '#9FAC9D'],
  ['X445 Lido', '24797', '#B3C1B5'],
  ['Y445', '36028', '#DBE1D9'],
  ['F446', '34201', '#F1F0EC'],
  ['G446', '34369', '#E8E9DF'],
  ['H446', '28764', '#D2D4C5'],
  ['J446 Flanelli', '30895', '#BFC2B1'],
  ['K446', '34888', '#A7A997'],
  ['L446', '35063', '#7B806F'],
  ['M446', '35222', '#5D6052'],
  ['N446', '35398', '#717869'],
  ['S446', '35559', '#9A9C8A'],
  ['V446 Neva', '24837', '#C2C3B0'],
  ['X446', '35871', '#D8DAC9'],
  ['Y446', '36029', '#E9E7DB'],
  ['F447', '34202', '#EEEEE5'],
  ['G447', '34370', '#E1E4D9'],
  ['H447', '34542', '#D7DBCD'],
  ['J447', '34710', '#BCC1AC'],
  ['K447', '34889', '#9FA38B'],
  ['L447 Verholehti', '24785', '#82856C'],
  ['M447', '35223', '#747865'],
  ['N447', '35399', '#585C4F'],
  ['S447', '35560', '#757A6A'],
  ['V447 Serpentiini', '24899', '#AEB2A5'],
  ['X447 Merisavu', '30890', '#CDD1C8'],
  ['Y447', '36030', '#E4E7E2'],
  ['F448 Lootus', '24802', '#F1F1EA'],
  ['G448', '34371', '#E5E4D7'],
  ['H448', '34543', '#D7D9C7'],
  ['J448', '34711', '#B4BAA4'],
  ['K448 Poukama', '24867', '#9CA286'],
  ['L448', '35064', '#83896C'],
  ['M448 Jaava', '24749', '#6A7157'],
  ['N448 Bonsai', '24706', '#686D5A'],
  ['S448', '35561', '#7D8270'],
  ['V448', '35724', '#A9AF9F'],
  ['X448', '35872', '#C3C9BC'],
  ['Y448', '36031', '#E1E7E2'],
  ['F449', '34203', '#F7F4E7'],
  ['G449', '34372', '#F8F3D6'],
  ['H449', '34544', '#E7E9CC'],
  ['J449', '34712', '#BFC0A6'],
  ['K449', '34890', '#898C6E'],
  ['L449', '35065', '#6A705A'],
  ['M449 Luola', '24806', '#5B5B4A'],
  ['N449', '35400', '#555546'],
  ['S449', '35562', '#777B64'],
  ['V449 Jade', '24750', '#ADAC93'],
  ['X449', '35873', '#CAC4A6'],
  ['Y449', '36032', '#F4EED2'],
  ['F450', '34204', '#EAEBE0'],
  ['G450', '34373', '#E2E1CF'],
  ['H450 Lepo', '24795', '#D8D2B8'],
  ['J450', '34713', '#BAB390'],
  ['K450', '34891', '#A3976C'],
  ['L450', '35066', '#7E734B'],
  ['M450', '35224', '#716642'],
  ['N450', '35401', '#6A6749'],
  ['S450', '35563', '#88855B'],
  ['V450', '35725', '#C3C0A6'],
  ['X450 Vaellus', '24941', '#D9D7C9'],
  ['Y450', '28750', '#E9E8DE'],
  ['F451', '34205', '#EAE9DD'],
  ['G451', '34374', '#E8E6CE'],
  ['H451', '34545', '#D6D3B4'],
  ['J451', '34714', '#C7C5A5'],
  ['K451', '34892', '#B4AF7F'],
  ['L451', '35067', '#949060'],
  ['M451', '35225', '#77744B'],
  ['N451 Mätäs', '24833', '#76724B'],
  ['S451 Maja', '24809', '#999268'],
  ['V451 Oliivilehto', '24845', '#C5BD9C'],
  ['X451', '35874', '#E3DDC8'],
  ['Y451', '36033', '#ECE8D5'],
  ['F452 Väinönputki', '24955', '#E7E8D4'],
  ['G452 Huhtikuu', '30892', '#E5DFC3'],
  ['H452 Tanka', '24924', '#CFC8A4'],
  ['J452', '34715', '#B6AA79'],
  ['K452', '34893', '#A59664'],
  ['L452', '35068', '#9A8B5A'],
  ['M452', '35226', '#7A6D42'],
  ['N452', '35402', '#999268'],
  ['S452', '35564', '#A59766'],
  ['V452', '35726', '#D3C7A2'],
  ['X452', '35875', '#DBD4B6'],
  ['Y452', '28757', '#E8E3CF'],
  ['F453', '34206', '#EBE7D8'],
  ['G453', '34375', '#E4DAC1'],
  ['H453', '34546', '#C7B790'],
  ['J453', '34716', '#AD9B6A'],
  ['K453', '34894', '#9D8B5B'],
  ['L453', '35069', '#8A7A4C'],
  ['M453', '35227', '#6E5F40'],
  ['N453', '35403', '#85764A'],
  ['S453', '35565', '#9E8C5D'],
  ['V453', '35727', '#B7A678'],
  ['X453', '35876', '#CDC09D'],
  ['Y453 Päärynä', '24874', '#DED5BB'],
  ['F454', '34207', '#F3EBD8'],
  ['G454', '34376', '#EBDDBE'],
  ['H454', '34547', '#DDCBA2'],
  ['J454', '34717', '#C3AB72'],
  ['K454', '28782', '#AE965B'],
  ['L454', '35070', '#9A834A'],
  ['M454', '35228', '#907C4A'],
  ['N454', '35404', '#958057'],
  ['S454', '35566', '#B7A278'],
  ['V454 Laakeri', '24784', '#D5C7A3'],
  ['X454 Riisi', '24878', '#E0D4BD'],
  ['Y454', '36034', '#EEE6D7'],
  ['F455', '34208', '#EEECE0'],
  ['G455', '34377', '#ECE8DB'],
  ['H455 Ikuisuus', '24747', '#DFDBC6'],
  ['J455', '34718', '#C4BDA1'],
  ['K455', '34895', '#B3A67D'],
  ['L455', '35071', '#9F916D'],
  ['M455', '35229', '#7F7151'],
  ['N455', '35405', '#766A50'],
  ['S455', '35567', '#897B5E'],
  ['V455', '35728', '#A59675'],
  ['X455', '35877', '#CABEA5'],
  ['Y455 Tuffi', '24933', '#E4DCCA'],
  ['F456', '34209', '#F4EDE4'],
  ['G456', '34378', '#F4E9CF'],
  ['H456 Himmeli', '24741', '#EAD4AE'],
  ['J456', '34719', '#E1C7A1'],
  ['K456', '34896', '#C3A06C'],
  ['L456', '35072', '#A28455'],
  ['M456', '35230', '#7F6443'],
  ['N456', '35406', '#967853'],
  ['S456', '35568', '#B59973'],
  ['V456', '35729', '#CFAF81'],
  ['X456', '35878', '#E5D2B4'],
  ['Y456 Kookos', '24775', '#F0EBDF'],
  ['F457 Brie', '24708', '#EDE8DE'],
  ['G457', '34379', '#E3DBCA'],
  ['H457', '34548', '#CEC2A9'],
  ['J457 Zen', '24956', '#BCAC90'],
  ['K457 Kukkula', '24776', '#A5987B'],
  ['L457 Suo', '24915', '#8F8165'],
  ['M457', '35231', '#746850'],
  ['N457 Leiri', '24792', '#6B604A'],
  ['S457', '35569', '#84775C'],
  ['V457', '35730', '#9D8E72'],
  ['X457 Apatiitti', '24669', '#C5B89C'],
  ['Y457', '36035', '#D7CCB6'],
  ['F458 Akropolis', '24688', '#F1E9DD'],
  ['G458', '34380', '#DED2C1'],
  ['H458', '34549', '#D1C4AF'],
  ['J458 Fluoriitti', '24674', '#C7B59C'],
  ['K458', '34897', '#AC997B'],
  ['L458', '35073', '#9B886C'],
  ['M458', '35232', '#7B694D'],
  ['N458', '35407', '#77705D'],
  ['S458 Markasiitti', '24814', '#AAA28C'],
  ['V458', '35731', '#C4BCA9'],
  ['X458', '28747', '#D7D1C1'],
  ['Y458 Merino', '24819', '#E6E1D4'],
  ['F459', '34210', '#EEE5D6'],
  ['G459 Halva', '24733', '#E6D8C3'],
  ['H459 Chai', '24714', '#DECDB5'],
  ['J459 Sisal', '24907', '#C6B08F'],
  ['K459', '34898', '#AE9471'],
  ['L459', '35074', '#806A4E'],
  ['M459', '35233', '#76644C'],
  ['N459', '35408', '#877359'],
  ['S459', '35570', '#9E8767'],
  ['V459 Köysi', '24783', '#C7B79F'],
  ['X459 Uni', '24938', '#D2C6B2'],
  ['Y459', '36036', '#E8E2D5'],
  ['F460', '34211', '#F0EBE3'],
  ['G460', '34381', '#E9D7BB'],
  ['H460', '34550', '#D2BA94'],
  ['J460', '34720', '#BBA179'],
  ['K460', '34899', '#927A5E'],
  ['L460', '35075', '#7D674D'],
  ['M460 Aarniometsä', '30854', '#5F4F41'],
  ['N460', '35409', '#7D674E'],
  ['S460 Karavaani', '24763', '#A18B6E'],
  ['V460 Albiitti', '24667', '#BBA78A'],
  ['X460 Kipsi', '24682', '#D6CAB7'],
  ['Y460', '36037', '#E8E1D4'],
  ['F461', '28755', '#F5EBDD'],
  ['G461', '34382', '#EBD8C5'],
  ['H461', '34551', '#E7D0BB'],
  ['J461', '34721', '#D5B291'],
  ['K461', '34900', '#BE9672'],
  ['L461', '35076', '#956D4D'],
  ['M461', '35234', '#775E49'],
  ['N461', '35410', '#89684A'],
  ['S461', '28738', '#BB9A78'],
  ['V461', '35732', '#D2B398'],
  ['X461', '35879', '#E1CAB1'],
  ['Y461', '36038', '#F2E4D5'],
  ['F462 Halo', '28752', '#F4EBDE'],
  ['G462', '34383', '#EEDECE'],
  ['H462 Viikonloppu', '30855', '#E0C8B1'],
  ['J462 Kultamalmi', '24677', '#CAA98D'],
  ['K462', '34901', '#7A5B49'],
  ['L462 Sacher', '24888', '#5A4A41'],
  ['M462', '35235', '#544942'],
  ['N462 Salo', '30851', '#564E48'],
  ['S462', '35571', '#7D6758'],
  ['V462', '28788', '#D3B491'],
  ['X462 Lastu', '24790', '#E4CCB0'],
  ['Y462 Tofu', '24928', '#E8DDCE'],
  ['F463', '34212', '#F2E6DC'],
  ['G463', '34384', '#ECDDCE'],
  ['H463', '34552', '#E9D4C5'],
  ['J463', '28775', '#DBBCA5'],
  ['K463', '34902', '#C49D7D'],
  ['L463', '35077', '#966D4E'],
  ['M463', '28791', '#79583F'],
  ['N463', '35411', '#735742'],
  ['S463', '35572', '#92735D'],
  ['V463 Autiomaa', '24696', '#BB9E82'],
  ['X463 Hiekkakivi', '30856', '#CEB9A7'],
  ['Y463', '36039', '#E0D0C1'],
  ['F464', '34213', '#F1E7DD'],
  ['G464', '34385', '#EBDFD3'],
  ['H464', '34553', '#E0CAB9'],
  ['J464', '34722', '#D5BAA7'],
  ['K464', '34903', '#B4907A'],
  ['L464', '27823', '#8C6555'],
  ['M464', '35236', '#6D5140'],
  ['N464 Aleksandriitti', '24690', '#624E46'],
  ['S464', '35573', '#816355'],
  ['V464', '35733', '#AF9282'],
  ['X464', '35880', '#D0B6A5'],
  ['Y464', '36040', '#E4D3C7'],
  ['F465', '34214', '#EEE0D4'],
  ['G465', '34386', '#E5D0BE'],
  ['H465', '34554', '#DCC3AD'],
  ['J465', '34723', '#CCAB98'],
  ['K465', '34904', '#A67C66'],
  ['L465', '35078', '#936853'],
  ['M465', '35237', '#785440'],
  ['N465', '35412', '#72594B'],
  ['S465', '35574', '#7A5E4F'],
  ['V465 Aventuriini', '24670', '#BCA392'],
  ['X465 Breksia', '24671', '#D5C4B9'],
  ['Y465', '36041', '#E8DDD4'],
  ['F466 Pergamentti', '28790', '#EBDFCF'],
  ['G466', '34387', '#E4CDB5'],
  ['H466 Angora', '24694', '#DAC2AB'],
  ['J466', '34724', '#C6A78D'],
  ['K466', '34905', '#AC8469'],
  ['L466', '35079', '#89644B'],
  ['M466', '35238', '#765440'],
  ['N466', '35413', '#866B56'],
  ['S466', '35575', '#9C826B'],
  ['V466 Laama', '24786', '#C0A891'],
  ['X466', '35881', '#DFCFC0'],
  ['Y466', '36042', '#E7DACF'],
  ['F467', '34215', '#F2E7DD'],
  ['G467 Herkkusieni', '24739', '#E9DACC'],
  ['H467', '34555', '#D1B9A6'],
  ['J467', '34725', '#B69781'],
  ['K467', '34906', '#967663'],
  ['L467', '35080', '#856351'],
  ['M467', '35239', '#785948'],
  ['N467', '35414', '#7C675B'],
  ['S467', '35576', '#887366'],
  ['V467 Bastioni', '30859', '#9A877A'],
  ['X467', '35882', '#BDADA2'],
  ['Y467 Shaali', '24900', '#D7CCC3'],
  ['F468 Marenki', '30880', '#EEE9E6'],
  ['G468', '34388', '#E6D9D0'],
  ['H468', '34556', '#DDCEC3'],
  ['J468', '34726', '#BEA593'],
  ['K468', '34907', '#9E816E'],
  ['L468', '35081', '#876653'],
  ['M468', '35240', '#6F5243'],
  ['N468', '35415', '#685043'],
  ['S468 Tähtianis', '24937', '#765B4C'],
  ['V468', '35734', '#8D7160'],
  ['X468', '35883', '#B9A190'],
  ['Y468', '36043', '#DFCFC4'],
  ['F469', '34216', '#F2E4DB'],
  ['G469', '34389', '#EBD6CA'],
  ['H469', '34557', '#E4CABB'],
  ['J469', '34727', '#CFA993'],
  ['K469', '34908', '#BD9074'],
  ['L469', '35082', '#AC795C'],
  ['M469', '35241', '#885D48'],
  ['N469', '35416', '#865D48'],
  ['S469', '35577', '#A3775E'],
  ['V469', '35735', '#D0AF9A'],
  ['X469', '35884', '#DBBDAA'],
  ['Y469', '36044', '#E7D5C9'],
  ['F470 Lumikki', '24803', '#F2E7DC'],
  ['G470', '34390', '#F0E0D1'],
  ['H470', '34558', '#E9D2BD'],
  ['J470', '34728', '#D9B59B'],
  ['K470', '34909', '#B98A71'],
  ['L470', '35083', '#A66D53'],
  ['M470', '35242', '#7D5341'],
  ['N470', '35417', '#714F42'],
  ['S470', '35578', '#9C6E55'],
  ['V470', '35736', '#D3B8AA'],
  ['X470', '35885', '#E2CEC1'],
  ['Y470', '36045', '#ECDFD5'],
  ['F471', '34217', '#F1E8E1'],
  ['G471', '34391', '#EBDBD1'],
  ['H471', '34559', '#E2C6B6'],
  ['J471', '34729', '#CDA08B'],
  ['K471', '34910', '#B38571'],
  ['L471', '35084', '#A06C58'],
  ['M471', '35243', '#885849'],
  ['N471', '35418', '#79584A'],
  ['S471 Etruski', '24725', '#A68270'],
  ['V471', '35737', '#B99687'],
  ['X471', '35886', '#D6BAA9'],
  ['Y471', '36046', '#E9DBCE'],
  ['F472', '34218', '#EEE0D8'],
  ['G472', '34392', '#E7D1C8'],
  ['H472', '34560', '#DFC4BA'],
  ['J472', '34730', '#CDAB9E'],
  ['K472', '34911', '#A97969'],
  ['L472', '35085', '#936151'],
  ['M472', '35244', '#7F5446'],
  ['N472', '35419', '#7D554A'],
  ['S472', '35579', '#A17769'],
  ['V472 Ruusukvartsi', '24886', '#B99384'],
  ['X472', '35887', '#D5BAAE'],
  ['Y472', '36047', '#E4D0C6'],
  ['F473', '34219', '#EAD7CE'],
  ['G473', '34393', '#DFC4B8'],
  ['H473', '34561', '#CBA799'],
  ['J473', '34731', '#BC8F81'],
  ['K473', '34912', '#A67466'],
  ['L473', '35086', '#986556'],
  ['M473', '35245', '#895446'],
  ['N473', '35420', '#946F64'],
  ['S473', '35580', '#B09288'],
  ['V473', '35738', '#C4ACA4'],
  ['X473', '35888', '#DDCEC8'],
  ['Y473', '36048', '#E6DDDA'],
  ['F474', '34220', '#ECDFD8'],
  ['G474', '34394', '#E2D5CF'],
  ['H474', '34562', '#CCB5AF'],
  ['J474', '34732', '#B69080'],
  ['K474', '34913', '#8E675A'],
  ['L474', '35087', '#6E4E43'],
  ['M474', '35246', '#664D43'],
  ['N474', '35421', '#77594D'],
  ['S474', '35581', '#A7837A'],
  ['V474', '35739', '#BBA098'],
  ['X474', '35889', '#D4C0BA'],
  ['Y474', '36049', '#E4D5CF'],
  ['F475', '34221', '#EBDFDD'],
  ['G475', '34395', '#EAD9D3'],
  ['H475', '34563', '#E4CDC7'],
  ['J475', '34733', '#C2A19A'],
  ['K475', '34914', '#9F6859'],
  ['L475 Jaspis', '24680', '#8A5749'],
  ['M475', '35247', '#6A4A42'],
  ['N475', '35422', '#6C4B42'],
  ['S475', '35582', '#8C675C'],
  ['V475', '35740', '#C1A59D'],
  ['X475', '35890', '#D7C0B9'],
  ['Y475', '36050', '#E1CEC8'],
  ['F476', '34222', '#F0E3DD'],
  ['G476', '34396', '#ECDAD7'],
  ['H476', '34564', '#E1C7C4'],
  ['J476', '34734', '#C19691'],
  ['K476', '34915', '#9E716B'],
  ['L476', '35088', '#865851'],
  ['M476 Rooibos', '24882', '#784B42'],
  ['N476', '35423', '#795B57'],
  ['S476', '35583', '#8B7371'],
  ['V476', '35741', '#A18784'],
  ['X476', '35891', '#C3B0B0'],
  ['Y476', '36051', '#DDD0D1'],
  ['F477', '34223', '#F4EBE9'],
  ['G477 Budoaari', '24709', '#E7D3CC'],
  ['H477', '34565', '#D5B6AF'],
  ['J477', '34735', '#AC7E77'],
  ['K477', '34916', '#986D66'],
  ['L477', '35089', '#734D44'],
  ['M477', '35248', '#654943'],
  ['N477', '35424', '#6B4C42'],
  ['S477 Porfyyri', '24865', '#8B6B5E'],
  ['V477 Muuri', '24831', '#9F8579'],
  ['X477 Keisarinna', '30873', '#D2BFB8'],
  ['Y477', '36052', '#E9DFDB'],
  ['F478', '34224', '#EEE5E5'],
  ['G478', '34397', '#E5D8D8'],
  ['H478', '34566', '#CAB7B6'],
  ['J478', '34736', '#B89F9C'],
  ['K478', '34917', '#997D7B'],
  ['L478 Tuulihaukka', '30860', '#795A55'],
  ['M478', '35249', '#684943'],
  ['N478', '35425', '#7C5D59'],
  ['S478 Salonki', '30877', '#987C79'],
  ['V478', '35742', '#B59D9B'],
  ['X478', '35892', '#CEBDBC'],
  ['Y478', '36053', '#DDCFCE'],
  ['F479 Ballerina', '24697', '#ECE1DF'],
  ['G479', '34398', '#E5D7D4'],
  ['H479', '34567', '#D6C2BE'],
  ['J479', '34737', '#C9B2AF'],
  ['K479', '34918', '#AB8E88'],
  ['L479', '35090', '#856761'],
  ['M479', '35250', '#72524E'],
  ['N479', '35426', '#6A504C'],
  ['S479', '35584', '#806560'],
  ['V479 Zirkoni', '25098', '#A7938F'],
  ['X479', '35893', '#BCADAB'],
  ['Y479', '36054', '#CEC7C8'],
  ['F480 Kamee', '24758', '#EFE6E0'],
  ['G480', '34399', '#E7DBD3'],
  ['H480 Sunnuntai', '30872', '#DDCEC4'],
  ['J480', '34738', '#D3C1B6'],
  ['K480 Tamariski', '30857', '#B7A090'],
  ['L480', '35091', '#917A6F'],
  ['M480', '35251', '#625249'],
  ['N480', '35427', '#6B594D'],
  ['S480', '35585', '#968176'],
  ['V480', '35743', '#CEBEB4'],
  ['X480', '35894', '#E5DAD2'],
  ['Y480', '36055', '#ECE5E0'],
  ['F481', '34225', '#EEE8E6'],
  ['G481 Mirage', '30871', '#D5CCC7'],
  ['H481', '34568', '#C9BDB9'],
  ['J481', '34739', '#B3A59E'],
  ['K481 Amuletti', '30875', '#A0928B'],
  ['L481 Siluetti', '28778', '#837168'],
  ['M481', '35252', '#614E45'],
  ['N481', '35428', '#625249'],
  ['S481 Stauroliitti', '24913', '#8C7D76'],
  ['V481 Shantung', '24901', '#BDB1AE'],
  ['X481', '35895', '#D1C9C3'],
  ['Y481 Pilvi', '24860', '#E5E1DC'],
  ['F482', '34226', '#EAE7E1'],
  ['G482', '34400', '#E5DFD9'],
  ['H482', '34569', '#D0C4BC'],
  ['J482', '34740', '#BEAB9E'],
  ['K482', '34919', '#9B8574'],
  ['L482', '35092', '#746054'],
  ['M482', '35253', '#614F45'],
  ['N482', '28733', '#5A4C44'],
  ['S482', '35586', '#837063'],
  ['V482', '35744', '#B9A89C'],
  ['X482', '35896', '#CDBEB1'],
  ['Y482', '36056', '#E1D8D2'],
  ['F483 Illusio', '30870', '#E5DDD8'],
  ['G483', '34401', '#D5CCC5'],
  ['H483', '34570', '#CCBFB5'],
  ['J483', '34741', '#B9A493'],
  ['K483', '34920', '#A18C7B'],
  ['L483', '35093', '#876E5F'],
  ['M483', '35254', '#5B4B42'],
  ['N483', '28745', '#594C44'],
  ['S483', '35587', '#9C8C7B'],
  ['V483', '27835', '#B8AB9E'],
  ['X483', '35897', '#CBBDB3'],
  ['Y483', '36057', '#E1D8CF'],
  ['F484 Kalkki', '24755', '#EBE8E0'],
  ['G484 Talkki', '24922', '#E6DED4'],
  ['H484 Mulperi', '24825', '#DDD4C9'],
  ['J484 Linna', '30853', '#CEBFB0'],
  ['K484 Granuliitti', '24676', '#B9A694'],
  ['L484 Taateli', '24918', '#7A6553'],
  ['M484', '35255', '#695849'],
  ['N484 Siitake', '24903', '#907C6B'],
  ['S484 Konglomeraatti', '24773', '#9B8975'],
  ['V484 Ajopuu', '24686', '#C3B6A7'],
  ['X484 Muskoviitti', '24828', '#D1C6B9'],
  ['Y484', '36058', '#DFD4C8'],
  ['F485 Damasti', '24719', '#EFEBE2'],
  ['G485 Kanvaasi', '28740', '#E9E4DB'],
  ['H485', '28744', '#D9CFC3'],
  ['J485', '34742', '#C2B5A9'],
  ['K485', '28749', '#A09285'],
  ['L485 Viborgiitti', '24948', '#887769'],
  ['M485', '35256', '#65584C'],
  ['N485 Alppimaja', '24691', '#706357'],
  ['S485', '27826', '#897B6D'],
  ['V485', '28048', '#b8b0a6'],
  ['X485 Kashmir', '30850', '#D9D2CB'],
  ['Y485', '36059', '#E4DBD1'],
  ['F486', '34227', '#ECEBE5'],
  ['G486 Myötätuuli', '30849', '#DBD6CF'],
  ['H486', '34571', '#CFC8C0'],
  ['J486', '34743', '#B9AEA3'],
  ['K486', '34921', '#A4998E'],
  ['L486', '35094', '#746457'],
  ['M486', '35257', '#5A4C43'],
  ['N486 Savukvartsi', '24896', '#5B5048'],
  ['S486 Vulkaniitti', '24953', '#9F9284'],
  ['V486 Savikivi', '24895', '#B8AFA5'],
  ['X486 Mediaani', '28765', '#D5D1CA'],
  ['Y486', '36060', '#E3E2DE'],
  ['F487 Höyhen', '24745', '#E5E3DC'],
  ['G487 Batisti', '30848', '#DBD7CF'],
  ['H487 Hohkakivi', '24679', '#CAC5BD'],
  ['J487 Dolomiitti', '24672', '#B8B3AB'],
  ['K487 Kalsiitti', '24757', '#A6A199'],
  ['L487 Kontiainen', '24774', '#847B72'],
  ['M487', '28774', '#534D46'],
  ['N487', '35429', '#696157'],
  ['S487', '35588', '#8C877D'],
  ['V487 Valamo', '24942', '#BEB9B0'],
  ['X487 Laasti', '24787', '#C9C5BC'],
  ['Y487 Piazza', '24859', '#D8D4CA'],
  ['F488', '34228', '#E4E1E2'],
  ['G488', '34402', '#D1D0D3'],
  ['H488', '34572', '#C3C3C6'],
  ['J488', '34744', '#B7B5B7'],
  ['K488', '34922', '#A5A3A6'],
  ['L488', '28766', '#969495'],
  ['M488', '35258', '#817D7F'],
  ['N488', '35430', '#6F6C6D'],
  ['S488', '35589', '#5E5A5C'],
  ['V488 Pimennys', '30876', '#4E4B4D'],
  ['X488', '27832', '#4A4748'],
  ['Y488', '36061', '#434141'],
  ['F489', '34229', '#D5D9DE'],
  ['G489', '34403', '#C8CDD5'],
  ['H489', '34573', '#C0C5CE'],
  ['J489', '34745', '#B8BFC9'],
  ['K489', '34923', '#ADB2BC'],
  ['L489', '35095', '#9599A3'],
  ['M489', '35259', '#8B8F99'],
  ['N489', '35431', '#7E828A'],
  ['S489', '28748', '#6A6E78'],
  ['V489', '28746', '#575A64'],
  ['X489 Antrasiitti', '24668', '#44464C'],
  ['Y489', '28772', '#404143'],
  ['F490', '34230', '#D9DFE3'],
  ['G490 Prinssi', '30874', '#CFD9DE'],
  ['H490', '34574', '#BFCCD5'],
  ['J490 Sade', '24889', '#A7B8C4'],
  ['K490', '34924', '#9CABB8'],
  ['L490', '35096', '#92A3B1'],
  ['M490', '35260', '#82929F'],
  ['N490', '35432', '#70818D'],
  ['S490', '35590', '#6A7A86'],
  ['V490', '35745', '#5B6A75'],
  ['X490', '35898', '#46525C'],
  ['Y490', '36062', '#3F4246'],
  ['F491', '34231', '#E8EBE9'],
  ['G491', '34404', '#E0E6E6'],
  ['H491', '34575', '#CFD8D9'],
  ['J491', '34746', '#B7C8CB'],
  ['K491 Mistraali', '24821', '#A2B6BD'],
  ['L491 Maininki', '24808', '#869EA4'],
  ['M491', '35261', '#6D858B'],
  ['N491', '35433', '#5E737A'],
  ['S491 Petrooli', '24858', '#56676D'],
  ['V491', '35746', '#485359'],
  ['X491 Herttua', '30842', '#434A4E'],
  ['Y491', '36063', '#3E4042'],
  ['F492', '34232', '#E7E8E7'],
  ['G492', '34405', '#DFE2E3'],
  ['H492', '34576', '#CCD2D3'],
  ['J492', '34747', '#BEC6CA'],
  ['K492', '34925', '#B3BDC1'],
  ['L492', '35097', '#9EACAF'],
  ['M492', '35262', '#86989B'],
  ['N492', '35434', '#7E9195'],
  ['S492', '35591', '#718185'],
  ['V492', '35747', '#5F6F73'],
  ['X492', '35899', '#4E595D'],
  ['Y492', '36064', '#3E4244'],
  ['F493', '34233', '#EAEDEB'],
  ['G493', '34406', '#E3E9E8'],
  ['H493', '34577', '#CED6D4'],
  ['J493 Topaasi', '24930', '#B6C1BF'],
  ['K493', '34926', '#A6B0AC'],
  ['L493', '35098', '#95A1A0'],
  ['M493', '35263', '#7C8A8B'],
  ['N493', '35435', '#6C7A7C'],
  ['S493 Epidootti', '24673', '#596668'],
  ['V493 Linnunrata', '30843', '#4D5658'],
  ['X493', '35900', '#474D4F'],
  ['Y493 Mustarastas', '30841', '#3E4041'],
  ['F494', '34234', '#EAEDEB'],
  ['G494', '34407', '#E3E6E1'],
  ['H494', '34578', '#D7DDD4'],
  ['J494', '34748', '#CBD3C8'],
  ['K494', '34927', '#BDC6B9'],
  ['L494', '35099', '#ABB6A8'],
  ['M494', '35264', '#939D93'],
  ['N494 Nefriitti', '24834', '#828C84'],
  ['S494', '35592', '#717B72'],
  ['V494', '28787', '#59605A'],
  ['X494', '35901', '#494C48'],
  ['Y494', '36065', '#424645'],
  ['F495', '34235', '#E5E6E6'],
  ['G495', '34408', '#DCDDD8'],
  ['H495 Harso', '24734', '#D1D3CD'],
  ['J495', '34749', '#C2C5BE'],
  ['K495', '34928', '#B3B5AC'],
  ['L495', '35100', '#ABACA3'],
  ['M495 Tunturipaju', '28783', '#9A9C90'],
  ['N495', '35436', '#8B8E82'],
  ['S495', '35593', '#7A7D74'],
  ['V495 Rautatie', '30888', '#6C6F65'],
  ['X495', '35902', '#60635A'],
  ['Y495', '36066', '#4F5049'],
  ['F496 Lokki', '24801', '#E4E8E6'],
  ['G496', '34409', '#E2E4E4'],
  ['H496', '34579', '#D4D8D7'],
  ['J496 Varjo', '28741', '#CACDCD'],
  ['K496', '34929', '#BABDBC'],
  ['L496', '35101', '#A7ABAB'],
  ['M496', '35265', '#9CA09E'],
  ['N496', '35437', '#848988'],
  ['S496', '35594', '#777C7B'],
  ['V496', '35748', '#686C6C'],
  ['X496', '35903', '#5D6161'],
  ['Y496', '36067', '#4E5151'],
  ['F497 Paperi', '28837', '#F1F1EC'],
  ['G497 Höyry', '24746', '#E8E7E1'],
  ['H497 Kaoliini', '24761', '#E4E1D9'],
  ['J497', '34750', '#D1D0C6'],
  ['K497 Atlas', '30846', '#C4C4BB'],
  ['L497', '35102', '#BBB9B2'],
  ['M497 Tuhka', '24934', '#ADAAA3'],
  ['N497 Savi', '24796', '#908E84'],
  ['S497', '35595', '#868379'],
  ['V497', '35749', '#6D6D67'],
  ['X497 Laava', '24788', '#595650'],
  ['Y497 Toro', '24931', '#4B4944'],
  ['F498', '34236', '#E9EDE9'],
  ['G498', '34410', '#DDE1E2'],
  ['H498', '34580', '#D1D7D9'],
  ['J498', '34751', '#C4CACE'],
  ['K498 Valkama', '24943', '#BAC1C6'],
  ['L498 Sarka', '30845', '#ACB1B5'],
  ['M498', '35266', '#929799'],
  ['N498', '35438', '#828789'],
  ['S498', '35596', '#73787B'],
  ['V498', '35750', '#676D70'],
  ['X498', '35904', '#54595C'],
  ['Y498 Keskiyö', '24961', '#3C3C3C'],
  ['F499', '34237', '#E9EAE8'],
  ['G499', '34411', '#DCDEDB'],
  ['H499', '28759', '#C9CCCA'],
  ['J499', '34752', '#BCBEBB'],
  ['K499 Graniitti', '24730', '#AAACAA'],
  ['L499', '35103', '#8A8B89'],
  ['M499 Mantteli', '24812', '#7D7F7E'],
  ['N499 Basaltti', '24701', '#686A6A'],
  ['S499', '35597', '#595B5B'],
  ['V499', '35751', '#4C4D4E'],
  ['X499 Lakritsi', '30847', '#484A4A'],
  ['Y499', '36068', '#414242'],
  ['F500 Enkeli', '24724', '#E7E8E7'],
  ['G500 Bungalow', '24710', '#DCDFE0'],
  ['H500', '34581', '#D1D7D9'],
  ['J500', '34753', '#C4CBCF'],
  ['K500', '34930', '#B5BEC3'],
  ['L500', '35104', '#A8AFB5'],
  ['M500', '35267', '#98A1A7'],
  ['N500 Akaatti', '24687', '#8E979E'],
  ['S500 Tyrsky', '24936', '#757B81'],
  ['V500', '35752', '#696F74'],
  ['X500', '35905', '#5E6266'],
  ['Y500 Takorauta', '24921', '#4E5255'],
  ['F501', '34238', '#EDE8E5'],
  ['G501', '34412', '#E7DFDE'],
  ['H501', '34582', '#DDD3D2'],
  ['J501', '34754', '#D1C6C5'],
  ['K501', '34931', '#B9AFB0'],
  ['L501', '35105', '#9D9192'],
  ['M501', '35268', '#8B8081'],
  ['N501 Andante', '30896', '#6E6566'],
  ['S501', '28763', '#605757'],
  ['V501 Hematiitti', '24678', '#584F50'],
  ['X501 Munakoiso', '24826', '#4C4342'],
  ['Y501', '36069', '#464241'],
  ['F502', '34239', '#E9E8EC'],
  ['G502', '34413', '#E5E5E9'],
  ['H502', '34583', '#E0DEE2'],
  ['J502', '34755', '#D7D4DC'],
  ['K502', '34932', '#CBC7CE'],
  ['L502', '35106', '#BCB8C1'],
  ['M502', '35269', '#AAA4AC'],
  ['N502', '35439', '#888087'],
  ['S502', '35598', '#736870'],
  ['V502 Viikuna', '24949', '#5E565C'],
  ['X502 Kätkö', '24782', '#4F494E'],
  ['Y502', '36070', '#454143'],
  ['F503 Jasmiini', '24751', '#F6F2E8'],
  ['G503 Kalla', '24756', '#F4F0E6'],
  ['H503', '27819', '#F5EDE9'],
  ['J503 Jääruusu', '24753', '#F3EEEB'],
  ['K503 Palatsi', '24853', '#EFECEC'],
  ['L503 Kinos', '24771', '#ECEFED'],
  ['M503 Opaali', '24850', '#EDF0EB'],
  ['N503', '35440', '#EFF0E9'],
  ['S503 Lumikko', '24804', '#F2F1EA'],
  ['V503 Talvi', '24923', '#EEEEE9'],
]

export default colorsTikkurilaSymphony
