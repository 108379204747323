const colorsMontanaHc = [
  ['Unicorn Yellow', 'RV 252', 'FFFFBF'],

  ['Beach Yellow', 'RV 222', 'FFF386'],

  ['Party Yellow', 'RV 20', 'fdef46'],

  ['Light Yellow', 'RV 1021', 'FFE100'],

  ['Ganges Yellow', 'RV 11', 'F4CA2A'],

  ['Tenere Sand', 'RV 17', 'DF9E00'],

  ['Druide Brown', 'RV 246', 'EECF99'],

  ['Tepuy Brown', 'RV 247', 'DEB276'],

  ['Baobab Brown', 'RV 248', 'CC9456'],

  ['Greyhound Brown', 'RV 249', 'B0763F'],

  ['Toasted Brown', 'RV 8002', '8A5531'],

  ['Chocolat Brown', 'RV 35', '4D2200'],

  ['Poison Green', 'RV 235', 'DEDD3A'],

  ['Mojito Green', 'RV 236', 'CED50A'],

  ['Pistachio Green', 'RV 16', 'B0C643'],

  ['Guacamole Green', 'RV 34', '88B14F'],

  ['Cologno N. Green', 'RV 237', '6A8515'],

  ['Lutecia Green', 'RV 5', '005900'],

  ['Element Green', 'RV 253', 'C5CC8F'],

  ['Apple Green', 'RV 15', 'ACB681'],

  ['Rambo Green', 'RV 250', '8F995B'],

  ['Khaki Green', 'RV 6013', '727E48'],

  ['Forest Green', 'RV 251', '46502C'],

  ['Olive Green', 'RV 6003', '60683D'],

  ['Max Green', 'RV 254', '9FE5C2'],

  ['París Green', 'RV 219', '8BC8AA'],

  ['UFO Green', 'RV 220', '5BB89E'],

  ['Surgical Green', 'RV 21', '009473'],

  ['Dark Green', 'RV 6016', '008352'],

  ['Persephone Green', 'RV 221', '005231'],

  ['Patagonia Blue', 'RV 255', '83CCDF'],

  ['Cousteau Blue', 'RV 231', '1BB2C4'],

  ['Glacier Blue', 'RV 232', '009CAB'],

  ['Planet Blue', 'RV 14', '008098'],

  ['Christiania Blue', 'RV 233', '006675'],

  ['Indigo Blue', 'RV 234', '004750'],

  ['Artic Blue', 'RV 29', '6BDCFF'],

  ['Light Blue', 'RV 8', '60C5E7'],

  ['Avatar Blue', 'RV 217', '00A2DD'],

  ['Electric Blue', 'RV 30', '007FC4'],

  ['Dark Blue', 'RV 5005', '00659D'],

  ['Neptune Blue', 'RV 218', '004578'],

  ['Waimea Blue', 'RV 227', '6F9FD0'],

  ['Zeppelin Blue', 'RV 228', '4875B3'],

  [' Andromeda Blue', 'RV 229', '465499'],

  ['Ultramarine Blue', 'RV 5002', '37306A'],

  [' Leviatan Blue', 'RV 230', '0D0F49'],

  ['Nostromo Blue', 'RV 75', '00003B'],

  ['Malva', 'RV 256', 'ACA6D1'],

  ['Violet', 'RV 214', '9993C3'],

  [' Prophet Violet', 'RV 215', '6D63A5'],

  [' Blue Violet', 'RV 3', '574A96'],

  ['Anonymous Violet', 'RV 216', '45347A'],

  ['Galaxy Blue', 'RV 264', '1B2753'],

  ['Princess Violet', 'RV 257', 'CA87B6'],

  ['Witch Violet', 'RV 224', 'C8739F'],

  ['Geisha Violet', 'RV 225', 'C15C92'],

  ['Purple', 'RV 4006', 'AB4779'],

  ['Tube Violet', 'RV 226', '7C3F60'],

  ['Cherry', 'RV 4007', '582741'],

  ['Manga Pink', 'RV 258', 'FFA2CA'],

  ['Love Pink', 'RV 211', 'EE87AD'],

  ['Erika', 'RV 4003', 'DE336B'],

  ['Magenta', 'RV 4010', 'DD1D5A'],

  ['Akari Red', 'RV 212', 'C50041'],

  ['Merlot Red', 'RV 213', '762A3D'],

  ['Flamingo', 'RV 259', 'FF958E'],

  ['Buddha Red', 'RV 223', 'EB6964'],

  ['Colorado Red', 'RV 33', 'E74F45'],

  ['Light Red', 'RV 3020', 'c8393b'],

  ['Vivid red', 'RV 3001', 'C90019'],

  ['Bourdeaux Red', 'RV 3004', '82131D'],

  ['Atacama Yellow', 'RV 206', 'FBCB8C'],

  ['Peach', 'RV 1017', 'F9BA57'],

  [' Medium Yellow', 'RV 1028', 'F6A800'],

  ['Mango', 'RV 207', 'E77A19'],

  ['Mustard', 'RV 8023', 'B9501A'],

  ['Pangea Brown', 'RV 261', '933B00'],

  ['Pumpkin', 'RV 208', 'F6AD6B'],

  ['Pastel Orange', 'RV 2003', 'EC753B'],

  ['Orange', 'RV 2004', 'E95E0F'],

  ['Calcutta Orange', 'RV 209', 'DC4126'],

  ['Prometheus Orange', 'RV 210', '990000'],

  ['Iroko Red', 'RV 260', '5E0000'],

  ['Divinity White', 'R 9010', 'FFFFFF'],

  ['Light Grey', 'RV 6', 'bebebe'],

  ['Matter Grey', 'RV 262', '989898'],

  ['Pearl Grey', 'RV 7040', '7b7b7b'],

  ['Dark Grey', 'RV 7031', '696a6a'],

  ['Sputnik Grey', 'RV 263', '575757'],

  ['Anthracite Grey', 'RV 7016', '3d3d3d'],

  ['Black', 'R 9011', '000000'],

  ['Silver', '  ', '666666'],

  ['Gold', ' ', 'c49d69'],

  ['Copper', ' ', 'cf7a2f'],

  ['Giant Yellow', 'RV 238', 'FFED00'],

  ['Luxor Yellow', 'RV 239', 'FAB700'],

  ['Kenya Orange', 'RV 240', 'E9600F'],

  ['Miami Pink', 'RV 244', 'E41370'],

  ['Madrid Red', 'RV 241', 'c52332'],

  ['Soviet Red', 'RV 242', '991100'],

  ['Valley Green', 'RV 6018', '6A9720'],

  ['Cyan', 'RV 245', '53B6E3'],

  ['Babylon Blue', 'RV 243', '121fd0'],

  ['Vampire Violet', 'RV 27', '00084D'],

  ['White', 'Satin', 'FFFFFF'],

  ['White ', 'Matte', 'FFFFFF'],
]

export default colorsMontanaHc
