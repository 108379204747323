// Constants
import { createPaletteArray } from '../../modules/palettes'
import {
  RGB,
  HSV,
  LAB,
  LABExtended,
  LinearRGB,
  hex2color,
  OkHSV,
  OkHWB,
} from '../../modules/colors'
import { DefaultPaletteArrayType, palettes, PaletteArrayType } from './palettes'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Color extends RGB, HSV, LAB, LABExtended, LinearRGB, OkHSV, OkHWB {
  hex: string
  name?: string
  productCode?: string
}
export interface SetColorPermutation {
  [index: number]: Hex[]
}

export type Hex = string

export interface SetColor {
  [index: string]: SetColorPermutation
}
export type ColorsState = SetColor
export const defaultState: ColorsState = {}

// Same handler for all of the reducers
interface ChangeColorSettings {
  colorHex: Hex
  permutationsAmount: string | number
  type?: PaletteArrayType
}
const changeColorSettings = (state, action: PayloadAction<ChangeColorSettings>): void => {
  const {
    type = action.payload.type || DefaultPaletteArrayType,
    colorHex,
    permutationsAmount,
  } = action.payload
  const color = hex2color(colorHex)
  if (!state[type]?.[colorHex]?.[permutationsAmount]) {
    if (!state[type]) {
      state[type] = {}
    }
    if (!state[type][colorHex]) {
      state[type][colorHex] = {}
    }
    state[type][colorHex][permutationsAmount] = createPaletteArray(
      color,
      Number(permutationsAmount),
      type,
    ).map((color) => color.hex)
  }
}

export const colors = createSlice({
  name: 'colors',
  initialState: defaultState,
  reducers: {
    changeColorSettings,
  },
  extraReducers: (builder) => {
    builder.addCase(palettes.actions.createPalette, changeColorSettings)
    builder.addCase(palettes.actions.createPaletteWithBG, changeColorSettings)
    builder.addCase(palettes.actions.modifyPalette, changeColorSettings)
  },
})
