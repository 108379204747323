import { Color, Hex } from '../reducers/modules/colors'
import { okhsv2wb, rgb2okHsv } from './colors/okSpace'

export interface RGB {
  r: number
  g: number
  b: number
}

export interface HSV {
  h: number
  s: number
  v: number
}

export interface HSL {
  h: number
  s: number
  l: number
}
export interface LAB {
  lLab: number
  aLab: number
  bLab: number
}

export interface LinearRGB {
  linearR: number
  linearG: number
  linearB: number
}
export interface LABExtended extends LAB {
  cLab: number
  hLab: number
}

export interface OkHWB {
  okWhiteness: number // WHiteness
  okBlackness: number // Blackness
}
export interface OkHSL {
  hueOkHsl: number
  saturationOkHsl: number
  lightnessOkHsl: number
}

export interface OkHSV {
  hueOkHsv: number
  saturationOkHsv: number
  valueOkHsv: number
}

export const hex2rgb = (_hex: string): RGB => {
  const hex = _hex || ''
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b),
  )
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 }
}

const componentToHex = (c: number): string => {
  const hex = c.toString(16)
  return hex.padStart(2, '0')
}

export const rgb2hex = ({ r, g, b }: RGB): Hex =>
  `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`

// export const hsv2hsl = ({ h, s, v }) => {
//   if (arguments.length === 1) {
//       s = h.s, v = h.v, h = h.h;
//   }
//   let _h = h,
//       _s = s * v,
//       _l = (2 - s) * v;
//   _s /= (_l <= 1) ? _l : 2 - _l;
//   _l /= 2;

//   return {
//       h: _h,
//       s: _s,
//       l: _l,
//   };
// };

// export const hsl2hsv = ({ h, s, l }) => {
//   if (arguments.length === 1) {
//     ;(s = h.s), (l = h.l), (h = h.h)
//   }
//   let _h = h,
//     _s,
//     _v

//   l *= 2
//   s *= l <= 1 ? l : 2 - l
//   _v = (l + s) / 2
//   _s = (2 * s) / (l + s)

//   return {
//     h: _h,
//     s: _s,
//     v: _v,
//   }
// }

export const hsv2rgb = ({ h, s, v }: HSV): RGB => {
  let r = 0
  let g = 0
  let b = 0
  const i = Math.floor(h * 6)
  const f = h * 6 - i
  const p = v * (1 - s)
  const q = v * (1 - f * s)
  const t = v * (1 - (1 - f) * s)

  switch (i % 6) {
    case 0:
      r = v
      g = t
      b = p
      break
    case 1:
      r = q
      g = v
      b = p

      break
    case 2:
      r = p
      g = v
      b = t

      break
    case 3:
      r = p
      g = q
      b = v

      break
    case 4:
      r = t
      g = p
      b = v

      break
    case 5:
      r = v
      g = p
      b = q

      break
  }
  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  }
}

export const rgb2hsv = ({ r, g, b }: RGB): HSV => {
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  const d = max - min
  let h
  const s = max === 0 ? 0 : d / max
  const v = max / 255

  switch (max) {
    case min:
      h = 0
      break
    case r:
      h = g - b + d * (g < b ? 6 : 0)
      h /= 6 * d
      break
    case g:
      h = b - r + d * 2
      h /= 6 * d
      break
    case b:
      h = r - g + d * 4
      h /= 6 * d
      break
  }

  return { h, s, v }
}

export const hex2hsv = (hex: string): HSV => rgb2hsv(hex2rgb(hex))

type ReadabilityOptions = {
  inverse?: boolean
  smoothed?: boolean
}
export const rgb2readability = (
  { r, g, b }: RGB,
  { inverse = false, smoothed = false }: ReadabilityOptions,
): Hex =>
  /* eslint-disable no-mixed-operators */
  inverse !== (r * 299 + g * 587 + b * 144) / 1000 < 127
    ? smoothed
      ? '#eee'
      : '#fff'
    : smoothed
    ? '#111'
    : '#000'
/* eslint-enable no-mixed-operators */

export const randomHex = (): Hex =>
  rgb2hex({
    r: Math.floor(Math.random() * 256),
    g: Math.floor(Math.random() * 256),
    b: Math.floor(Math.random() * 256),
  })

export const hex2readability = (hex: string, options: ReadabilityOptions): Hex => {
  return rgb2readability(hex2rgb(hex), options)
}

export const hex2readabilityLegacy2 = (hex: string): Hex =>
  ('000000' + (0xffffff ^ Number(`0x${hex}`)).toString(16)).slice(-6)

export const rgb2readabilityLegacy = ({ r, g, b }: RGB): Hex =>
  ('000000' + (0xffffff ^ Number(`0x${r}${g}${b}`)).toString(16)).slice(-6)

const _rgbComponentToLinear = (comp: number): number => {
  const x = comp /// 255 // The web/css rgb is in hex, TODO: clamp to [0, 255]
  const value = x >= 0.0031308 ? 1.055 * Math.pow(x, 1 / 2.4) - 0.055 : 12.92 * x
  return value
}
const _linearRgbComponent2rgb = (comp: number): number => {
  const x = comp
  const value = x >= 0.04045 ? Math.pow((x + 0.055) / (1 + 0.055), 2.4) : x / 12.92
  return value //* 255
}

// const linearRgb2Rgb = ({ linearR, linearB, linearG }: LinearRGB): RGB => {
//   return {
//     r: _rgbComponentToLinear(linearR),
//     g: _rgbComponentToLinear(linearG),
//     b: _rgbComponentToLinear(linearB),
//   }
// }

export const rgb2LinearRgb = ({ r, g, b }: RGB): LinearRGB => {
  return {
    linearR: _rgbComponentToLinear(r / 255),
    linearG: _rgbComponentToLinear(g / 255),
    linearB: _rgbComponentToLinear(b / 255),
  }
}

export const _linearRgb2lab = ({ linearR: r, linearG: g, linearB: b }: LinearRGB): LAB => {
  const l = 0.412165612 * r + 0.536275208 * g + 0.0514575653 * b
  const m = 0.211859107 * r + 0.6807189584 * g + 0.107406579 * b
  const s = 0.0883097947 * r + 0.2818474174 * g + 0.6302613616 * b

  const l_ = Math.sqrt(l)
  const m_ = Math.sqrt(m)
  const s_ = Math.sqrt(s)

  return {
    lLab: 0.2104542553 * l_ + 0.793617785 * m_ - 0.0040720468 * s_,
    aLab: 1.9779984951 * l_ - 2.428592205 * m_ + 0.4505937099 * s_,
    bLab: 0.0259040371 * l_ + 0.7827717662 * m_ - 0.808675766 * s_,
  }
}

// OKLab spec: https://bottosson.github.io/posts/oklab/
export const _rgb2lab = ({ r, g, b }: RGB): LAB => {
  const linearR = _rgbComponentToLinear(r / 255)
  const linearG = _rgbComponentToLinear(g / 255)
  const linearB = _rgbComponentToLinear(b / 255)
  return _linearRgb2lab({ linearR, linearB, linearG })
}

export const labChroma = ({ aLab, bLab }: LAB): number => {
  const labChroma = Math.sqrt(aLab * aLab + bLab * bLab)
  return labChroma
}

export const labHue = ({ aLab, bLab }: LAB): number => {
  const labHue = Math.atan2(bLab, aLab) // + Math.PI) / (Math.PI * 2)
  return labHue
}

// const rgb2lab = ({ r, g, b }: RGB): LABExtended => {
//   const lab = _rgb2lab({ r, g, b })
//   return {
//     ...lab,
//     cLab: labChroma(lab),
//     hLab: labHue(lab),
//   }
// }

export const linearRgb2lab = (rgb: LinearRGB): LABExtended => {
  const lab = _linearRgb2lab(rgb)
  return {
    ...lab,
    cLab: labChroma(lab),
    hLab: labHue(lab),
  }
}

// OKLab spec: https://bottosson.github.io/posts/oklab/
export const lab2rgb = ({ lLab, aLab, bLab }: LAB): RGB & LinearRGB => {
  const l_ = lLab + 0.3963377774 * aLab + 0.2158037573 * bLab
  const m_ = lLab - 0.1055613458 * aLab - 0.0638541728 * bLab
  const s_ = lLab - 0.0894841775 * aLab - 1.291485548 * bLab

  const l__ = l_ * l_ * l_
  const m__ = m_ * m_ * m_
  const s__ = s_ * s_ * s_

  const linearR = 0 + 4.0767245293 * l__ - 3.3072168827 * m__ + 0.2307590544 * s__
  const linearG = 0 - 1.2681437731 * l__ + 2.6093323231 * m__ - 0.341134429 * s__
  const linearB = 0 - 0.0041119885 * l__ - 0.7034763098 * m__ + 1.7068625689 * s__

  return {
    linearR,
    linearG,
    linearB,
    r: Math.max(0, Math.min(Math.floor(_linearRgbComponent2rgb(linearR) * 256), 255)),
    g: Math.max(0, Math.min(Math.floor(_linearRgbComponent2rgb(linearG) * 256), 255)),
    b: Math.max(0, Math.min(Math.floor(_linearRgbComponent2rgb(linearB) * 256), 255)),
  }
}

export const aLab = (cLab: number, hLab: number): number => {
  return cLab * Math.cos(hLab)
}

export const bLab = (cLab: number, hLab: number): number => {
  return cLab * Math.sin(hLab)
}

export const lab2hex = (lab: LAB): string => {
  const rgb = lab2rgb(lab)
  const hex = rgb2hex(rgb)
  return hex
}

export const lab2color = (lab: LAB): Color => {
  const rgb = lab2rgb(lab)
  const hex = rgb2hex(rgb)
  const hsv = rgb2hsv(rgb)
  const cLab = labChroma(lab)
  const hLab = labHue(lab)
  const okHsv = rgb2okHsv(rgb)
  const okHWB = okhsv2wb(okHsv)

  return {
    hex,
    cLab,
    hLab,
    ...hsv,
    ...rgb,
    ...lab,
    ...okHsv,
    ...okHWB,
  }
}

export const hex2color = (_hex: string): Color => {
  const hex = _hex?.[0] === '#' ? _hex : `#${_hex}`
  const hsv = hex2hsv(hex)
  const rgb = hex2rgb(hex)
  const linearRgb = rgb2LinearRgb(rgb)
  const lab = linearRgb2lab(linearRgb)
  const okHsv = rgb2okHsv(rgb)
  const okHWB = okhsv2wb(okHsv)

  return {
    hex,
    ...hsv,
    ...rgb,
    ...lab,
    ...linearRgb,
    ...okHsv,
    ...okHWB,
  }
}

export const hsv2color = (hsv: HSV): Color => {
  const rgb = hsv2rgb(hsv)
  const hex = rgb2hex(rgb)
  const linearRgb = rgb2LinearRgb(rgb)
  const lab = linearRgb2lab(linearRgb)

  const okHsv = rgb2okHsv(rgb)
  const okHWB = okhsv2wb(okHsv)
  return {
    ...hsv,
    ...rgb,
    ...lab,
    ...linearRgb,
    hex,
    ...okHsv,
    ...okHWB,
  }
}
