import CSS from 'csstype'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { ColorControls, readability } from '../components/colorControls/colorControls'
import { useTsSelector } from '../reducers'
import { Color } from '../reducers/modules/colors'
import { PositionOnGui } from '../reducers/modules/gui'
import { Palette, PaletteArrayType } from '../reducers/modules/palettes'
import { ColorsContainer } from './colorsContainer'
import classNames from './paletteDisplay.module.sass'

interface PaletteDisplayProps extends Palette {
  // palettes: Palette[]
  spawnPosition: PositionOnGui
  closestColor: Color
  isFirstOfTree: boolean
  // colors: Color[]
}

export const verboseTypeHeader: Record<PaletteArrayType, string> = {
  [PaletteArrayType.FullH]: 'Industrial Hue',
  [PaletteArrayType.FullHLab]: 'Organic Hue',
  // [PaletteArrayType.FullCLab]: 'Chroma',
  // [PaletteArrayType.FullLLab]: 'Luminosity',
  // [PaletteArrayType.FullSOk]: 'Saturation',
  // [PaletteArrayType.FullVOk]: 'Sweep to gray (value)',
  [PaletteArrayType.WhitenessOk]: 'Sweep to white',
  [PaletteArrayType.BlacknessOk]: 'Sweep to black',
  [PaletteArrayType.Wack]: 'Sweep to reverse',
}

const backgroundCss = (closestColor: Color): CSS.Properties => {
  return {
    background: `rgba(${closestColor.r},${closestColor.g},${closestColor.b},1)`,
  }
}

export const PaletteDisplay: React.FC<PaletteDisplayProps> = ({
  children,
  permutationsAmount,
  color,
  closestColor,
  id,
  subpalettes,
  permutations,
  isFirstOfTree = false,
  type,
  background,
}) => {
  const movingContentRef = useRef<HTMLDivElement>(null)
  const targetWrapperRef = useRef<HTMLDivElement>(null)

  const spawnPosition = useTsSelector((state) => state.gui.position)
  const zIndex = useTsSelector((state) => state.gui.zIndex)

  const [visible, setVisible] = useState(false)

  useLayoutEffect(() => {
    if (movingContentRef.current) {
      /* TODO: lisää lähtö- ja kohderuudut ja toteuta after-pseudoselektorilla */
      if (spawnPosition) {
        movingContentRef.current.style.top = '-' + spawnPosition.top
      }

      const { top, left } = movingContentRef.current.getBoundingClientRect()
      movingContentRef.current.style.top = 'calc(4em - ' + (top + window.pageXOffset) + 'px'
      if (spawnPosition) {
        movingContentRef.current.style.left = `calc(${parseInt(spawnPosition.left) - left}px)`
      }
      if (targetWrapperRef && targetWrapperRef.current) {
        targetWrapperRef.current.classList.add(classNames.shown)
      }
      setTimeout(() => setVisible(true), 200)
    }
  }, [spawnPosition])

  return (
    (
      <div
        ref={targetWrapperRef}
        className={[classNames.paletteDisplayContainer].join(' ')}
        style={{ zIndex: zIndex, overflow: 'visible' }}
      >
        <div
          ref={movingContentRef}
          style={{
            ...(background && backgroundCss(closestColor)),
          }}
          className={[classNames.paletteDisplayHidden, visible && classNames.paletteDisplayShow]
            .filter(Boolean)
            .join(' ')}
        >
          <header style={{ background: closestColor.hex }}>
            <ColorControls
              color={closestColor}
              id={id}
              permutationsAmount={permutationsAmount}
              type={type}
              background={Boolean(background)}
              isFirstOfTree={isFirstOfTree}
            />
          </header>
          {children}
          {background && (
            <div style={{ ...readability(color) }} className={classNames.colorName}>
              {closestColor.name || closestColor.hex}
            </div>
          )}
          <ColorsContainer
            color={color}
            parentId={id}
            permutations={permutations || []}
            subpalettes={subpalettes}
            permutationsAmount={permutationsAmount}
            type={type}
          />
        </div>
      </div>
    ) || null
  )
}

export default PaletteDisplay
