import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Color } from './colors'
import { DEFAULT_COLOR_SET_NAME } from './paletteSets'

export interface PositionOnGui {
  top: string
  left: string
}

export interface GuiState {
  showInfo: boolean
  colors: Color[]
  usedColorSet: string
  position?: PositionOnGui
  zIndex: number
  amountOfSwatches: number
}

export const initialState: GuiState = {
  showInfo: false,
  colors: [],
  usedColorSet: DEFAULT_COLOR_SET_NAME,
  position: undefined,
  zIndex: 1,
  amountOfSwatches: 1,
}

export const gui = createSlice({
  name: 'gui',
  initialState,
  reducers: {
    setShowInfo(state, action: PayloadAction<boolean>) {
      state.showInfo = action.payload
    },
    changePaletteSet(state, action: PayloadAction<string>) {
      state.usedColorSet = action.payload
    },
    markZoomInPosition(state, action: PayloadAction<PositionOnGui>) {
      state.position = action.payload
      state.zIndex += 1
    },
    changeAmountOfSwatches(state, action: PayloadAction<number>) {
      // Only one color with hex set
      state.amountOfSwatches = state.usedColorSet === 'hex' ? 1 : action.payload
    },
    // dehydate(state, action: PayloadAction<>) {
    //   state = {
    //     ...state,
    //     ...action.payload,
    //   }
    // },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(
  //     thunks.markZoomInPosition.fulfilled,
  //     (state, action: PayloadAction<PositionOnGui>) => {
  //       state.position = action.payload
  //       state.zIndex += 1
  //     },
  //   )
  // },
})
