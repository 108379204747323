import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import undoable from 'redux-undo'
import { RootState } from '../store'

import { colors } from './modules/colors'
import { gui } from './modules/gui'
import { palettes } from './modules/palettes'

const rootReducer = combineReducers({
  colors: colors.reducer,
  gui: gui.reducer,
  palettes: undoable(palettes.reducer),
})

export default rootReducer

export const useTsSelector: TypedUseSelectorHook<RootState> = useSelector
