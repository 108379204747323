import { Color } from '../reducers/modules/colors'
import { calculateRadialDistance } from './colorSets'

export type ColorComparator = (reference: Color, desired: Color) => number

export enum DistanceType {
  SweepHue = 'SweepHue',
  SweepWithoutHue = 'SweepWithoutHue',
  SweepChroma = 'SweepChroma',
  SweepLuminosity = 'SweepLuminosity',
}

export const DefaultDistanceType = DistanceType.SweepHue

// export enum SweepTypes {
//   SweepHue = 'SweepHue',
//   SweepWithoutHue = 'SweepWithoutHue',
//   SweepChroma = 'SweepChroma',
//   SweepLuminosity = 'SweepLuminosity',
// }

export type comparators = Record<keyof typeof DistanceType, ColorComparator>

export const comparators: comparators = {
  SweepHue: (reference: Color, desired: Color): number => {
    const hLabDistance = calculateRadialDistance(reference.hLab, desired.hLab)
    return (
      Math.pow(hLabDistance, 2) * 1 +
      Math.pow(Math.abs((reference.cLab - desired.cLab) % 1), 2) * 0.2 +
      Math.pow(Math.abs((reference.lLab - desired.lLab) % 1), 2) * 0.2 +
      Math.pow(Math.abs((reference.h - desired.h) % 1), 2) * 0.1
    )
  },
  SweepWithoutHue: (reference: Color, desired: Color): number => {
    return (
      Math.pow(reference.cLab - desired.cLab, 2) * 1 +
      Math.pow(reference.lLab - desired.lLab, 2) * 1 +
      Math.abs(reference.h - desired.h)
    )
  },
  SweepChroma: (reference: Color, desired: Color): number => {
    return Math.pow(reference.cLab - desired.cLab, 2) + Math.abs(reference.h - desired.h)
  },
  SweepLuminosity: (reference: Color, desired: Color): number => {
    return Math.pow(reference.lLab - desired.lLab, 2) + Math.abs(reference.h - desired.h)
  },
}
