import React from 'react'
import { useDispatch } from 'react-redux'
import { gui } from '../../reducers/modules/gui'
import { sets } from '../../reducers/modules/paletteSets'

const PaletteSetPicker: React.FC = () => {
  const paletteSetNames = Object.keys(sets)
  const dispatch = useDispatch()

  const changeHandler = (value): void => {
    dispatch(gui.actions.changePaletteSet(value))
  }
  return (
    <label>
      <p>Color set</p>
      <select onInput={(e: React.ChangeEvent<HTMLSelectElement>) => changeHandler(e.target.value)}>
        {paletteSetNames.map((setName) => (
          <option key={setName} value={String(setName)}>
            {setName}
          </option>
        ))}
      </select>
    </label>
  )
}

export default PaletteSetPicker
