import React from 'react'
import { Color } from '../../reducers/modules/colors'
import { PaletteArrayType, PaletteId } from '../../reducers/modules/palettes'
import { PaletteDisplayList } from '../paletteDisplayList'
import classNames from './colorsContainer.module.sass'
import { ColorContainer } from './colorsContainer.types'
import { FullSweepContainer } from './fullSweepContainer'
import { NonHueSweepContainer } from './NonHueSweepContainer'

/**
 * Faded background is for FullHue palette which does not neccessarily represent
 * the correct color
 */
// const fadedBackground = (
//   color: Color,
//   type: PaletteArrayType,
// ): CSS.Properties => {
//   const shouldUseBackground =
//     !type || // Default to showing a background
//     [
//       PaletteArrayType.FullH,
//       PaletteArrayType.FullS,
//       PaletteArrayType.FullV,
//       PaletteArrayType.FullHLab,
//     ].includes(type)
//   return shouldUseBackground
//     ? {
//         background: `linear-gradient(to top right, transparent, ${color.hex} 50%, transparent)`,
//         // background: `linear-gradient(to top right, transparent, rgba(${color.r}, ${color.g},
//       }
//     : {}
// }

// const shouldHideFirstColor = (type: PaletteArrayType): boolean =>
//   [PaletteArrayType.FullH, PaletteArrayType.FullHLab].includes(type)

interface ColorsContainer {
  color: Color
  parentId: string
  permutationsAmount: number
  permutations: Color[]
  subpalettes?: PaletteId[]
  type: PaletteArrayType
}

export const ColorsContainer: React.FC<ColorsContainer> = ({
  // color,
  parentId,
  permutationsAmount,
  permutations,
  subpalettes = [],
  type,
}) => {
  const swatchDisplayHandlers: Partial<Record<PaletteArrayType, React.FC<ColorContainer>>> = {
    // [PaletteArrayType.FullCLab]: CombinedColorsContainer,
    // [PaletteArrayType.FullLLab]: CombinedColorsContainer,
    // [PaletteArrayType.FullSOk]: CombinedColorsContainer,
    // [PaletteArrayType.FullVOk]: CombinedColorsContainer,
    [PaletteArrayType.Wack]: NonHueSweepContainer,
    [PaletteArrayType.FullH]: FullSweepContainer,
    [PaletteArrayType.FullHLab]: FullSweepContainer,
    [PaletteArrayType.BlacknessOk]: NonHueSweepContainer,
    [PaletteArrayType.WhitenessOk]: NonHueSweepContainer,
  }

  const SwatchDisplayHandler: React.FC<ColorContainer> =
    swatchDisplayHandlers[type] || FullSweepContainer

  return (
    <>
      <div className={classNames.colorsContainer}>
        <SwatchDisplayHandler
          colors={permutations}
          parentId={parentId}
          type={type}
          permutationsAmount={permutationsAmount}
        />
      </div>
      <div>{subpalettes && <PaletteDisplayList paletteIds={subpalettes} />}</div>
    </>
  )
}

export default ColorsContainer
