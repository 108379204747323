import React, { ChangeEvent } from 'react'

interface ColorPicker {
  changeHandler: (T: ChangeEvent<HTMLInputElement>) => void
  value: string
}

export const ColorPicker: React.FC<ColorPicker> = (props) => (
  <input
    type="color"
    value={props.value}
    // defaultValue={this.state.color}
    onChange={(e) => props.changeHandler(e)}
  />
)

export default ColorPicker
