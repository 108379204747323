import React, { useContext } from 'react'
import { useTsSelector } from '../../reducers'
import { DEFAULT_COLOR_SET_NAME } from '../../reducers/modules/paletteSets'
import { NearestColorsContext } from '../../utils/Context'
import { ColorSwatchContainer } from '../colorSwatchContainer'
import { ColorContainer } from './colorsContainer.types'

export const FullSweepContainer: React.FC<ColorContainer> = ({ colors, parentId }) => {
  const { findNeighboursHandler } = useContext(NearestColorsContext)
  const colorSetName = useTsSelector((state) => state.gui.usedColorSet) || DEFAULT_COLOR_SET_NAME
  const amountOfSwatches = useTsSelector((state) => state.gui.amountOfSwatches) || 3

  const isSingleColor = colorSetName === 'hex'

  return (
    <>
      {colors.map((color, i) => (
        <ColorSwatchContainer
          color={color}
          parentId={parentId}
          nearestColors={isSingleColor ? [color] : [...findNeighboursHandler(color)]}
          amountOfSwatches={amountOfSwatches}
          key={i}
        />
      ))}
    </>
  )
}
