import React, { useMemo } from 'react'
import { useTsSelector } from '../reducers'
import * as colorSetUtils from '../modules/colorSets'
import classNames from './paletteDisplay.module.sass'
import { PaletteDisplay } from './paletteDisplay'
import { Palette, PaletteArrayType, PaletteInStore } from '../reducers/modules/palettes'
import { sets } from '../reducers/modules/paletteSets'
import { hex2color } from '../modules/colors'
import {
  CreatePaletteButton,
  HighlightAreaForNewPaletteButton,
} from '../components/createPaletteButton'

interface PaletteDisplayListProps {
  paletteIds?: string[]
}

type PalettesSlice = Record<string, PaletteInStore>
type PalettesSliceFullColors = Record<string, Palette>

const mapper = (ap: PalettesSlice): PalettesSliceFullColors => {
  return Object.entries(ap).reduce(
    (memo, [id, palette]) => ({
      ...memo,
      [id]: {
        ...palette,
        color: hex2color(palette.colorHex),
      },
    }),
    {},
  )
}

const nPaletteArrayTypes = Object.entries(PaletteArrayType).length / 2

export const PaletteDisplayList: React.FC<PaletteDisplayListProps> = ({ paletteIds }) => {
  const _allPalettes = useTsSelector<PalettesSlice>((state) => state.palettes.present)
  const allPalettes = useMemo(() => mapper(_allPalettes), [_allPalettes])
  const usedColorSet = useTsSelector((state) => state.gui.usedColorSet)

  const palettes = paletteIds
    ? paletteIds.map<Palette>((subpaletteId: string): Palette => allPalettes[subpaletteId])
    : Object.values<Palette>(allPalettes).filter((palette) => !palette.parentId)
  const colorSet = sets[usedColorSet]
  return (
    <div className={[classNames.palettesSubwindow].filter(Boolean).join(' ')}>
      {palettes.map((palette) => {
        const closestColor = colorSetUtils.closestNeighbourFromSet(palette.color, colorSet)
        return (
          <PaletteDisplay
            closestColor={closestColor || palette.color}
            spawnPosition={{ top: '0px', left: '0px' }}
            isFirstOfTree={palette.parentId === undefined}
            {...palette}
            key={palette.id}
          >
            <CreatePaletteButton
              color={palette.color || closestColor}
              permutationsAmount={4}
              type={(palette.subpalettes.length + 1) % nPaletteArrayTypes}
              parentId={palette.id}
            />
            <HighlightAreaForNewPaletteButton />
          </PaletteDisplay>
        )
      })}
    </div>
  )
}

export default PaletteDisplayList
