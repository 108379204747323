import React from 'react'
import classNames from './colorControls.module.sass'
import { useDispatch } from 'react-redux'

import { PaletteArrayType, palettes } from '../reducers/modules/palettes'
import { rgb2readability } from '../modules/colors'

import { TsDispatch } from '../store'
import { Color } from '../reducers/modules/colors'
import { gui } from '../reducers/modules/gui'

interface CreatePaletteButton {
  color: Color
  permutationsAmount: number
  parentId: string
  type?: PaletteArrayType
}

export const CreatePaletteButton: React.FC<CreatePaletteButton> = ({
  color,
  permutationsAmount,
  parentId,
  type,
}) => {
  const dispatch: TsDispatch = useDispatch()
  const handleCreatePalette = (e): void => {
    // 'afterCreate' in ownProps && ownProps.afterCreate();
    const target = e.currentTarget
    let { top, left } = target.getBoundingClientRect()
    top += 'px'
    left += 'px'

    dispatch(gui.actions.markZoomInPosition({ top, left }))
    dispatch(
      palettes.actions.createPaletteWithBG({
        colorHex: color.hex,
        permutationsAmount: permutationsAmount || 3,
        parentId,
        type,
        top,
        left,
      }),
    )
  }

  return (
    <svg
      className={[classNames.icon, classNames.paletteFromSwatchButton].join(' ')}
      viewBox="0 0 200 200"
      onClick={handleCreatePalette}
    >
      <circle
        cx="100"
        cy="100"
        r="90"
        fill={rgb2readability(color, { smoothed: true })}
        strokeWidth="15"
        stroke={rgb2readability(color, {
          smoothed: true,
          inverse: true,
        })}
      />
      <polygon
        points="70, 55 70, 145 145, 100"
        fill={rgb2readability(color, {
          smoothed: true,
          inverse: true,
        })}
      />
    </svg>
  )
}

export const HighlightAreaForNewPaletteButton: React.FC = () => {
  return <div className={classNames.newPaletteAreaHighlight} />
}
