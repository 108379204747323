const colorsMontana94 = [
  ['Ipanema Yellow', 'RV 189', 'fff9c3'],
  ['Party Yellow', 'RV 20', 'fdef8c'],
  ['Canarias Yellow', 'RV 109', 'fff058'],

  ['Light Yellow', 'RV 1021', 'fedf00'],

  ['Yosemite Yellow', 'RV 110', 'c7b300'],

  ['Babel Green', 'RV 111', 'a29400'],

  ['Mission Green', 'RV 112', '766a10'],

  ['Dragon Green', 'RV 113', '5e5003'],

  ['Maya Green', 'RV 114', '3d3408'],

  ['Sundance', 'RV 192', 'ffebc2'],

  ['Dalai Orange', 'RV 102', 'fddca3'],

  ['Plural Orange', 'RV 103', 'fac67e'],

  ['Solar Orange', 'RV 50', 'f7b060'],

  ['Tangerine', 'RV 105', 'f5a54a'],

  ['Lava Orange', 'RV 106', 'ef8407'],

  ['Orange', 'RV 2004', 'ea640d'],

  ['Mars Orange', 'RV 107', 'e64715'],

  ['Phoenix Orange', 'RV 108', 'be3819'],

  ['Malta White', 'RV 190', 'f9f6e9'],

  ['Bone White', 'RV 1013', 'f3efd6'],

  ['Safari Brown', 'RV 135', 'eadfa8'],

  ['Inca Brown', 'RV 136', 'd9b365'],

  ['Pasteboard Brown', 'RV 137', 'bd9b61'],

  ['Marrakech', 'RV 138', '99743f'],

  ['Sequoia Brown', 'RV 139', '6f5229'],

  ['Mole Brown', 'RV 140', '503915'],

  ['Gondola Brown', 'RV 141', '412c13'],

  ['Jaima Brown', 'RV 191', 'f1e8de'],

  ['Dingo Brown', 'RV 94', 'e8d0b7'],

  ['Tana Brown', 'RV 95', 'd2a785'],

  ['Montserrat', 'RV 96', 'cc955e'],

  ['Chiapas Brown', 'RV 97', 'a0682b'],

  ['Bean Brown', 'RV 98', 'a65f2f'],

  ['Marron Glace', 'RV 99', '7a3d24'],

  ['Coffee Brown', 'RV 100', '402009'],

  ['Ebony Brown', 'RV 101', '2f1109'],

  ['Chewing gum', 'RV 193', 'f6c3d3'],

  ['Tutti Frutti', 'RV 115', 'ea949d'],

  ['Fever Red', 'RV 3017', 'dc534d'],

  ['Blood Red', 'RV 116', 'd52f25'],

  ['Vivid Red', 'RV 3001', 'b3061e'],

  ['Clandestine Red', 'RV 47', '950825'],

  ['Bourdeaux Red', 'RV 3004', '610819'],

  ['Cherokee Red', 'RV 3007', '3a0807'],

  ['Night Red', 'RV 77', '2d0604'],

  ['Supernova Pink', 'RV 194', 'fce6eb'],

  ['Tokyo Pink', 'RV 164', 'f7c7d7'],

  ['Orchid Pink', 'RV 165', 'ec7faa'],

  ['Erika', 'RV 4003', 'e85b99'],

  ['Magenta', 'RV 4010', 'd90075'],

  ['Açaí Red', 'RV 166', '9a004b'],

  ['Rioja Red', 'RV 167', '6b0037'],

  ['Anger Red', 'RV 168', '550026'],

  ['Taurus Red', 'RV 169', '36001c'],

  ['Saudade Pink', 'RV 196', 'f2dddd'],

  ['Boreal Pink', 'RV 86', 'e3bfc3'],

  ['Stereo Pink', 'RV 87', 'ca95a4'],

  ['Single Pink', 'RV 88', 'a06c7e'],

  ['Compact Red', 'RV 89', '864a54'],

  ['Bitacora Red', 'RV 90', '582b3a'],

  ['Pandora Red', 'RV 91', '4f1f30'],

  ['Gaudí Red', 'RV 92', '321129'],

  ['Stendhal Red', 'RV 93', '1e000e'],

  ['Penelope Brown', 'RV 197', 'f8ede7'],

  ['Sensible Brown', 'RV 176', 'efd9d0'],

  ['Oak Brown', 'RV 203', 'a86059'],

  ['Interrail Brown', 'RV 204', '9f3d44'],

  ['Warrior Brown', 'RV 205', '5a1521'],

  ['Plancton', 'RV 200', 'e8d6c8'],

  ['Respect Pink', 'RV 64', 'b59892'],

  ['Scarlet Brown', 'RV 201', '8e6061'],

  ['Mosquito Brown', 'RV 202', '684445'],

  ['Shiva Violet', 'RV 195', 'e5cce1'],

  ['Persia Violet', 'RV 170', 'c5add0'],

  ['Community Violet', 'RV 171', 'b194c0'],

  ['Destiny Violet', 'RV 172', '8270a3'],

  ['Ultraviolet', 'RV 173', '6b4d8d'],

  ['Venus Violet', 'RV 174', '483366'],

  ['Electra Violet', 'RV 175', '32234e'],

  ['Cosmos Violet', 'RV 28', '241c46'],

  ['Vampire Violet', 'RV 27', '140632'],

  ['Rain Blue', 'RV 184', 'c4e7f8'],

  ['Hydra Blue', 'RV 149', '44b3df'],

  ['Argo Blue', 'RV 150', '8ecae5'],

  ['Freedom Blue', 'RV 151', '0098d2'],

  ['Europe Blue', 'RV 152', '0074b0'],

  ['Electric Blue', 'RV 30', '005891'],

  ['Dark Blue', 'RV 5005', '003875'],

  ['Twister Blue', 'RV 154', '042151'],

  ['Azul Marino', 'RV 5013', '010E30'],

  ['Angel Blue', 'RV 185', 'eaf0f5'],

  ['Barceloneta Blue', 'RV 156', 'CAE4F0'],

  ['Thalassa Blue', 'RV 157', 'a2d3ea'],

  ['Perseus Blue', 'RV 158', '7fb8d5'],

  ['Odyssey Blue', 'RV 159', '6ea4be'],

  ['Eureka Blue', 'RV 160', '467289'],

  ['Jase Blue', 'RV 161', '2e4a5b'],

  ['Deep Blue', 'RV 162', '003447'],

  ['Poseydon Blue', 'RV 163', '00202c'],

  ['Virgin Green', 'RV 186', 'f5faf7'],

  ['Gemini Green', 'RV 142', 'e1f0e8'],

  ['Luminouse Green', 'RV 6027', 'a1d5cf'],

  ['Bali Green', 'RV 144', '7bc6c0'],

  ['Erasmus Green', 'RV 145', '5bb5b5'],

  ['Turquoise', 'RV 5018', '008691'],

  ['Glory Blue', 'RV 146', '2d6a75'],

  ['Pegasus Blue', 'RV 147', '003640'],

  ['Hercules Blue', 'RV 148', '002628'],

  ['Vespa Green', 'RV 187', 'f1f5df'],

  ['Frisco Green', 'RV 124', 'd4e19e'],

  ['Pistachio Green', 'RV 16', 'bdd268'],

  ['Neon Green', 'RV 125', 'a5b700'],

  ['Guacamole Green', 'RV 34', '81a512'],

  ['Valley Green', 'RV 6018', '4a7518'],

  ['Toscana Green', 'RV 126', '2b491e'],

  ['Era Green', 'RV 127', '142f0f'],

  ['Amazonas Green', 'RV 6009', '0f2405'],

  ['Shining Green', 'RV 188', 'f2edbe'],

  ['Lemon Yellow', 'RV 1016', 'dcd872'],

  ['Oregano Green', 'RV 129', 'afab00'],

  ['Kripton Green', 'RV 60', '83890e'],

  ['Euskadi Green', 'RV 130', '50590d'],

  ['Comarca Green', 'RV 131', '333b08'],

  ['Borneo Green', 'RV 132', '283307'],

  ['Dharma Green', 'RV 133', '1e2705'],

  ['Infinity Green', 'RV 134', '131901'],

  ['Eldorado', 'RV 177', 'f1c700'],

  ['Tibet', 'RV 178', 'd58b26'],

  ['Mustard', 'RV 8023', 'a76225'],

  ['Java Green', 'RV 199', '86c8b4'],

  ['Emerald Green', 'RV 182', '009e8f'],

  ['Beryl Green', 'RV 183', '117774'],

  ['Bonsai Green', 'RV 179', 'b8bb86'],

  ['Thai Green', 'RV 180', '8e966e'],

  ['Labyrinth Green', 'RV 181', '47492b'],

  ['Sulfur Yelow', 'RV 267', 'FAEB37'],

  ['Psycho Green', 'RV 266', 'D5E739'],

  ['Sónar Green', 'RV 265', 'd2d026'],

  ['Laser Green', 'RV 281', 'cad44f'],

  ['Laos Green', 'RV 282', 'b0c753'],

  ['Hulk Green', 'RV 280', '7cab3b'],

  ['Mint Green', 'RV 272', '93bf82'],

  ['Iguana Green', 'RV 273', 'b8cd68'],

  ['Mystic Green', 'RV 271', '7ab058'],

  ['Peach', 'RV 1017', 'F9BA57'],

  ['Medium Yellow', 'RV 1028', 'F6A800'],

  ['Mango', 'RV 207', 'd57828'],

  ['Formentera Blue', 'RV 270', '00B4BC'],

  ['Genesis Blue', 'RV 269', '179DAB'],

  ['Tramontana Blue', 'RV 268', '007788'],

  ['Bishop Violet', 'RV 276', 'C29BCB'],

  ['Raval Violet', 'RV 275', '94679C'],

  ['Reverend Violet', 'RV 274', '6C5D80'],

  ['Joker Pink', 'RV 278', 'c476aa'],

  ['Disco Pink', 'RV 277', 'b471a7'],

  ['Rosa Rosario', 'RV279', 'c42581'],

  ['Light Red', 'RV 3020', 'c8393b'],

  ['Madrid Red', 'RV 241', 'c52332'],

  ['Cyan', 'RV 245', '00FFFF'],

  ['Babylon Blue', 'RV 243', '3D5588'],

  ['Sultan Violet', 'RV 283', '83577D'],

  ['Stardust Grey', 'RV 198', 'eef0f1'],

  ['Siberian Grey', 'RV 7047', 'dbdddf'],

  ['Rita Grey', 'RV 118', 'cdd0d1'],

  ['Pearl Grey', 'RV 7040', 'b5b8ba'],

  ['London Grey', 'RV 119', '898b8e'],

  ['Wolf Grey', 'RV 120', '5b5b5e'],

  ['Icarus Grey', 'RV 121', '48484b'],

  ['Anthracite Grey', 'RV 7016', '363538'],

  ['Metropolis Grey', 'RV 78', '262227'],

  ['Black', 'R 9011', '000000'],

  ['White', 'R 9010', 'ffffff'],

  [
    'Silver Jewell',
    '',
    '666666',
    'null',
    'null',
    '',
    'null',
    'null',
    'null',
    'null',
    'null',
    'null',
    'null',
  ],

  [
    'Frame Gold',
    '',
    'c49d69',
    'null',
    'null',
    '',
    'null',
    'null',
    'null',
    'null',
    'null',
    'null',
    'null',
  ],

  ['Spectral Ethereal Yellow', 'SPECTRAL', 'FEEB34'],

  ['Spectral Calima Orange', 'SPECTRAL', 'DF6033'],

  ['Spectral Soul Red', 'SPECTRAL', 'E7332A'],

  ['Spectral Aura Violet', 'SPECTRAL', '5B4998'],

  ['Spectral Atmosphere Blue', 'SPECTRAL', '199CD2'],

  ['Spectral Breeze Green', 'SPECTRAL', '87b251'],

  ['Spectral Judea Brown', 'SPECTRAL', 'B07642'],

  ['Spectral Shadow Black', 'SPECTRAL', '000000'],

  ['Spectral Spectral Air White', 'SPECTRAL', 'FFFFFF'],

  ['Fluor Yelow', 'FLUOR', 'FFEE17'],

  ['Fluor Orange', 'FLUOR', 'FF8B00'],

  ['Fluor Red', 'FLUOR', 'FF2741'],

  ['Fluor Fucsia', 'FLUOR', 'FF3D90'],

  ['Fluor Green', 'FLUOR', '31B100'],

  ['Fluor Blue', 'FLUOR', '0088CD'],
]

export default colorsMontana94
