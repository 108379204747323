import React from 'react'
import { useDispatch } from 'react-redux'

import { ActionCreators } from 'redux-undo'
import { useTsSelector } from '../reducers'

import PaletteSetPicker from '../components/toolbar/paletteSetPicker'
// import AutoModeToggle from '../components/toolbar/autoMode'
import AmountOfSwatches from '../components/toolbar/amountOfSwatches'
// import Saveloop from '../components/toolbar/saveloop'
import classNames from './toolBar.module.sass'
import { gui } from '../reducers/modules/gui'
import { sets } from '../reducers/modules/paletteSets'
// import { palettes } from '../reducers/modules/palettes'

const ToolBar: React.FC = () => {
  const dispatch = useDispatch()
  const amountOfSwatches = useTsSelector((state) => state.gui.amountOfSwatches)
  const showInfo = useTsSelector((state) => state.gui.showInfo)
  const usedColorSet = useTsSelector((state) => state.gui.usedColorSet)
  const maxAmountOfSwatches = sets?.[usedColorSet]?.length
  // const resetPalettesHandler = (): void => {
  //   dispatch(palettes.actions.reset())
  // }

  const submitChangeAmountOfSwatches = (e): void => {
    dispatch(gui.actions.changeAmountOfSwatches(Number(e.target.value)))
  }

  // const changeAutomode = (e): void => {
  //   dispatch(gui.actions.changeAutomode(Boolean(e.target.checked)))
  // }

  return (
    <div className={classNames.toolbar}>
      <label>
        <button onClick={() => dispatch(gui.actions.setShowInfo(!showInfo))}>INFO</button>
      </label>
      <PaletteSetPicker />
      <label style={{ display: 'flex' }}>
        <p></p>
        <button onClick={() => dispatch(ActionCreators.undo())}>Undo</button>
        &nbsp;
        <button onClick={() => dispatch(ActionCreators.redo())}>Redo</button>
      </label>
      {/* { <AutoModeToggle value={autoModeState} changeHandler={changeAutomode} />} */}
      <AmountOfSwatches
        value={amountOfSwatches}
        max={maxAmountOfSwatches}
        changeHandler={submitChangeAmountOfSwatches}
      />
      {/* {false && <Saveloop dispatch={this.props.dispatch} />} */}
    </div>
  )
}

export default ToolBar
