import React from 'react'
import { useTsSelector } from '../reducers'
import * as colorSetUtils from '../modules/colorSets'
import { sets } from '../reducers/modules/paletteSets'
import { Color } from '../reducers/modules/colors'

type FindColorsetHandler = (color: Color, options?: colorSetUtils.NeighbourghOptions) => Color[]

type ColorContextValue = {
  findNeighboursHandler: FindColorsetHandler
  findNeighboursAllHandlers: Record<string, FindColorsetHandler>
}

const contextDefaultProps: ColorContextValue = {
  findNeighboursHandler: () => [],
  findNeighboursAllHandlers: {},
}

export const NearestColorsContext: React.Context<ColorContextValue> =
  React.createContext(contextDefaultProps)

export const NearestColorsContextProvider: React.FC = ({ children }) => {
  const usedColorSet = useTsSelector((state) => state.gui.usedColorSet)
  const paletteSets = sets

  const findNeighboursAllHandlers = Object.entries(paletteSets).reduce(
    (memo, [paletteSetName, paletteSet]) => {
      return paletteSet ? { [paletteSetName]: colorSetUtils.neighbours(paletteSet), ...memo } : memo
    },
    {},
  )

  const findNeighboursHandler =
    paletteSets && paletteSets[usedColorSet]
      ? colorSetUtils.neighbours(paletteSets[usedColorSet])
      : () => []

  return (
    <>
      <NearestColorsContext.Provider value={{ findNeighboursHandler, findNeighboursAllHandlers }}>
        {children}
      </NearestColorsContext.Provider>
    </>
  )
}
