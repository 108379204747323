import React from 'react'
import { InfoBar } from '../containers/infoBar'
import PaletteDisplayList from '../containers/paletteDisplayList'
import ToolBar from '../containers/toolBar'
import { NearestColorsContextProvider } from '../utils/Context'
import { StatusPersistance } from './StatusPersistance'

export const App: React.FC = () => {
  return (
    <div>
      <InfoBar />
      <ToolBar /> {/* <ColorControls main style={{ top: '0em' }} /> */}
      <StatusPersistance />
      <NearestColorsContextProvider>
        <PaletteDisplayList />
      </NearestColorsContextProvider>
    </div>
  )
}

export default App
